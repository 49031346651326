import { Box, Grid } from "@mui/material";
import React from "react";
import NavCards from "../components/NavCards/NavCards";
import AiSidebar from "../commonComponents/AISidebar/Aisidebar";
import AiHeader from "../commonComponents/AIHeader/AiHeader";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import talairo_ai from "../images/svgs/talairo_ai.svg";
import talairo_title from "../images/svgs/talairo_title.svg";

function AiLayout() {
  const theme = useTheme();

  // const isWeb = useMediaQuery(theme.breakpoints.up("md"));
  const isWeb = useMediaQuery("(min-width:960px)");

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        overflow: "hidden",
      }}
    >
      <Grid container sx={{ flexGrow: 1 }}>
        {isWeb ? (
          <Grid
            item
            xs={2}
            sx={{
              borderRight: "1px solid black",
              height: "100vh",
              position: "sticky",
              top: 0,
              // paddingRight: "15px",
            }}
          >
            <AiSidebar isMobile={isMobile} />
          </Grid>
        ) : null}

        <Grid item xs={12} sm={12} md={10}>
          <Box
            sx={{ display: "flex", flexDirection: "column", height: "100vh" }}
          >
            <AiHeader isWeb={isWeb} isMobile={isMobile} />
            {/* This is the scrollable content area */}
            <Box
              sx={{
                // flexGrow: 1,
                overflowY: "auto",
                padding: "32px",
              }}
            >
              <NavCards />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default AiLayout;
