import React, { useState } from "react";
import { Tabs, Tab, Box, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import AttributeRange from "./Components/AttributeRange";

const CustomTabs = styled(Tabs)({
  "& .MuiTabs-indicator": {
    display: "none",
  },
  backgroundColor: "#F5F5F5",
  padding: "10px 16px",
  borderRadius: "8px",
  width: "fit-content",
  margin: "auto",
  minHeight: "32px",
});

// Custom styled Tab component
const CustomTab = styled(Tab)(({ selected }) => ({
  textTransform: "none",
  borderRadius: "8px",
  border: `1px solid ${selected ? "white" : "#F5F5F5"}`,
  backgroundColor: selected ? "white" : "#F5F5F5",
  color: "black",
  fontWeight: selected ? "bold" : "normal",
  padding: "4px 12px",
  marginRight: "8px",
  minHeight: "32px",
  "&.Mui-selected": {
    color: "black",
  },
  "&:hover": {
    backgroundColor: "white",
  },
}));

function SettingsAi() {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{
          position: "sticky",
          top: "-50px",
          zIndex: 1,
          backgroundColor: "white",
        }}
      >
        <Box sx={{ backgroundColor: "white !important", padding: "16px" }}>
          <CustomTabs value={tabValue} onChange={handleTabChange} centered>
            <CustomTab selected={tabValue === 0} label="Preset 1: Balanced" />
            <CustomTab
              selected={tabValue === 1}
              label="Preset 2: Experience is King"
            />
            <CustomTab
              selected={tabValue === 2}
              label="Preset 3: Bedside Manners Rules"
            />
          </CustomTabs>
        </Box>
      </Grid>

      <Grid item xs={12}>
        {tabValue === 0 && (
          <>
            <AttributeRange />
          </>
        )}
        {/* Content for other presets can go here */}
      </Grid>
    </Grid>
  );
}

export default SettingsAi;
