import {
  Avatar,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import {
  StyledButton,
  StyledFlexColumnView,
  StyledFlexRowView,
  StyledProfile,
  StyledProfileView,
} from "../../CandidateHub/Components/StyledComponents";
import profile from "../../../images/logos/profile.png";
import ConfidenceCircle from "./ConfidenceCircle";
import {
  ResponsiveTableTitle,
  StyledInteractionProfileView,
} from "./StyledComponents";
import PersonIcon from "@mui/icons-material/Person";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

function Profile({
  matchedData,
  handleViewResume,
  handleCompareResume,
  handleDownloadResume,
  resumeDownloadLoading,
}) {
  return (
    <Grid container spacing={3} alignItems="center">
      {/* Main profile section */}
      <Grid
        item
        xs={12} // Full width on mobile
        sm={9} // Takes 9 out of 12 on small screens like tablets
        md={9} // Takes 9 out of 12 on medium and larger screens
      >
        <StyledInteractionProfileView>
          {/* <StyledProfile
            src={profile}
            style={{
              height: "150px",
              width: "auto",
              maxWidth: "150px",
              marginBottom: "16px",
            }}
          /> */}
          <Avatar
            sx={{
              width: 140,
              height: 130,
              marginBottom: "16px",
              borderRadius: "8px",
            }}
            variant="square"
          >
            <PersonIcon sx={{ fontSize: "150px" }} />
          </Avatar>
          <StyledFlexColumnView sx={{ gap: "6px" }}>
            <StyledFlexRowView style={{ gap: "15px", flexWrap: "wrap" }}>
              <StyledButton variant="contained" onClick={handleViewResume}>
                {"View Resume"}
              </StyledButton>
              <StyledButton variant="contained" onClick={handleCompareResume}>
                {"Compare"}
              </StyledButton>
              <StyledButton variant="contained">{"Share"}</StyledButton>
              <StyledButton variant="contained">
                {"See Interactions"}
              </StyledButton>
              <StyledButton
                variant="contained"
                startIcon={
                  resumeDownloadLoading ? (
                    <CircularProgress color="inherit" size={15} />
                  ) : null
                }
                onClick={handleDownloadResume}
                disabled={resumeDownloadLoading}
              >
                {"Download"}
              </StyledButton>
            </StyledFlexRowView>

            {matchedData?.error_message ? (
              <Typography sx={{ color: "red", marginTop: "17px" }}>
                {matchedData?.error_message}
              </Typography>
            ) : (
              <>
                <ResponsiveTableTitle
                  textAlign="left"
                  sx={{
                    marginTop: { md: "30px" },
                    fontWeight: 600,
                    fontSize: { lg: "30px" },
                  }}
                >
                  {matchedData?.candidate_name
                    ? matchedData?.candidate_name
                    : null}
                </ResponsiveTableTitle>
                <Typography textAlign="left" sx={{ fontSize: "20px" }}>
                  {matchedData?.pay_rate
                    ? `Rate: ${matchedData?.pay_rate}`
                    : null}
                </Typography>
                <Typography
                  textAlign="left"
                  sx={{ fontSize: "20px", color: "#828282" }}
                >
                  {matchedData?.inferred_title}{" "}
                  {matchedData?.location ? ` - ${matchedData.location}` : ""}
                  {matchedData?.visa_info ? ` - ${matchedData.visa_info}` : ""}
                </Typography>
              </>
            )}
          </StyledFlexColumnView>
        </StyledInteractionProfileView>
      </Grid>

      {/* ConfidenceCircle section */}
      <Grid
        item
        xs={12}
        sm={3}
        md={3}
        container
        justifyContent="center"
        alignItems="center"
      >
        <ConfidenceCircle matchedData={matchedData} />
      </Grid>
    </Grid>
  );
}

export default Profile;
