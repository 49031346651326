import { Grid, Typography } from "@mui/material";
import React from "react";
import GaugeChart from "./GaugeChart";
import { StyledBoldTypo } from "./StyledComponents";

function OverallChart({ formPrepopValue }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={5} lg={5} mb={"20px"}>
        <GaugeChart formPrepopValue={formPrepopValue} />
      </Grid>
      {formPrepopValue?.insights ? (
        <Grid
          item
          xs={12}
          md={7}
          lg={7}
          sx={{ backgroundColor: "#BBF8C4", borderRadius: "8px" }}
        >
          <div>
            {formPrepopValue?.insights?.insights?.length ? (
              <>
                <StyledBoldTypo>{"Insights"}</StyledBoldTypo>
                {formPrepopValue?.insights?.insights?.map((item, index) => (
                  <Typography
                    key={index}
                    component="div"
                    dangerouslySetInnerHTML={{
                      __html: `${index + 1}. ${item}`,
                    }}
                    sx={{ color: "#828282" }}
                  />
                ))}
              </>
            ) : null}

            {formPrepopValue?.insights?.spelling_errors?.length ? (
              <>
                <StyledBoldTypo marginTop={2}>
                  {"Spelling Errors"}
                </StyledBoldTypo>
                {formPrepopValue?.insights?.spelling_errors.map(
                  (item, index) => (
                    <Typography
                      key={index}
                      component="div"
                      dangerouslySetInnerHTML={{
                        __html: `${index + 1}. ${item}`,
                      }}
                      sx={{ color: "#828282" }}
                    />
                  )
                )}
              </>
            ) : null}

            {formPrepopValue?.insights?.punctuation_errors?.length ? (
              <>
                <StyledBoldTypo marginTop={2}>
                  {"Punctuation Errors"}
                </StyledBoldTypo>
                {formPrepopValue?.insights?.punctuation_errors?.map(
                  (item, index) => (
                    <Typography
                      key={index}
                      component="div"
                      dangerouslySetInnerHTML={{
                        __html: `${index + 1}. ${item}`,
                      }}
                      sx={{ color: "#828282" }}
                    />
                  )
                )}{" "}
              </>
            ) : null}

            {formPrepopValue?.insights?.professional_writing_errors?.length ? (
              <>
                <StyledBoldTypo marginTop={2}>
                  {"Professional Writing Errors"}
                </StyledBoldTypo>
                {formPrepopValue?.insights?.professional_writing_errors?.map(
                  (item, index) => (
                    <Typography
                      key={index}
                      component="div"
                      dangerouslySetInnerHTML={{
                        __html: `${index + 1}. ${item}`,
                      }}
                      sx={{ color: "#828282" }}
                    />
                  )
                )}
              </>
            ) : null}

            {formPrepopValue?.insights?.justification?.length ? (
              <>
                <StyledBoldTypo marginTop={2}>
                  {"Title Relevency"}
                </StyledBoldTypo>
                {formPrepopValue?.insights?.justification?.map(
                  (item, index) => (
                    <Typography
                      key={index}
                      component="div"
                      dangerouslySetInnerHTML={{
                        __html: `${index + 1}. ${item}`,
                      }}
                      sx={{ color: "#828282" }}
                    />
                  )
                )}
              </>
            ) : null}
          </div>
        </Grid>
      ) : null}
    </Grid>
  );
}

export default OverallChart;
