import { QUICK_MATCH } from "../actionTypes";

const initialState = {
  //   pagination: {
  //     activePage: 1,
  //     items: 10,
  //   },
  jobDescriptionFile: null,
  resumeFile: null,
};

const quickMatch = (state = initialState, { type, payload }) => {
  switch (type) {
    case QUICK_MATCH.setState: {
      return { ...state, ...payload };
    }
    case QUICK_MATCH.clearState: {
      return initialState;
    }
    default:
      return state;
  }
};

export default quickMatch;
