import { Grid, Step, StepLabel, Stepper } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import "./Style.css";
import ImportCreateJob from "./Components/ImportCreateJob";
import PrePopJob from "./Components/PrePopJob";
import ConfirmSettings from "./Components/ConfirmSettings";
import {
  uploadJobDataFile,
  getJobData,
  JdAddNotes,
  JdGetNotes,
} from "../../api/api";
import axios from "axios";
import CustomSnack from "../../commonComponents/Snack/CustomSnack";
import { StyledStepperContainer } from "./Components/StyledComponents";

const steps = [
  "Select master blaster campaign settings",
  "Create an ad group",
  "Create an ad",
];

function Index() {
  const history = useHistory();
  const params = useParams();
  const location = useLocation();

  const username = localStorage.getItem("username");
  // const [activeStep, setActiveStep] = useState(0);
  const activeStep = Number(params.step);
  const fileInputRef = useRef(null);
  const isNew = location?.state?.isNew;
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [formPrepopValue, setFormPrepopValue] = useState({
    title: "",
    industry: "",
  });
  const [snack, setSnack] = useState({
    open: false,
    color: "",
    message: "",
  });
  const [id, setId] = useState("");
  //Add Notes
  const [addNotesDialogOpen, setAddNotesDialogOpen] = useState(false);
  const [noteList, setNoteList] = useState([
    { note: "", interaction: "", added_by: username },
  ]);
  const [notesTableList, setNotesTableList] = useState([]);
  const [addNoteLoading, setAddNoteLoading] = useState(false);

  const handleAddNotesDialogClose = () => {
    setAddNotesDialogOpen(false);
    setNoteList([{ note: "", interaction: "", added_by: username }]);
  };

  const getNotesDetatil = (id) => {
    axios
      .get(JdGetNotes(id))
      .then((res) => {
        setNotesTableList(res?.data?.data);
      })
      .catch((err) => {
        setSnack({
          open: true,
          color: "error",
          message: err?.response?.data?.message,
        });
      });
  };

  // Handle saving the notes
  const handleSaveAddNotes = () => {
    const hasEmptyFields = noteList.some(
      (noteItem) => !noteItem.note || !noteItem.interaction
    );

    if (hasEmptyFields) {
      setSnack({
        open: true,
        color: "error",
        message: "Note and Interaction Type fields are required",
      });
      return; // Stop execution if validation fails
    }

    if (id) {
      setAddNoteLoading(true);
      axios
        .post(JdAddNotes(id), noteList)
        .then((response) => {
          if (response?.data?.success === true) {
            setSnack({
              open: true,
              color: "success",
              message: response?.data?.message,
            });
            setAddNoteLoading(false);
          } else {
            setSnack({
              open: true,
              color: "error",
              message: response?.data?.message,
            });
            setAddNoteLoading(false);
          }
          getNotesDetatil(id);
          setAddNotesDialogOpen(false);
          setNoteList([{ note: "", interaction: "", added_by: username }]);
        })
        .catch((err) => {
          setSnack({
            open: true,
            color: "error",
            message: err?.response?.data?.message,
          });
          setAddNoteLoading(false);
        });
    }
  };

  // Handle adding another note and interaction type
  const handleAddAnotherNote = () => {
    setNoteList([
      ...noteList,
      { note: "", interaction: "", added_by: username },
    ]); // Add new empty note object
  };

  // Handle note text change for a specific index
  const handleNoteTextChange = (index, e) => {
    const newNotes = [...noteList];
    newNotes[index].note = e.target.value; // Update note for the corresponding index
    setNoteList(newNotes);
  };

  // Handle interaction type change for a specific index
  const handlePhoneEmailTextChange = (index, value) => {
    const newNotes = [...noteList];
    newNotes[index].interaction = value?.label || ""; // Ensure you set the correct field
    setNoteList(newNotes);
  };

  const handleAddNotes = () => {
    setAddNotesDialogOpen(true);
  };

  const handleNoteDelete = (indexToDelete) => {
    let arr = [...noteList];
    arr.splice(indexToDelete, 1);
    setNoteList(arr);
  };

  const handleStep = (index) => {
    // setActiveStep(index);
    history.replace(`/home/postjob/${index}`);
  };

  const renderComponent = () => {
    if (activeStep === 0) {
      return (
        <ImportCreateJob
          handleFirstNextClick={handleFirstNextClick}
          handleUploadClick={handleUploadClick}
          handleFileChange={handleFileChange}
          handleRemoveFile={handleRemoveFile}
          fileName={fileName}
          fileInputRef={fileInputRef}
          file={file}
          loading={loading}
        />
      );
    } else if (activeStep === 1) {
      return (
        <PrePopJob
          handleStep={handleStep}
          formPrepopValue={formPrepopValue}
          handleAddHardSkill={handleAddHardSkill}
          handleAddSoftSkill={handleAddSoftSkill}
          handleRemoveSkill={handleRemoveSkill}
          handleSliderChange={handleSliderChange}
          handleMultiSelectChange={handleMultiSelectChange}
          pageLoader={pageLoader}
        />
      );
    } else if (activeStep === 2) {
      return (
        <ConfirmSettings
          handleStep={handleStep}
          formPrepopValue={formPrepopValue}
          handleChangeInterview={handleChangeInterview}
          handleClearInterview={handleClearInterview}
          handleAddNotesDialogClose={handleAddNotesDialogClose}
          handleSaveAddNotes={handleSaveAddNotes}
          handleAddNotes={handleAddNotes}
          addNotesDialogOpen={addNotesDialogOpen}
          noteList={noteList}
          handleAddAnotherNote={handleAddAnotherNote}
          handleNoteTextChange={handleNoteTextChange}
          handlePhoneEmailTextChange={handlePhoneEmailTextChange}
          handleNoteDelete={handleNoteDelete}
          username={username}
          notesTableList={notesTableList}
          addNoteLoading={addNoteLoading}
        />
      );
    }
  };

  //ImportCreateJob

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Trigger the file input click
    }
  };

  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      setFileName(event.target.files[0].name); // Update the file name state
      setFile(event.target.files[0]);
    }
  };

  const handleRemoveFile = () => {
    setFileName(""); // Clear the file name state
    setFile("");
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Clear the file input
    }
  };

  const getJobdataDetails = async (id) => {
    setPageLoader(true);
    try {
      const {
        data: { data },
      } = await axios.get(getJobData(id));
      const certificates = data?.job_details?.certificates?.flatMap((item) =>
        item.content
          .filter((value) => value.length > 0) // Filter out empty strings
          .map((value) => ({ name: item.name, value }))
      );

      const languages = data?.job_details?.languages?.flatMap((item) =>
        item.content
          .filter((value) => value.length > 0) // Filter out empty strings
          .map((value) => ({ name: item.name, value }))
      );
      const employer = data?.job_details?.employer_targets?.flatMap((item) =>
        item.content
          .filter((value) => value.length > 0) // Filter out empty strings
          .map((value) => ({ name: item.name, value }))
      );
      setFormPrepopValue({
        title: data?.job_details?.job_title,
        industry: data?.job_details?.industry,
        payRate:
          typeof data?.job_details?.pay_rate === "string"
            ? data.job_details.pay_rate
            : null,
        managementLevel: data?.job_details?.management_level,
        employmentType:
          typeof data?.job_details?.employment === "string"
            ? data.job_details.employment
            : null,
        location: data?.job_details?.location,
        startDate: data?.job_details?.start_date,
        endDate: data?.job_details?.end_date,
        occupationalClassification:
          data?.job_details?.occupational_classification,
        requestId: data?.job_details?.req_id,
        shift: data?.job_details?.shift,
        reportsTo: data?.job_details?.reports_to,
        buDepartmentTeam: data?.job_details["bu/department/team"],
        hardSkills: data?.job_details?.extracted_hard_skills,
        softSkills: data?.job_details?.extracted_soft_skills,
        recommendHardSkills: data?.job_details?.relevant_hard_skills,
        recommendSoftSkills: data?.job_details?.relevant_soft_skills,

        locationTargets: data?.job_details?.location_targets?.map((elt) => ({
          value: elt,
        })),
        employerTargets: employer,
        certificates: certificates,
        education:
          typeof data?.job_details?.education === "string"
            ? data?.job_details?.education
            : null,
        languages: languages,
        visaStatus: data?.job_details["visa/employment_needs"]?.visa_status,
        visaContent: data?.job_details["visa/employment_needs"]?.content,
        govtSecurityClearance: data?.job_details?.govt_security_clearance,
        dei: data?.job_details?.dei,
        relocation_required: data?.job_details?.relocation_required,
        score: data?.job_details?.score,
        insights: data?.job_details?.insights,
        inferredTitle: data?.job_details?.inferred_title,
        companyProfile: data?.job_details?.company_profile,
        company_name: data?.job_details?.company_name,
        scoreLabel: data?.job_details?.score_label,
        interviewType: data?.job_details?.interview_type || [],
      });
      setPageLoader(false);
    } catch (error) {
      setSnack({
        open: true,
        color: "error",
        message: error?.response?.data?.message,
      });
      setPageLoader(false);
    }
  };

  const handleFirstNextClick = () => {
    setLoading(true);

    let formData = new FormData();
    formData.append("files", file);
    axios
      .post(uploadJobDataFile(), formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })

      .then((response) => {
        if (response?.data?.success === true) {
          getJobdataDetails(response?.data?.data?.id);
          setId(response?.data?.data?.id);
          // setSnack({
          //   open: true,
          //   color: "success",
          //   message: response?.data?.message,
          // });
          // setActiveStep(1);
          history.replace("/home/postjob/1");
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err, "err");
        // setActiveStep(1);
        setSnack({
          open: true,
          color: "error",
          message: err?.response?.data?.message,
        });
      });
  };

  //hardsoftskill
  const handleAddHardSkill = () => {
    const newSkill = {
      skill: "",
      experience_in_yrs: 0,
      criticality_score: 0,
    };
    //   setHardSkills([...hardSkills, newSkill]);
    let arr = [...formPrepopValue?.hardSkills, newSkill];
    setFormPrepopValue({
      ...formPrepopValue,
      hardSkills: arr,
    });
  };

  const handleAddSoftSkill = () => {
    const newSkill = {
      skill: "",
      criticality_score: 0,
    };
    let arr = [...formPrepopValue?.softSkills, newSkill];
    setFormPrepopValue({
      ...formPrepopValue,
      softSkills: arr,
    });
    //   setSoftSkills([...softSkills, newSkill]);
  };

  const handleRemoveSkill = (index) => {
    let arr = [...formPrepopValue?.hardSkills];
    arr.splice(index, 1);
    setFormPrepopValue({
      ...formPrepopValue,
      hardSkills: arr,
    });
  };

  const handleSliderChange = (index, newValue, type) => {
    if (type === "hard") {
      let arr = [...formPrepopValue?.hardSkills];
      arr[index].criticality_score = newValue;
      setFormPrepopValue({
        ...formPrepopValue,
        hardSkills: arr,
      });
    } else if (type === "soft") {
      let arr = [...formPrepopValue?.softSkills];
      arr[index].criticality_score = newValue;
      setFormPrepopValue({
        ...formPrepopValue,
        softSkills: arr,
      });
    } else if (type === "recommendHard") {
      let arr = [...formPrepopValue?.recommendHardSkills];
      arr[index].criticality_score = newValue;
      setFormPrepopValue({
        ...formPrepopValue,
        recommendHardSkills: arr,
      });
    } else {
      let arr = [...formPrepopValue?.recommendSoftSkills];
      arr[index].criticality_score = newValue;
      setFormPrepopValue({
        ...formPrepopValue,
        recommendSoftSkills: arr,
      });
    }
  };

  const handleMultiSelectChange = (name, val) => {
    if (typeof val === "string") {
      setFormPrepopValue({
        ...formPrepopValue,
        [name]: val,
      });
    } else if (val && val.inputValue) {
      // Create a new value from the user input
      setFormPrepopValue({
        ...formPrepopValue,
        [name]: val.inputValue,
      });
    } else {
      // setFieldValue("preferred", newValue);
      setFormPrepopValue({
        ...formPrepopValue,
        [name]: val,
      });
    }
  };

  //confirm settings
  const handleClearInterview = (index) => {
    setFormPrepopValue((prev) => ({
      ...prev,
      interviewType: prev.interviewType.map((point, i) =>
        i === index ? "" : point
      ),
    }));
  };

  const handleChangeInterview = (index, newValue) => {
    setFormPrepopValue((prev) => ({
      ...prev,
      interviewType: prev.interviewType.map((point, i) =>
        i === index ? newValue : point
      ),
    }));
  };

  const handleSnackClose = () => {
    setSnack({
      open: false,
      color: "",
      message: "",
    });
  };

  useEffect(() => {
    if (isNew) {
      setFileName("");
      if (fileInputRef.current) {
        fileInputRef.current.value = ""; // Clear the file input
      }
    }
  }, [isNew]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <StyledStepperContainer>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map(({ title }, index) => (
              <Step key={title} completed={index < activeStep}>
                <StepLabel />
              </Step>
            ))}
          </Stepper>
        </StyledStepperContainer>
      </Grid>

      <Grid item xs={12} pt={4}>
        {renderComponent()}
      </Grid>

      <CustomSnack
        open={snack.open}
        color={snack.color}
        message={snack.message}
        onClose={handleSnackClose}
      />
    </Grid>
  );
}
export default Index;
