import React from "react";
import { Grid, List, ListItem, Tooltip, Typography } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import {
  CategoryRatingContainer,
  GreyBoldTextTypo,
  StyledFlexRowView,
} from "./StyledComponents";
import { StyledRating } from "../../CandidateHub/Components/StyledComponents";

const StrengthsAndWeaknesses = ({ matchedData }) => {
  return (
    <Grid container>
      {matchedData?.insights?.strengths?.length ? (
        <Grid item xs={12} md={5} sx={{ paddingRight: { md: "30px" } }}>
          <List>
            <ListItem sx={{ padding: "2px" }}>
              <GreyBoldTextTypo>{"Areas of Strength:"}</GreyBoldTextTypo>
            </ListItem>
            {matchedData?.insights?.strengths?.map((item, index) => (
              <CategoryRatingContainer>
                <StyledFlexRowView>
                  <Add sx={{ marginRight: 1 }} />
                  <Tooltip title={item.category} placement="top-start">
                    <Typography sx={{ color: "#828282", fontSize: "20px" }}>
                      {item?.category}
                    </Typography>
                  </Tooltip>
                </StyledFlexRowView>
                <StyledFlexRowView>
                  <StyledRating
                    name={item?.category}
                    value={item?.rating}
                    readOnly
                    precision={0.5}
                  />
                  {/* <Typography sx={{ fontWeight: "bold" }}>
                    {item?.score ? item?.score.toFixed(2) : "0.00"}%
                  </Typography> */}
                </StyledFlexRowView>
              </CategoryRatingContainer>
            ))}
          </List>
        </Grid>
      ) : null}
      {matchedData?.insights?.weaknesses?.length ? (
        <Grid item xs={12} md={5} sx={{ paddingLeft: { md: "30px" } }}>
          <List>
            <ListItem sx={{ padding: "2px" }}>
              <GreyBoldTextTypo>{"Areas of Weakness:"}</GreyBoldTextTypo>
            </ListItem>

            {matchedData?.insights?.weaknesses?.map((item, index) => (
              <CategoryRatingContainer>
                <StyledFlexRowView>
                  <Remove sx={{ marginRight: 1 }} />
                  <Tooltip title={item.category} placement="top-start">
                    <Typography sx={{ color: "#828282", fontSize: "20px" }}>
                      {item?.category}
                    </Typography>
                  </Tooltip>
                </StyledFlexRowView>
                <StyledFlexRowView>
                  <StyledRating
                    name={item?.category}
                    value={item?.rating}
                    readOnly
                    precision={0.5}
                  />
                  {/* <Typography sx={{ fontWeight: "bold" }}>
                    {item?.score ? item?.score.toFixed(2) : "0.00"}%
                  </Typography> */}
                </StyledFlexRowView>
              </CategoryRatingContainer>
            ))}
          </List>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default StrengthsAndWeaknesses;
