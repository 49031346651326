import {
  Box,
  Button,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import talairo_ai from "../../images/svgs/talairo_ai.svg";
import talairo_title from "../../images/svgs/talairo_title.svg";
import "./Style.css";
import AddIcon from "@mui/icons-material/Add";
import { useHistory } from "react-router-dom";
import AiHeader from "../../commonComponents/AIHeader/AiHeader";

const steps = [
  "Select master blaster campaign settings",
  "Create an ad group",
  "Create an ad",
];

function Index() {
  const history = useHistory();

  const handleClickAddNew = () => {
    history.push("/home/postjob");
  };

  const handleHomeDashbord = () => {
    history.push("/home/dashboard-ai");
  };

  const handleCandidate = () => {
    history.push("/home/candidate-hub");
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography>{"Dashboard"}</Typography>
      </Grid>
    </Grid>
  );
}
export default Index;
