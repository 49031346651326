import { Box, Button, List } from "@material-ui/core";
import styled from "styled-components";
import { TableContainer, TableHead } from "@material-ui/core";
import { CheckBox } from "@mui/icons-material";
import { Slider } from "@mui/material";

export const StyledLoadingButton = styled(Button)`
  .MuiButton-label:has(.text_loading) {
    width: calc(100% - 10px);
    transform: translate(14px, 0px);
  }
  .MuiButton-startIcon:has(.text_loading) {
    position: absolute;
    left: -23px;
    top: calc(50% - 10px);
  }
  .text_loading,
  .icon_loading {
    color: #ffffff;
  }
  .text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

// StyledTableContainer component
export const StyledTableContainer = styled(TableContainer)`
  width: 100%;
  overflow-x: auto;
  max-height: 58vh;

  tbody tr:last-of-type td {
    border-bottom: none;
  }

  td > b {
    display: inline-flex;
    align-items: center;
    min-height: 30px;
  }
`;

// StyledTableHead component
export const StyledTableHead = styled(TableHead)`
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: var(--table-header-color);

  tr {
    position: relative;
    &::after {
      position: absolute;
      display: block;
      content: "";
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      border-bottom: 1px solid rgba(224, 224, 224, 1);
    }
  }

  th {
    border: none;
    font-size: 15px !important;
    color: #333333 !important;
    font-weight: bold !important;
  }

  tr th:first-child {
    background-color: var(--table-header-color);

    &::after {
      position: absolute;
      display: block;
      content: "";
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      border-bottom: 1px solid rgba(224, 224, 224, 1);
    }
  }
`;

export const FlexCenterView = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FlexView = styled(Box)`
  display: flex;
  align-items: center;
`;

export const FlexColumnView = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const FlexColumnCenterView = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledList = styled(List)`
  li {
    width: 100%;
    display: flex;
    position: relative;
    box-sizing: border-box;
    text-align: left;
    align-items: flex-start;
    padding-top: 0px;
    padding-bottom: 8px;
    justify-content: flex-start;
    text-decoration: none;
    margin: 0;
    gap: 10px;

    svg {
      height: 1.5rem;
    }
  }
`;

export const StyledAccordionHeader = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--primary-color);
  padding: 10px 15px;
  transition: all 0.3s;
  border-radius: ${({ open }) => (open ? "6px 6px 0px 0px" : "6px")};
  color: #ffffff;
  cursor: pointer;

  .MuiTypography-root {
    font-weight: 600 !important;
  }

  .MuiIconButton-root {
    padding: 0 !important;
    background-color: #ffffff;

    svg.expand {
      transition: all 0.3s;
      transform: ${({ open }) => (open ? "rotate(180deg)" : "none")};
    }
  }
`;

export const CollapsibleWrapper = styled(Box)`
  padding: 15px;
  border: 1px solid lightgrey;
  border-top: none;
  background-color: ghostwhite;
  transition: all 0.3s;
  border-radius: 0px 0px 6px 6px;
`;

///Talairo
export const StyledCheckBox = styled(CheckBox)(() => ({
  color: "black",
  "&.Mui-checked": {
    color: "black",
  },
}));

export const StyledSlider = styled(Slider)(() => ({
  color: "#2C2C2C", // This will apply the primary color for the track and thumb
  "& .MuiSlider-thumb": {
    backgroundColor: "#2C2C2C", // Color of the thumb (handle)
  },
  "& .MuiSlider-track": {
    backgroundColor: "#2C2C2C", // Color of the track
  },
  "& .MuiSlider-rail": {
    backgroundColor: "#D3D3D3", // Color of the rail (unfilled part of the track)
  },
  "& .MuiSlider-root": {
    color: "#2C2C2C",
  },
}));
