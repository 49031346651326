import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

function WeightageScore({ matchedData }) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography
          sx={{
            fontSize: {
              xs: "20px", // Mobile
              sm: "24px", // Tablet
              md: "28px", // Small Laptop
              lg: "30px", // Large Laptop/Desktop
            },
          }}
          fontWeight={"bold"}
        >
          {"Matching Score"}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Box>
          <TableContainer component={Paper} sx={{ overflowX: "auto" }}>
            <Table
              sx={{
                "& .MuiTableCell-root": {
                  border: "1px solid",
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      position: "sticky",
                      left: 0,
                      backgroundColor: "white", // To ensure the sticky cell has a background
                      zIndex: 1, // To stay above other cells when scrolling
                      fontWeight: "800",
                      fontSize: "16px",
                    }}
                  >
                    {"Category"}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ fontWeight: "800", fontSize: "16px" }}
                  >
                    {"Name"}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ fontWeight: "800", fontSize: "16px" }}
                  >
                    {"Score"}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      fontWeight: "800",
                      fontSize: "16px",
                      "@media (max-width: 1300px)": {
                        whiteSpace: "normal",
                        wordBreak: "break-word",
                      },
                    }}
                  >
                    {"Weightage"}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      fontWeight: "800",
                      fontSize: "16px",
                      "@media (max-width: 1300px)": {
                        whiteSpace: "normal",
                        wordBreak: "break-word",
                      },
                    }}
                  >
                    {"Weightage Score"}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      fontWeight: "800",
                      fontSize: "16px",
                    }}
                  >
                    {"Justification"}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {matchedData?.results?.map((row) => (
                  <TableRow key={row.gpa}>
                    <TableCell
                      align="left"
                      sx={{
                        position: "sticky",
                        left: 0,
                        backgroundColor: "white",
                        fontSize: "18px",
                      }}
                    >
                      {row.category}
                    </TableCell>

                    <TableCell
                      align="left"
                      sx={{
                        fontSize: "18px",
                        "@media (max-width: 1300px)": {
                          whiteSpace: "normal",
                          wordBreak: "break-word",
                        },
                      }}
                    >
                      {row.name}
                    </TableCell>
                    <TableCell align="left" sx={{ fontSize: "18px" }}>
                      {row.score ? row.score.toFixed(2) : "0.00"}
                    </TableCell>
                    <TableCell align="left" sx={{ fontSize: "18px" }}>
                      {row.weightage ? row.weightage.toFixed(2) : "0.00"}
                    </TableCell>
                    <TableCell align="left" sx={{ fontSize: "18px" }}>
                      {row.weightage_score
                        ? row.weightage_score.toFixed(2)
                        : "0.00"}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontSize: "18px",
                      }}
                    >
                      {row.justification ? row.justification : "-"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>
    </Grid>
  );
}

export default WeightageScore;
