import React from "react";
import { Modal, Box, Button, Typography } from "@mui/material";
import { StyledButton } from "../../components/CandidateHub/Components/StyledComponents";

const SnackModal = ({ open, message, color, onClose }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          // textAlign: "center", // Center text inside the modal
          width: "400px",
        }}
      >
        <Typography
          id="modal-title"
          component="h2"
          sx={{ color: color === "success" ? "green" : "red" }}
        >
          {message || "Something went wrong!"}
        </Typography>
        <StyledButton
          variant="contained"
          onClick={onClose}
          sx={{
            mt: 3,
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          OK
        </StyledButton>
      </Box>
    </Modal>
  );
};

export default SnackModal;
