import {
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  StyledButton,
  StyledFlexRowView,
} from "../../CandidateHub/Components/StyledComponents";
import AddIcon from "@mui/icons-material/Add";
import AddNoteDialog from "../../../commonComponents/AddNoteDialog/AddNoteDialog";
import Notes from "../../CandidateHub/Components/Notes";
import {
  ConfirmSettingButtonGrid,
  ConfirmSettingsGreyContainer,
  ConfrimSettingsContainer,
  InterviewSettingsContainerGrid,
  InterviewTouchContainer,
  StyledBoldTypo,
  StyledCheckBoxAll,
  StyledSpaceBetween,
  TierContainer,
} from "./StyledComponents";
import { StyledCheckBox } from "../../../commonComponents/StyledComponents";

function ConfirmSettings({
  handleStep,
  formPrepopValue,
  handleChangeInterview,
  handleClearInterview,
  handleAddNotesDialogClose,
  handleSaveAddNotes,
  handleAddNotes,
  addNotesDialogOpen,
  noteList,
  handleAddAnotherNote,
  handleNoteTextChange,
  handlePhoneEmailTextChange,
  handleNoteDelete,
  username,
  notesTableList,
  addNoteLoading,
}) {
  const handleBackClick = () => {
    handleStep(1);
  };

  const getDynamicLabel = (index) => {
    const ordinalNumbers = [
      "First Touch",
      "Second Touch",
      "Third Touch",
      "Fourth Touch",
      "Fifth Touch",
      "Sixth Touch",
      "Seventh Touch",
      "Eighth Touch",
      "Ninth Touch",
      "Tenth Touch",
    ];
    return `${ordinalNumbers[index] || `Touch ${index + 1}`}`;
  };

  const typeList = [{ label: "Phone" }, { label: "Email" }, { label: "Text" }];

  const currentDate = new Date().toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
  });
  return (
    <>
      <Grid container spacing={3}>
        <ConfrimSettingsContainer container item xs={12}>
          <Typography variant="h5" fontWeight={"bold"}>
            {"Confirm Settings"}
          </Typography>
        </ConfrimSettingsContainer>

        <Grid item xs={12} md={5}>
          <div>
            <StyledBoldTypo>{"Job Owner"}</StyledBoldTypo>
            <TextField
              id="title"
              label=""
              placeholder="Job Owner"
              size="small"
              fullWidth
              name="Job Owner"
              value={""}
            />
          </div>
        </Grid>

        <Grid item xs={12} md={5}>
          <div>
            <StyledBoldTypo>{"Hiring Manager"}</StyledBoldTypo>
            <TextField
              id="title"
              label=""
              placeholder="Hiring Manager"
              size="small"
              fullWidth
              name="Hiring Manager"
              value={""}
            />
          </div>
        </Grid>

        <Grid item xs={12} md={5}>
          <div>
            <StyledBoldTypo>{"Delegates"}</StyledBoldTypo>
            <TextField
              id="title"
              label=""
              placeholder="Delegates"
              size="small"
              fullWidth
              name="Job Owner"
              value={""}
              multiline
              rows={4}
            />
          </div>
        </Grid>

        <Grid item xs={12} md={5}>
          <div>
            <StyledBoldTypo>{"Hiring Manager Delegates"}</StyledBoldTypo>
            <TextField
              id="title"
              label=""
              placeholder="Hiring Manager Delegates"
              size="small"
              fullWidth
              name="Job Owner"
              value={""}
              multiline
              rows={4}
            />
          </div>
        </Grid>

        <Grid item xs={12} md={5}>
          <ConfirmSettingsGreyContainer>
            <StyledSpaceBetween>
              <StyledBoldTypo>{"Re-publish jobs to"}</StyledBoldTypo>
              <ArrowDropDownIcon />
            </StyledSpaceBetween>

            <TierContainer>
              <Typography>{"Tier 1"}</Typography>
              <FormControlLabel
                control={<StyledCheckBoxAll defaultChecked />}
                label="All"
              />
            </TierContainer>
            <Typography>{"Tier 2"}</Typography>
            <Typography>{"Tier 3"}</Typography>
            <TextField
              id="title"
              label=""
              placeholder=""
              size="small"
              fullWidth
              name="Job Owner"
              value={""}
              multiline
              rows={4}
            />
          </ConfirmSettingsGreyContainer>
        </Grid>

        <Grid item xs={12} md={5}>
          <ConfirmSettingsGreyContainer>
            <StyledSpaceBetween>
              <StyledBoldTypo>{"Per-ID Canditate info"}</StyledBoldTypo>
              <ArrowDropDownIcon />
            </StyledSpaceBetween>

            <TextField
              id="title"
              label=""
              placeholder="Email"
              size="small"
              fullWidth
              name="Job Owner"
              value={""}
            />

            <TextField
              id="title"
              label=""
              placeholder="Phone"
              size="small"
              fullWidth
              name="Job Owner"
              value={""}
            />

            <div>
              <StyledBoldTypo>{"Preferred Agency"}</StyledBoldTypo>
              <TextField
                id="title"
                label=""
                placeholder="Agency Name / Email Id"
                size="small"
                fullWidth
                name="Job Owner"
                value={""}
              />
            </div>

            <div>
              <StyledBoldTypo>{"Candidate Resume"}</StyledBoldTypo>
              <TextField
                id="title"
                label=""
                placeholder="Attach Resume"
                size="small"
                fullWidth
                name="Job Owner"
                value={""}
              />
            </div>
          </ConfirmSettingsGreyContainer>
        </Grid>

        <Grid item xs={12}>
          <StyledBoldTypo>{"Interview Settings"}</StyledBoldTypo>
        </Grid>

        <Grid item xs={12}>
          <InterviewSettingsContainerGrid container spacing={3}>
            <Grid item xs={12} md={6}>
              <InterviewTouchContainer>
                {formPrepopValue?.interviewType?.map((point, index) => (
                  <TextField
                    key={index}
                    label={getDynamicLabel(index)}
                    placeholder=""
                    size="small"
                    fullWidth
                    value={point}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) =>
                      handleChangeInterview(index, e.target.value)
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{ cursor: "pointer" }}
                        >
                          <CancelIcon
                            onClick={() => handleClearInterview(index)}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                ))}
              </InterviewTouchContainer>
            </Grid>
            <Grid item xs={12} md={6}>
              <InterviewTouchContainer>
                <StyledBoldTypo>{"Seeded Pre-Screen Questions"}</StyledBoldTypo>
                <TextField
                  id="title"
                  label=""
                  placeholder="Not Provided"
                  size="small"
                  fullWidth
                  name="title"
                  value={""}
                  multiline
                  rows={4}
                />

                <StyledFlexRowView>
                  <StyledCheckBox defaultChecked />
                  <Typography>{"Enable Dynamic Interview Module"}</Typography>
                </StyledFlexRowView>
              </InterviewTouchContainer>
            </Grid>
          </InterviewSettingsContainerGrid>
        </Grid>

        {notesTableList?.length ? (
          <Grid item xs={12}>
            <Notes notesTableList={notesTableList} isTrue={true} />
          </Grid>
        ) : null}

        <ConfirmSettingButtonGrid container item xs={12} spacing={3}>
          <StyledButton
            variant="contained"
            endIcon={<AddIcon />}
            onClick={handleAddNotes}
          >
            {"Add Notes"}
          </StyledButton>
          <StyledButton variant="outlined">{"Submit"}</StyledButton>
          <StyledButton variant="contained">{"Share"}</StyledButton>

          <StyledButton variant="contained">{"Re-generate JD"}</StyledButton>

          <StyledButton variant="contained">{"Delete"}</StyledButton>
        </ConfirmSettingButtonGrid>

        <Grid
          container
          alignItems={"center"}
          justifyContent={"space-between"}
          item
          xs={12}
        >
          <StyledButton variant="outlined" onClick={handleBackClick}>
            Back
          </StyledButton>
          <StyledButton
            // onClick={handleNext}
            variant="contained"
          >
            {"Next"}
          </StyledButton>
        </Grid>
      </Grid>

      {/* Notes Dialog */}
      <AddNoteDialog
        handleAddNotesDialogClose={handleAddNotesDialogClose}
        handleSaveAddNotes={handleSaveAddNotes}
        addNotesDialogOpen={addNotesDialogOpen}
        noteList={noteList}
        handleAddAnotherNote={handleAddAnotherNote}
        handleNoteTextChange={handleNoteTextChange}
        handlePhoneEmailTextChange={handlePhoneEmailTextChange}
        handleNoteDelete={handleNoteDelete}
        username={username}
        notesTableList={notesTableList}
        addNoteLoading={addNoteLoading}
        currentDate={currentDate}
        typeList={typeList}
      />
    </>
  );
}

export default ConfirmSettings;
