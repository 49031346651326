import { Checkbox, Grid, Typography } from "@mui/material";
import React from "react";
import profile from "../../../images/logos/profile.png";
import email from "../../../images/logos/email_icon.png";
import phone from "../../../images/logos/phone_icon.png";
import home from "../../../images/logos/home_icon.png";
import linkedin from "../../../images/logos/linkedin.png";

import {
  OverallRatingBox,
  StyledButton,
  StyledCandidateBio,
  StyledFlexColumnView,
  StyledFlexRowView,
  StyledImg,
  StyledProfile,
  StyledProfileView,
} from "./StyledComponents";
import ResumeGaugeChart from "./ResumeGaugeChart";
import ResumeRating from "./ResumeRating";

function CandidateProfile({ resumeValue }) {
  return (
    <Grid container spacing={3}>
      {/* Profile Section */}
      <Grid item xs={12} md={8}>
        <StyledFlexRowView
          sx={{
            flexWrap: { xs: "wrap", sm: "nowrap" },
            justifyContent: { xs: "center", sm: "flex-start" },
          }}
        >
          <StyledButton
            variant="contained"
            sx={{
              color: "white",
              backgroundColor: "Black",
              "&:hover": {
                backgroundColor: "Black",
              },
            }}
          >
            {"View Resume"}
          </StyledButton>
          <StyledButton
            variant="contained"
            sx={{
              color: "white",
              backgroundColor: "Black",
              "&:hover": {
                backgroundColor: "Black",
              },
            }}
          >
            {"Compare"}
          </StyledButton>
          <StyledButton
            variant="contained"
            sx={{
              color: "white",
              backgroundColor: "Black",
              "&:hover": {
                backgroundColor: "Black",
              },
            }}
          >
            {"Share"}
          </StyledButton>
          <StyledButton
            variant="contained"
            sx={{
              color: "white",
              backgroundColor: "Black",
              "&:hover": {
                backgroundColor: "Black",
              },
            }}
          >
            {"Fix Resume"}
          </StyledButton>
        </StyledFlexRowView>
        <StyledProfileView
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: {
              xs: "center",
              sm: "center",
              md: "start",
              lg: "start",
            },
            justifyContent: "center",
            gap: 2, // Adding space between elements
            py: 2, // Padding for internal spacing
          }}
        >
          <StyledProfile
            src={profile}
            style={{
              height: "150px",
              width: "150px",
            }}
          />
          <StyledFlexColumnView
            sx={{
              alignItems: { xs: "center", sm: "flex-start" },
              textAlign: { xs: "center", sm: "left" },
              width: "100%", // Full width on mobile
              mt: { xs: 2, sm: 0 }, // Margin top for spacing on mobile
            }}
          >
            <Typography variant="h5" fontWeight={"bold"}>
              {resumeValue?.candidate_name}
            </Typography>
            {resumeValue?.email !== "NA" &&
            resumeValue?.email !== "" &&
            resumeValue?.email !== undefined ? (
              <StyledFlexRowView>
                <StyledImg src={email} sx={{ mr: 1 }} />
                <Typography color={"#828282"}>{resumeValue?.email}</Typography>
              </StyledFlexRowView>
            ) : null}
            {resumeValue?.phone_number !== "NA" &&
            resumeValue?.phone_number !== "" ? (
              <StyledFlexRowView>
                <StyledImg src={phone} sx={{ mr: 1 }} />
                <Typography color={"#828282"}>
                  {resumeValue?.phone_number}
                </Typography>
              </StyledFlexRowView>
            ) : null}
            {resumeValue?.location !== "NA" && resumeValue?.location !== "" ? (
              <StyledFlexRowView>
                <StyledImg src={home} sx={{ mr: 1 }} />
                <Typography color={"#828282"}>
                  {resumeValue?.location}
                </Typography>
              </StyledFlexRowView>
            ) : null}
            {resumeValue?.linkedin_url !== "NA" &&
            resumeValue?.linkedin_url !== "" ? (
              <StyledFlexRowView>
                <StyledImg src={linkedin} sx={{ mr: 1 }} />
                <Typography color={"#828282"}>
                  {resumeValue?.linkedin_url}
                </Typography>
              </StyledFlexRowView>
            ) : null}
            <Typography>
              {`${resumeValue?.title}  ${
                resumeValue?.visa !== "NA" && resumeValue?.visa !== ""
                  ? `- ${resumeValue?.visa}`
                  : ""
              } - ${resumeValue?.company_profile}`}
            </Typography>
            <Typography>{resumeValue?.seniority_level}</Typography>
            <StyledFlexRowView>
              <Typography>{"Willing to Relocate"}</Typography>
              <Checkbox
                sx={{
                  padding: "0px !important",
                  color: "black",
                  "&.Mui-checked": {
                    color: "black",
                  },
                }}
                checked={resumeValue?.willing_to_relocate}
              />
            </StyledFlexRowView>
            {resumeValue?.rate !== "NA" && resumeValue?.rate !== "" ? (
              <Typography>{`Rate : ${
                resumeValue?.rate ? resumeValue?.rate : "-"
              }`}</Typography>
            ) : null}
          </StyledFlexColumnView>
        </StyledProfileView>
      </Grid>

      {/* Rating Section */}
      <Grid
        item
        xs={12}
        md={4}
        sx={{ display: "flex", justifyContent: { xs: "center" } }}
      >
        <OverallRatingBox>
          <ResumeGaugeChart resumeValue={resumeValue} />
          <ResumeRating resumeValue={resumeValue} />
        </OverallRatingBox>
      </Grid>

      {/* Bio Section */}
      <Grid item xs={12}>
        <StyledCandidateBio>
          <Typography fontWeight={"bold"} variant={"h6"}>
            {"Resume Analysis:"}
          </Typography>
          <Typography>{resumeValue?.candidate_bio}</Typography>
        </StyledCandidateBio>
      </Grid>
    </Grid>
  );
}

export default CandidateProfile;
