import { Box, Grid, IconButton, Skeleton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CandidateSearch from "./Components/CandidateSearch";
import CandidateProfile from "./Components/CandidateProfile";
import SkillCard from "./Components/SkillCard";
import JobHistory from "./Components/JobHistory";
import axios from "axios";
import { getResumeData, addNotes, getNotes } from "../../api/api";
import CustomSnack from "../../commonComponents/Snack/CustomSnack";
import { useLocation } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { StyledButton, StyledFlexRowView } from "./Components/StyledComponents";

function CandidateHubIndex() {
  const location = useLocation();
  const history = useHistory();
  const username = localStorage.getItem("username");
  const [pageLoader, setPageLoader] = useState(false);
  const [snack, setSnack] = useState({
    open: false,
    color: "",
    message: "",
  });
  const [resumeValue, setResumeValue] = useState({});
  //Add Notes
  const [addNotesDialogOpen, setAddNotesDialogOpen] = useState(false);
  const [noteList, setNoteList] = useState([
    { note: "", interaction: "", added_by: username },
  ]);
  const [notesTableList, setNotesTableList] = useState([]);
  const [addNoteLoading, setAddNoteLoading] = useState(false);

  // Parse the query string
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id"); // Retrieves the value of 'id'

  const handleAddNotesDialogClose = () => {
    setAddNotesDialogOpen(false);
    setNoteList([{ note: "", interaction: "", added_by: username }]);
  };

  const getNotesDetatil = (id) => {
    axios
      .get(getNotes(id))
      .then((res) => {
        setNotesTableList(res?.data?.data);
      })
      .catch((err) => {
        setSnack({
          open: true,
          color: "error",
          message: err?.response?.data?.message,
        });
      });
  };

  // Handle saving the notes
  const handleSaveAddNotes = () => {
    const hasEmptyFields = noteList.some(
      (noteItem) => !noteItem.note || !noteItem.interaction
    );

    if (hasEmptyFields) {
      setSnack({
        open: true,
        color: "error",
        message: "Note and Interaction Type fields are required",
      });
      return; // Stop execution if validation fails
    }

    if (id) {
      setAddNoteLoading(true);
      axios
        .post(addNotes(id), noteList)
        .then((response) => {
          if (response?.data?.success === true) {
            setSnack({
              open: true,
              color: "success",
              message: response?.data?.message,
            });
            setAddNoteLoading(false);
          } else {
            setSnack({
              open: true,
              color: "error",
              message: response?.data?.message,
            });
            setAddNoteLoading(false);
          }
          getNotesDetatil(id);
          setAddNotesDialogOpen(false);
          setNoteList([{ note: "", interaction: "", added_by: username }]);
        })
        .catch((err) => {
          setSnack({
            open: true,
            color: "error",
            message: err?.response?.data?.message,
          });
          setAddNoteLoading(false);
        });
    }
  };
  console.log(notesTableList, "notesTableList");

  // Handle adding another note and interaction type
  const handleAddAnotherNote = () => {
    setNoteList([
      ...noteList,
      { note: "", interaction: "", added_by: username },
    ]); // Add new empty note object
  };

  // Handle note text change for a specific index
  const handleNoteTextChange = (index, e) => {
    const newNotes = [...noteList];
    newNotes[index].note = e.target.value; // Update note for the corresponding index
    setNoteList(newNotes);
  };

  // Handle interaction type change for a specific index
  const handlePhoneEmailTextChange = (index, value) => {
    const newNotes = [...noteList];
    newNotes[index].interaction = value?.label || ""; // Ensure you set the correct field
    setNoteList(newNotes);
  };

  const handleAddNotes = () => {
    setAddNotesDialogOpen(true);
  };

  const handleNoteDelete = (indexToDelete) => {
    let arr = [...noteList];
    arr.splice(indexToDelete, 1);
    setNoteList(arr);
  };

  useEffect(() => {
    const fetchData = async () => {
      setPageLoader(true);
      try {
        const {
          data: { data },
        } = await axios.get(getResumeData(id));
        console.log(data, "data");
        setResumeValue({
          candidate_name: data?.personal_details?.candidate_name,
          email: data?.personal_details?.email,
          phone_number: data?.personal_details?.phone_number,
          location: data?.personal_details?.location,
          linkedin_url: data?.personal_details?.linkedin_url,
          title: data?.personal_details?.title,
          company_profile: data?.personal_details?.company_profile,
          seniority_level: data?.personal_details?.seniority_level,
          willing_to_relocate: data?.personal_details?.willing_to_relocate,
          rate: data?.personal_details?.rate,
          visa: data?.personal_details?.visa,
          candidate_bio: data?.personal_details?.candidate_bio,
          all_skills: data?.all_skills,
          job_history: data?.job_history,
          elc_table: data?.elc_table,
          other_info: data?.other_info,
          resume_score: data?.resume_score,
          score: data?.score,
          score_label: data?.score_label,
        });
        setPageLoader(false);
      } catch (error) {
        setSnack({
          open: true,
          color: "error",
          message: error?.response?.data?.message,
        });
        setPageLoader(false);
        console.log(error, "err");
      }
    };
    fetchData();
    getNotesDetatil(id);
  }, [id]);

  const handleBackUpload = () => {
    history.push(`/home/candidate-hub`);
  };

  const handleSnackClose = () => {
    setSnack({
      open: false,
      color: "",
      message: "",
    });
  };
  const handleBackClick = () => {
    history.goBack();
  };
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <StyledFlexRowView>
            <StyledButton
              variant="outlined"
              startIcon={<KeyboardBackspaceIcon />}
              onClick={handleBackClick}
            >
              Back
            </StyledButton>
            <Typography variant={"h5"} fontWeight={"bold"}>
              {"Candidate Search"}
            </Typography>
          </StyledFlexRowView>
        </Grid>

        <Grid item xs={12}>
          <CandidateSearch resumeValu={resumeValue} />
        </Grid>
        {pageLoader ? (
          Array.from(new Array(12)).map((el, ind) => (
            <Grid item xs={12} sm={4} key={`key-${ind}`}>
              <Skeleton
                key={`key-${ind.toString()}`}
                width={"100%"}
                height={100}
              />
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <CandidateProfile resumeValue={resumeValue} />
              </Grid>

              <Grid item xs={12}>
                <SkillCard resumeValue={resumeValue} />
              </Grid>

              <Grid item xs={12}>
                <JobHistory
                  resumeValue={resumeValue}
                  handleBackUpload={handleBackUpload}
                  handleAddNotesDialogClose={handleAddNotesDialogClose}
                  handleSaveAddNotes={handleSaveAddNotes}
                  handleAddNotes={handleAddNotes}
                  addNotesDialogOpen={addNotesDialogOpen}
                  noteList={noteList}
                  handleAddAnotherNote={handleAddAnotherNote}
                  handleNoteTextChange={handleNoteTextChange}
                  handlePhoneEmailTextChange={handlePhoneEmailTextChange}
                  handleNoteDelete={handleNoteDelete}
                  username={username}
                  notesTableList={notesTableList}
                  addNoteLoading={addNoteLoading}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <CustomSnack
        open={snack.open}
        color={snack.color}
        message={snack.message}
        onClose={handleSnackClose}
      />
    </Box>
  );
}

export default CandidateHubIndex;
