import {
  Button,
  Rating,
  ToggleButtonGroup,
  toggleButtonGroupClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledFlexRowView = styled("div")(() => ({
  alignItems: "center",
  display: "flex",
  gap: "10px",
}));

export const StyledFlexColumnView = styled("div")(() => ({
  alignItems: "center",
  display: "flex",
  gap: "10px",
  flexDirection: "column",
  alignItems: "flex-start",
}));

export const StyledButton = styled(Button)(() => ({
  color: "white",
  backgroundColor: "Black",
  "&:hover": {
    backgroundColor: "Black",
  },
  textTransform: "none",
  borderRadius: "8px",
}));

export const StyledProfile = styled("img")(() => ({
  height: "177px",
  width: "200px !important",
}));

export const StyledImg = styled("img")(() => ({
  height: "20px",
  width: "20px !important",
}));

export const StyledProfileView = styled("div")(() => ({
  alignItems: "center",
  display: "flex",
  gap: "10px",
  alignItems: "flex-start",
}));

export const StyledCandidateBio = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  paddingRight: "16px", // Default padding for mobile
  paddingLeft: "16px", // Default padding for mobile
  [theme.breakpoints.up("sm")]: {
    paddingRight: "20px", // Padding for tablets
    paddingLeft: "20px", // Padding for tablets
  },
  [theme.breakpoints.up("md")]: {
    paddingRight: "50px", // Padding for medium screens
    paddingLeft: "50px", // Padding for medium screens
  },
}));

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
  ({ theme }) => ({
    [`& .${toggleButtonGroupClasses.grouped}`]: {
      margin: theme.spacing(0.5),
      border: 0,
      borderRadius: theme.shape.borderRadius,
      [`&.${toggleButtonGroupClasses.disabled}`]: {
        border: 0,
      },
    },
    [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
      {
        marginLeft: -1,
        borderLeft: "1px solid transparent",
      },
    "& .MuiToggleButton-root.Mui-selected": {
      color: "black", // Change text color
      backgroundColor: "white", // Change background color
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.12)", // Optional: Change hover background color
      },
    },
  })
);

export const StyledRating = styled(Rating)(() => ({
  "& .MuiRating-root": {
    color: "black", // Root color
  },
  "& .MuiRating-icon": {
    color: "black", // Root color
  },
  "& .MuiRating-iconFilled": {
    color: "black", // Color for filled stars
  },
  "& .MuiRating-iconHover": {
    color: "black", // Color on hover
  },
}));

export const RatingFlexRowView = styled("div")(() => ({
  // alignItems: "center",
  display: "flex",
  gap: "10px",
  justifyContent: "space-between",
}));

export const OverallRatingBox = styled("div")(() => ({
  alignItems: "center",
  display: "flex",
  gap: "10px",
  flexDirection: "column",
  alignItems: "center",
}));

export const StyledArrowImg = styled("img")(() => ({
  height: "35px",
  width: "35px !important",
  cursor: "pointer",
}));

export const StyledTranslateImg = styled("img")(() => ({
  height: "55px",
  width: "55px !important",
  cursor: "pointer",
}));

export const IconWrapper = styled("div")({
  height: "60px",
  width: "60px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
