import React from "react";
import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from "./StyledComponents";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ArrowForwardIosSharp, ExpandMore } from "@mui/icons-material";

const TableList = ({ data }) => {
  return (
    <Box>
      <TableContainer component={Paper} sx={{ overflowX: "auto" }}>
        <Table
          sx={{
            "&.MuiTable-root .MuiTableCell-root": {
              border: "1px solid black",
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  "&:first-child": {
                    // Correctly targeting the first-child pseudo-class
                    position: "unset !important",
                    left: "unset !important",
                    zIndex: "unset !important",
                    backgroundColor: "unset !important",
                  },
                  fontWeight: "800",
                  fontSize: "16px",
                }}
              >
                {"Skill"}
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontWeight: "800", fontSize: "16px" }}
              >
                {"Criticality Score"}
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontWeight: "800", fontSize: "16px" }}
              >
                {"Exp in Yrs"}
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  fontWeight: "800",
                  fontSize: "16px",
                  "@media (max-width: 1300px)": {
                    whiteSpace: "normal",
                    wordBreak: "break-word",
                  },
                }}
              >
                {"category"}
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  fontWeight: "800",
                  fontSize: "16px",
                  "@media (max-width: 1300px)": {
                    whiteSpace: "normal",
                    wordBreak: "break-word",
                  },
                }}
              >
                {"Expertise Level"}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontWeight: "800",
                  fontSize: "16px",
                }}
              >
                {"Match Score"}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontWeight: "800",
                  fontSize: "16px",
                }}
              >
                {"Justification"}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontWeight: "800",
                  fontSize: "16px",
                }}
              >
                {"Candidate Skill"}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontWeight: "800",
                  fontSize: "16px",
                }}
              >
                {"Candidate exp in yrs"}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontWeight: "800",
                  fontSize: "16px",
                }}
              >
                {"Candidate last used"}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontWeight: "800",
                  fontSize: "16px",
                }}
              >
                {"Candidate Expertise Level"}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontWeight: "800",
                  fontSize: "16px",
                }}
              >
                {"Normalized Weight"}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontWeight: "800",
                  fontSize: "16px",
                }}
              >
                {"Criticality Weighted Score"}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontWeight: "800",
                  fontSize: "16px",
                }}
              >
                {"Recency Weight"}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontWeight: "800",
                  fontSize: "16px",
                }}
              >
                {"Final Results"}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.length === 0 ? (
              <TableRow>
                <TableCell colSpan={15} align="center">
                  No data found
                </TableCell>
              </TableRow>
            ) : (
              data?.map((row) => (
                <TableRow key={row.skill}>
                  <TableCell
                    align="left"
                    sx={{
                      "&:first-child": {
                        // Correctly targeting the first-child pseudo-class
                        position: "unset !important",
                        left: "unset !important",
                        zIndex: "unset !important",
                        backgroundColor: "unset !important",
                      },
                      fontSize: "18px",
                    }}
                  >
                    {row.skill}
                  </TableCell>

                  <TableCell
                    align="left"
                    sx={{
                      fontSize: "18px",
                      "@media (max-width: 1300px)": {
                        whiteSpace: "normal",
                        wordBreak: "break-word",
                      },
                    }}
                  >
                    {row.criticality_score}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: "18px" }}>
                    {row.experience_in_yrs}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: "18px" }}>
                    {row.category}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: "18px" }}>
                    {row.expertise_level}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      fontSize: "18px",
                    }}
                  >
                    {row.match_score}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: "18px" }}>
                    {row.justification}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: "18px" }}>
                    {row.candidate_skill}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: "18px" }}>
                    {row.candidate_exp_in_yrs}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: "18px" }}>
                    {row.candidate_last_used}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: "18px" }}>
                    {row.candidate_expertise_level}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: "18px" }}>
                    {row.normalized_weight
                      ? row.normalized_weight.toFixed(3)
                      : "0.000"}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: "18px" }}>
                    {row.criticality_weighted_score
                      ? row.criticality_weighted_score.toFixed(2)
                      : "0.00"}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: "18px" }}>
                    {row.recency_weight}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: "18px" }}>
                    {row.final_results && !isNaN(parseFloat(row.final_results))
                      ? parseFloat(row.final_results).toFixed(2)
                      : "0.00"}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

function SkillScoreList({ matchedData }) {
  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  console.log(matchedData?.skill_calculation, "matchedData?.skill_calculation");
  return (
    <div>
      {matchedData?.error_message ? null : (
        <>
          <StyledAccordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <StyledAccordionSummary
              expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />}
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography sx={{ marginLeft: "10px" }}>
                {"Hard Skills"}
              </Typography>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <TableList
                data={matchedData?.skill_calculation?.req_hard_skills}
              />
            </StyledAccordionDetails>
          </StyledAccordion>

          <StyledAccordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <StyledAccordionSummary
              expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />}
              aria-controls="panel2d-content"
              id="panel2d-header"
            >
              <Typography sx={{ marginLeft: "10px" }}>
                {"Recommended Hard Skills"}
              </Typography>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <TableList
                data={matchedData?.skill_calculation?.n2h_hard_skills}
              />
            </StyledAccordionDetails>
          </StyledAccordion>

          <StyledAccordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <StyledAccordionSummary
              expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />}
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <Typography sx={{ marginLeft: "10px" }}>
                {"Soft Skills"}
              </Typography>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <TableList
                data={matchedData?.skill_calculation?.req_soft_skills}
              />
            </StyledAccordionDetails>
          </StyledAccordion>

          <StyledAccordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <StyledAccordionSummary
              expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />}
              aria-controls="panel4d-content"
              id="panel4d-header"
            >
              <Typography sx={{ marginLeft: "10px" }}>
                {"Recommended Soft Skills"}
              </Typography>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <TableList
                data={matchedData?.skill_calculation?.n2h_soft_skills}
              />
            </StyledAccordionDetails>
          </StyledAccordion>
        </>
      )}
    </div>
  );
}

export default SkillScoreList;
