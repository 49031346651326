const FEEDBACK_DASHBOARD = {
  setState: "setFeedbackDashboardState",
  clearState: "clearFeedbackDashboardState",
};

const FUNNEL = {
  setState: "setFunnelState",
  clearState: "clearFunnelState",
};

const QUALIFY = {
  setState: "setQualifyState",
  clearState: "clearQualifyState",
};

const TEST = {
  setState: "setTestState",
  clearState: "clearTestState",
};

const RECOMMEND = {
  setState: "setRecommendState",
  clearState: "clearRecommendState",
};

const CLIENT_INTERVIEW = {
  setState: "setClientInterviewState",
  clearState: "clearClientInterviewState",
};

const INTAKE_FORM = {
  setState: "setIntakeFormState",
  clearState: "clearIntakeFormState",
};

const QUICK_MATCH = {
  setState: "setQuickMatchState",
  clearState: "clearQuickMatchState",
};

const CANDIDATE_HUB = {
  setState: "setCandidateHubState",
  clearState: "clearCandidateHubState",
};

export {
  FEEDBACK_DASHBOARD,
  FUNNEL,
  QUALIFY,
  TEST,
  RECOMMEND,
  CLIENT_INTERVIEW,
  INTAKE_FORM,
  QUICK_MATCH,
  CANDIDATE_HUB,
};
