import {
  AppBar,
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  Toolbar,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useLocation,
} from "react-router-dom";
// Redux
import { userLoginStatus } from "../../redux/actions/actionCreator";
import MenuIcon from "@mui/icons-material/Menu";
import AiSidebar from "../AISidebar/Aisidebar";
import talairo_ai from "../../images/svgs/talairo_ai.svg";
import talairo_title from "../../images/svgs/talairo_title.svg";

function AiHeader({ isWeb, isMobile }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const Logoutuser = () => {
    localStorage.clear();
    dispatch(
      userLoginStatus({
        code: 0,
        login: false,
        msg: "User logged out!",
      })
    );
    /*alert.show(
        "Your have been successfully logged out of the system",
        {
          header: "Logged Out!",
          ctype: "info",
        }
      );*/
    // history.push("/intake/form");
    history.push("/home/dashboard-ai");

    window.location.reload();
  };

  return (
    <Box>
      <AppBar
        component="nav"
        position="static"
        sx={{
          backgroundColor: "white",
          boxShadow: "none",
        }}
      >
        <Toolbar>
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Grid item container xs>
              {isWeb ? null : (
                <>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    // sx={{ mr: 2, display: { sm: 'none' } }}
                  >
                    <MenuIcon sx={{ color: "black" }} />
                  </IconButton>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "4px",
                      alignItems: "center",
                      padding: "10px",
                    }}
                  >
                    <img className="tickImg" src={talairo_ai} />
                    <img className="talairoImg" src={talairo_title} />
                  </div>
                </>
              )}
            </Grid>
            {isMobile ? null : (
              <>
                <Grid item>
                  <Button
                    sx={{
                      color: "black",
                      backgroundColor: "white",
                      "&:hover": {
                        backgroundColor: "black",
                        color: "white",
                      },
                      textTransform: "none",
                      fontWeight: "bold",
                    }}
                  >
                    {"Help"}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    sx={{
                      color: "black",
                      backgroundColor: "white",
                      "&:hover": {
                        backgroundColor: "black",
                        color: "white",
                      },
                      textTransform: "none",
                      fontWeight: "bold",
                    }}
                  >
                    {"Notification"}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    sx={{
                      color: "black",
                      backgroundColor: "white",
                      "&:hover": {
                        backgroundColor: "black",
                        color: "white",
                      },
                      textTransform: "none",
                      fontWeight: "bold",
                    }}
                  >
                    {"Profile"}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    sx={{
                      color: "white",
                      backgroundColor: "Black",
                      "&:hover": {
                        backgroundColor: "Black",
                      },
                      textTransform: "none",
                      fontWeight: "bold",
                    }}
                    onClick={() => {
                      Logoutuser();
                    }}
                  >
                    {"Sign Out"}
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Toolbar>
      </AppBar>

      {/* TabMobile Drawer */}
      <Drawer
        // container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          // display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: "270px" },
        }}
      >
        <AiSidebar isMobile={isMobile} />
      </Drawer>
    </Box>
  );
}

export default AiHeader;
