import React from "react";
import { Box, Typography } from "@mui/material";

function StyledCircle({ percentage, label }) {
  const circleStyle = {
    width: "100px",
    height: "100px",
    backgroundColor: "#e65100", // Orange color
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)", // For the shadow effect
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "20px",
      }}
    >
      <Box style={circleStyle}>
        <Typography
          sx={{ color: "black", fontSize: "24px", fontWeight: "bold" }}
        >
          {percentage}%
        </Typography>
      </Box>
      <Typography
        sx={{
          marginTop: "10px",
          fontSize: "14px",
          fontWeight: "bold",
        }}
      >
        {label}
      </Typography>
    </Box>
  );
}

export default function ConfidenceCircle({ matchedData }) {
  return (
    <StyledCircle
      percentage={matchedData?.overall_score ? matchedData?.overall_score : "0"}
      // label="Confidence Level: -"
    />
  );
}
