import { CircularProgress, Grid, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import deleteIco from "../../../images/svgs/delete.svg";
import { StyledButton } from "./StyledComponents";
import axios from "axios";
import { uploadResume } from "../../../api/api";
import CustomSnack from "../../../commonComponents/Snack/CustomSnack";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCandidateHubState,
  setCandidateHubState,
} from "../../../redux/actions/candidateHub";

function UploadResume() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const isNewCandidateHub = location?.state?.isNewCandidateHub;
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(false);
  const [snack, setSnack] = useState({
    open: false,
    color: "",
    message: "",
  });

  const { candidateHubResumeFile } = useSelector((state) => state.candidateHub);

  useEffect(() => {
    if (isNewCandidateHub) {
      setFileName("");
      if (fileInputRef.current) {
        fileInputRef.current.value = ""; // Clear the file input
      }
      dispatch(clearCandidateHubState());
    } else {
      setFileName(candidateHubResumeFile?.name);
    }
  }, [isNewCandidateHub]);

  console.log(isNewCandidateHub, "isNewCandidateHub", candidateHubResumeFile);

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Trigger the file input click
    }
  };

  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      setFileName(file.name); // Update the file name state
      dispatch(
        setCandidateHubState({
          candidateHubResumeFile: file,
        })
      );
    }
  };

  const handleRemoveFile = () => {
    setFileName(""); // Clear the file name state
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Clear the file input
    }
    dispatch(
      setCandidateHubState({
        candidateHubResumeFile: null,
      })
    );
  };

  const handleFirstNextClick = () => {
    setLoading(true);

    let formData = new FormData();
    if (candidateHubResumeFile) {
      formData.append("files", candidateHubResumeFile);
    }
    axios
      .post(uploadResume(), formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })

      .then((response) => {
        if (response?.data?.success === true) {
          setLoading(false);
          history.push(
            `/home/candidate-hub/get-detail?id=${response?.data?.data?.id}`
          );
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err, "err");
        setSnack({
          open: true,
          color: "error",
          message: err?.response?.data?.message,
        });
      });
  };

  const handleSnackClose = () => {
    setSnack({
      open: false,
      color: "",
      message: "",
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5">{"Upload Resume"}</Typography>
      </Grid>
      <Grid item xs={12}>
        {fileName && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Typography
              variant="body2"
              sx={{ marginLeft: "10px", textAlign: "center" }}
            >
              {fileName}
            </Typography>
            <img
              className="deleteImg"
              src={deleteIco}
              alt="delete"
              onClick={handleRemoveFile}
              style={{ cursor: "pointer" }}
            />
          </div>
        )}
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <StyledButton
          variant="contained"
          component="span"
          onClick={handleUploadClick}
        >
          {"Upload File"}
          <input
            ref={fileInputRef}
            type="file"
            //   accept=".svg, .jpg, .png,"
            hidden
            onChange={handleFileChange}
            // value={file}
            name={"file"}
            id={"file"}
          />
        </StyledButton>
      </Grid>

      <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <StyledButton
          onClick={handleFirstNextClick}
          variant="contained"
          startIcon={
            loading ? <CircularProgress color="inherit" size={15} /> : null
          }
          disabled={loading}
        >
          {"Next"}
        </StyledButton>
      </Grid>
      <CustomSnack
        open={snack.open}
        color={snack.color}
        message={snack.message}
        onClose={handleSnackClose}
      />
    </Grid>
  );
}

export default UploadResume;
