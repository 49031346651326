import { Button, Typography } from "@mui/material";
import React from "react";
import {
  ResponsiveContainer,
  Cell,
  PieChart,
  Pie,
  Tooltip,
  Label,
} from "recharts";

export default function ResumeGaugeChart({ resumeValue }) {
  const width = 200;
  const height = 100;
  const chartValue = resumeValue?.score ? resumeValue?.score : 0; // Set this to 93
  const totalValue = 100; // The total value for the gauge
  console.log(resumeValue?.score, "resumeValue?.score");
  const slices = [
    {
      value: chartValue,
      color: "url(#gradient)", // Reference to the gradient
    },
    {
      value: totalValue - chartValue,
      color: "#e0e0e0", // Color for the unfilled portion
    },
  ];

  // Define pieProps here
  const pieProps = {
    startAngle: 180,
    endAngle: 0,
    cx: width / 2,
    cy: width / 2,
    isAnimationActive: false,
  };

  const getLabel = (value) => {
    if (value <= 25) return "Low";
    if (value <= 50) return "Moderate";
    if (value <= 75) return "Good";
    return "Excellent";
  };

  const getLabelColor = (value) => {
    if (value <= 25) return "#FF0000"; // Red for Low
    if (value <= 50) return "#FFA500"; // Orange for Moderate
    if (value <= 75) return "#99cc00"; // Yellow for Good
    return "#008000"; // Green for Excellent
  };

  // Dynamically determine gradient stops
  const gradientStops = [
    { offset: "0%", color: "#FF0000" }, // Red at the start
    { offset: "25%", color: "#ffb833" }, // Orange up to 25%
    { offset: "50%", color: "#ffa500" }, // Yellow up to 50%
    { offset: "75%", color: "#99cc00" }, // Yellow-Green up to 75%
    { offset: "100%", color: "#008000" }, // Green at the end
  ];

  // Filter gradient stops based on chartValue
  const filteredStops = gradientStops.filter((stop) => {
    const percentageValue = parseInt(stop.offset, 10);
    return percentageValue <= (chartValue / 100) * 100;
  });

  return (
    <div style={{ width, height: 150 }}>
      <ResponsiveContainer>
        <PieChart width={width} height={height}>
          <defs>
            <linearGradient id="gradient" x1="10%" y1="20%" x2="100%" y2="10%">
              {filteredStops.map((stop, index) => (
                <stop key={index} offset={stop.offset} stopColor={stop.color} />
              ))}
              {/* Add an extra stop for the filled area */}
              <stop
                offset={`${(chartValue / 100) * 100}%`}
                stopColor={filteredStops[filteredStops.length - 1].color}
              />
              {/* Remaining part should be gray */}
              <stop offset="100%" stopColor="#e0e0e0" />
            </linearGradient>
          </defs>
          <Pie
            stroke="none"
            data={slices}
            innerRadius={(width / 2) * 0.5}
            outerRadius={(width / 2) * 0.8}
            {...pieProps}
          >
            {slices.map((each, i) => (
              <Cell key={`cell-${i}`} fill={slices[i].color} stroke="none" />
            ))}
          </Pie>
          <Tooltip />
          <Pie
            stroke="none"
            fill="none"
            activeIndex={1}
            data={[{ value: chartValue }, { value: totalValue - chartValue }]}
            innerRadius={(width / 2) * 0.5}
            outerRadius={(width / 2) * 0.6}
            {...pieProps}
          >
            <Label
              value={chartValue}
              position="centerBottom"
              offset={-20}
              className="gauge-label"
              fontSize="30px"
              fontWeight="bold"
            />
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <div
        style={{ textAlign: "center", marginTop: "-30px", marginLeft: "10px" }}
      >
        <div
          style={{
            display: "inline-block",
            padding: "5px 5px",
            backgroundColor: getLabelColor(chartValue),
            color: "white",
            borderRadius: "5px",
            fontSize: "14px",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        >
          {resumeValue?.score_label}
        </div>
      </div>
    </div>
  );
}
