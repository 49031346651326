import { Typography } from "@mui/material";
import React, { useState } from "react";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Tooltip,
  Legend,
} from "recharts";

// Custom tick component for PolarAngleAxis
const CustomPolarAngleAxisTick = (props) => {
  const { payload, x, y, textAnchor } = props;

  // Function to break text into lines
  const breakTextIntoLines = (text, maxLineWidth) => {
    const words = text.split(" ");
    let lines = [];
    let currentLine = "";

    words.forEach((word) => {
      const testLine = currentLine + word + " ";
      const textLength = testLine.length; // Simple length check

      // Assuming a fixed width for each character
      const averageCharWidth = 6; // Average character width in pixels
      const lineWidth = textLength * averageCharWidth;

      if (lineWidth > maxLineWidth && currentLine) {
        lines.push(currentLine.trim());
        currentLine = word + " ";
      } else {
        currentLine = testLine;
      }
    });

    lines.push(currentLine.trim());
    return lines;
  };

  // Fixed maxLineWidth for given width
  const maxLineWidth = 80; // Adjust this value as needed
  const textLines = breakTextIntoLines(payload.value, maxLineWidth);

  return (
    <text x={x} y={y} textAnchor={textAnchor} fontSize={16} fill="#666">
      {textLines.map((line, index) => (
        <tspan x={x} dy={index === 0 ? 0 : 15} key={index}>
          {line}
        </tspan>
      ))}
    </text>
  );
};

// Custom tick component for PolarRadiusAxis
const CustomPolarRadiusAxisTick = (props) => {
  const { x, y, payload } = props;
  return (
    <text x={x} y={y} dy={16} textAnchor="middle" fill="#666" fontWeight="bold">
      {payload.value}
    </text>
  );
};

const renderLegend = (props) => {
  const { payload } = props;
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {payload.map((entry, index) => (
        <div
          key={`item-${index}`}
          style={{ display: "flex", alignItems: "center", margin: "0 10px" }}
        >
          <div
            style={{
              width: "10px",
              height: "10px",
              backgroundColor: entry.color,
              marginRight: "5px",
            }}
          />
          <span style={{ fontWeight: "bold", color: entry.color }}>
            {entry.value}
          </span>
        </div>
      ))}
    </div>
  );
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: "white",
          border: "1px solid #ccc",
          padding: "10px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Typography sx={{ fontWeight: "bold", fontSize: "16px" }}>
          {label}
        </Typography>
        {payload.map((entry, index) => (
          <Typography
            key={`item-${index}`}
            sx={{ fontWeight: 700, color: entry.color, marginTop: "7px" }}
          >
            {`${entry.name} : ${entry.value}`}
          </Typography>
        ))}
      </div>
    );
  }

  return null;
};

const ScoringRadarChart = ({ matchedData }) => {
  const [chartSize] = useState({
    width: 600, // Fixed width
    height: 500, // Fixed height
    cx: 300, // Centered horizontally
    cy: 250, // Centered vertically based on height
    radius: 200, // Adjust radius
  });

  return (
    <div
      style={{
        overflow: "hidden",
        width: "100%",
        maxWidth: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      <RadarChart
        cx={chartSize.cx}
        cy={chartSize.cy}
        outerRadius={chartSize.radius}
        width={chartSize.width}
        height={chartSize.height}
        data={matchedData?.spider_graph_results}
      >
        <PolarGrid gridType="circle" />
        <PolarAngleAxis
          dataKey="name"
          tick={CustomPolarAngleAxisTick} // Use custom tick component
        />
        <PolarRadiusAxis
          angle={30}
          domain={[0, 100]}
          tick={CustomPolarRadiusAxisTick} // Use custom radius axis tick component
        />
        <Radar
          name="Candidate Score"
          dataKey="candidate_score"
          stroke="#7F56D9"
          fill="#7F56D9"
          fillOpacity={0.1}
        />
        <Radar
          name="Highest Score"
          dataKey="highest_score"
          stroke="#17B26A"
          fill="#17B26A"
          fillOpacity={0.1}
        />
        <Radar
          name="Average Score"
          dataKey="average_score"
          stroke="#F79009"
          fill="#F79009"
          fillOpacity={0.1}
        />
        <Legend content={renderLegend} />
        <Tooltip content={<CustomTooltip />} />
      </RadarChart>
    </div>
  );
};

export default ScoringRadarChart;
