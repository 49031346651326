import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledFlexRowView = styled("div")(() => ({
  alignItems: "center",
  display: "flex",
  gap: "10px",
}));

export const StyledInteractionProfileView = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
    alignItems: "center",
  },
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
}));

export const ResponsiveTableTitle = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: "20px", // Default for mobile
  [theme.breakpoints.up("sm")]: {
    fontSize: "24px", // Tablet
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "28px", // Small Laptop
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "32px", // Large Laptop/Desktop
  },
}));

export const GreyBoldTextTypo = styled(Typography)(() => ({
  color: "#828282",
  fontSize: "20px",
  fontWeight: "bold",
}));

export const CategoryRatingContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  gap: "10px",
  marginBottom: "12px",
  alignItems: "center",
}));

export const HideIconButton = styled(IconButton)(() => ({
  backgroundColor: "transparent",
  "&:hover": {
    backgroundColor: "transparent", // Hover effect
  },
  cursor: "default",
}));

//Accordion
export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
}));

export const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
