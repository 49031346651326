import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import { List, ListItem, ListItemText } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  IconWrapper,
  StyledArrowImg,
  StyledButton,
  StyledFlexRowView,
  StyledTranslateImg,
} from "./StyledComponents";
import RemoveIcon from "@mui/icons-material/Remove";
import translate from "../../../images/logos/translate.png";
import summary_arrow from "../../../images/logos/summary_arrow.png";
import VoicemailIcon from "@mui/icons-material/Voicemail";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Notes from "./Notes";
import Delete from "@material-ui/icons/Delete";

function Row({ resumeValue }) {
  const [openRows, setOpenRows] = useState({});
  const [dialogOpen, setDialogOpen] = useState({});
  const [translateDialogOpen, setTranslateDialogOpen] = useState({});

  const handleRowClick = (index) => {
    setOpenRows((prev) => ({
      ...prev,
      [index]: !prev[index], // Toggle the specific row's open state
    }));
  };

  const handleDialogOpen = (index) => {
    setDialogOpen((prev) => ({
      ...prev,
      [index]: true, // Open the specific dialog
    }));
  };

  const handleDialogClose = (index) => {
    setDialogOpen((prev) => ({
      ...prev,
      [index]: false, // Close the specific dialog
    }));
  };

  const handleTranslateDialogOpen = (index) => {
    setTranslateDialogOpen((prev) => ({
      ...prev,
      [index]: true, // Open the specific dialog
    }));
  };

  const handleTranslateDialogClose = (index) => {
    setTranslateDialogOpen((prev) => ({
      ...prev,
      [index]: false, // Close the specific dialog
    }));
  };
  return (
    <React.Fragment>
      {resumeValue?.job_history?.map((elt, index) => (
        <React.Fragment key={index}>
          <TableRow
            sx={{
              "& > *": { borderBottom: "unset" },
              backgroundColor: elt?.job_title === "Gap" ? "#f1e6ff" : "none",
            }}
            key={index}
          >
            <TableCell>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => handleRowClick(index)}
                disabled={elt?.job_title === "Gap" ? true : false}
              >
                {openRows[index] ? <RemoveIcon /> : <AddIcon />}
              </IconButton>
            </TableCell>
            <TableCell component="th" scope="row">
              {elt.job_title}
            </TableCell>
            <TableCell align="left">
              {elt.company ? (
                <Chip label={elt.company} variant="outlined" />
              ) : (
                "-"
              )}
            </TableCell>
            <TableCell align="left">
              {elt.progression ? (
                <Chip label={elt.progression} variant="outlined" />
              ) : (
                "-"
              )}
            </TableCell>
            <TableCell align="left">{elt.start_date}</TableCell>
            <TableCell align="left">{elt.end_date}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={openRows[index]} timeout="auto" unmountOnExit>
                <Box
                  sx={{
                    margin: 1,
                  }}
                >
                  <Typography
                    gutterBottom
                    component="div"
                    sx={{ color: "#828282", fontSize: "14px" }}
                  >
                    {"Summary of accomplishments"}
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "20px",
                    }}
                  >
                    <div>
                      <ul style={{ paddingLeft: "20px", marginTop: "10px" }}>
                        {elt?.accomplishments?.map((item, index) => (
                          // {accom?.map((item, index) => (
                          <li
                            key={index}
                            style={{
                              marginBottom: "10px",
                              lineHeight: "1.5",
                              fontSize: "14px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "10px",
                                alignItems: "baseline",
                              }}
                            >
                              <FiberManualRecordIcon
                                style={{ fontSize: "8px", color: "#828282" }}
                              />
                              <div style={{ color: "#828282" }}>{item}</div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <IconWrapper>
                        <StyledArrowImg
                          src={summary_arrow}
                          onClick={() => handleDialogOpen(index)}
                        />
                      </IconWrapper>
                      <IconWrapper>
                        <StyledTranslateImg
                          src={translate}
                          onClick={() => handleTranslateDialogOpen(index)}
                          style={{ cursor: "pointer" }}
                        />
                      </IconWrapper>
                    </div>
                  </div>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
          {/* Dialog to show summary content */}
          <Dialog
            open={dialogOpen[index] || false}
            onClose={() => handleDialogClose(index)}
            aria-labelledby={`summary-dialog-title-${index}`}
            aria-describedby={`summary-dialog-description-${index}`}
          >
            <DialogTitle id={`summary-dialog-title-${index}`}>
              {"Accomplishments Summary"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id={`summary-dialog-description-${index}`}>
                {elt?.accomplishments_summary}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleDialogClose(index)}>Close</Button>
            </DialogActions>
          </Dialog>
          {/* Dialog to show transalte content */}
          <Dialog
            maxWidth="lg" // Set the max width of the dialog
            fullWidth
            open={translateDialogOpen[index] || false}
            onClose={() => handleTranslateDialogClose(index)}
            aria-labelledby={`summary-dialog-title-${index}`}
            aria-describedby={`summary-dialog-description-${index}`}
          >
            <DialogTitle id={`summary-dialog-title-${index}`}>
              {"Translation"}
            </DialogTitle>
            <DialogContent dividers>
              {/* <DialogContentText id={`summary-dialog-description-${index}`}>
                {`Here is a detailed summary of the accomplishments and job
                responsibilities. ${elt?.accomplishments_summary}`}
              </DialogContentText> */}
              <Grid container spacing={2}>
                {/* Left text */}
                <Grid item xs={5}>
                  <Typography variant="body1">
                    {[...new Array(50)]
                      .map(
                        () => `Cras mattis consectetur purus sit amet fermentum.
Cras justo odio, dapibus ac facilisis in, egestas eget quam.
Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`
                      )
                      .join("\n")}
                  </Typography>
                </Grid>

                {/* Center TextField */}
                <Grid item xs={2}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                      justifyContent: "center",
                    }}
                  >
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Language"
                      size="small"
                      // You can add other TextField props here
                    />
                    <StyledButton>{"Translate"}</StyledButton>
                  </div>
                </Grid>

                {/* Right text */}
                <Grid item xs={5}>
                  <Typography variant="body1">
                    {[...new Array(50)]
                      .map(
                        () => `Cras mattis consectetur purus sit amet fermentum.
Cras justo odio, dapibus ac facilisis in, egestas eget quam.
Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`
                      )
                      .join("\n")}
                  </Typography>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleTranslateDialogClose(index)}>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}

function JobHistory({
  resumeValue,
  handleBackUpload,
  handleAddNotesDialogClose,
  handleSaveAddNotes,
  handleAddNotes,
  addNotesDialogOpen,
  noteList,
  handleAddAnotherNote,
  handleNoteTextChange,
  handlePhoneEmailTextChange,
  handleNoteDelete,
  username,
  notesTableList,
  addNoteLoading,
}) {
  const typeList = [{ label: "Phone" }, { label: "Email" }, { label: "Text" }];

  const currentDate = new Date().toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
  });
  return (
    <>
      <Grid
        container
        spacing={3}
        sx={{
          px: {
            xs: "16px",
            sm: "20px",
            md: "50px",
          },
          py: {
            xs: "16px", // Mobile view
            sm: "24px", // Tablet view
          },
        }}
      >
        <Grid item xs={12}>
          <Typography
            sx={{
              fontSize: {
                xs: "20px", // Mobile
                sm: "24px", // Tablet
                md: "28px", // Small Laptop
                lg: "32px", // Large Laptop/Desktop
              },
            }}
            fontWeight={"bold"}
          >
            {"Job History"}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell sx={{ fontWeight: "800" }}>
                    {"Job Title"}
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: "800" }}>
                    {"Company"}
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: "800" }}>
                    {"Progression"}
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: "800" }}>
                    {"From"}
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: "800" }}>
                    {"To"}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <Row resumeValue={resumeValue} />
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12}>
          <Typography
            sx={{
              fontSize: {
                xs: "20px", // Mobile
                sm: "24px", // Tablet
                md: "28px", // Small Laptop
                lg: "32px", // Large Laptop/Desktop
              },
            }}
            fontWeight={"bold"}
          >
            {"Education, Licenses and Certificates "}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Box>
            <TableContainer
              component={Paper}
              sx={{ boxShadow: "none", overflowX: "auto" }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        position: "sticky",
                        left: 0,
                        backgroundColor: "white", // To ensure the sticky cell has a background
                        zIndex: 1, // To stay above other cells when scrolling
                        fontWeight: "800",
                      }}
                    >
                      {""}
                    </TableCell>
                    <TableCell align="left" sx={{ fontWeight: "800" }}>
                      {"School"}
                    </TableCell>
                    <TableCell align="left" sx={{ fontWeight: "800" }}>
                      {"Result"}
                    </TableCell>
                    <TableCell align="left" sx={{ fontWeight: "800" }}>
                      {"Achieved"}
                    </TableCell>
                    <TableCell align="left" sx={{ fontWeight: "800" }}>
                      {"GPA"}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {resumeValue?.elc_table?.map((row) => (
                    <TableRow key={row.gpa}>
                      <TableCell
                        align="left"
                        sx={{
                          position: "sticky",
                          left: 0,
                          backgroundColor: "white",
                        }}
                      >
                        {row.name}
                      </TableCell>
                      <TableCell align="left">
                        {row.school ? (
                          <Chip label={row.school} variant="outlined" />
                        ) : (
                          ""
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {row.result ? (
                          <Chip label={row.result} variant="outlined" />
                        ) : (
                          ""
                        )}
                      </TableCell>
                      <TableCell align="left">{row.achieved}</TableCell>
                      <TableCell align="left">{row.gpa}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>

        {resumeValue?.other_info?.length ? (
          <Grid item xs={12}>
            <Typography>{"Other info:"}</Typography>
            <ul style={{ paddingLeft: "20px", marginTop: "10px" }}>
              {resumeValue?.other_info?.map((item, index) => (
                <li
                  key={index}
                  style={{
                    marginBottom: "10px",
                    lineHeight: "1.5",
                    fontSize: "18px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      alignItems: "baseline",
                    }}
                  >
                    <FiberManualRecordIcon style={{ fontSize: "8px" }} />
                    <Typography>{item}</Typography>
                  </div>
                </li>
              ))}
            </ul>
          </Grid>
        ) : null}
        {notesTableList?.length ? (
          <Grid item xs={12}>
            <Notes notesTableList={notesTableList} />
          </Grid>
        ) : null}

        <Grid item xs={12}>
          <StyledFlexRowView
            sx={{
              flexWrap: { xs: "wrap", sm: "nowrap" },
              justifyContent: { xs: "center", sm: "flex-start" },
            }}
          >
            <StyledButton
              variant="contained"
              endIcon={<AddIcon />}
              onClick={handleAddNotes}
            >
              {"Add Notes"}
            </StyledButton>
            <StyledButton variant="contained">
              {"Conduct Pre-screen test"}
            </StyledButton>
            <StyledButton variant="contained">
              {"Conduct  AI screen"}
            </StyledButton>
          </StyledFlexRowView>
        </Grid>

        <Grid item xs={12}>
          <StyledButton variant="contained" onClick={handleBackUpload}>
            {"Back"}
          </StyledButton>
        </Grid>
      </Grid>

      {/* add notes Pop up*/}
      <Dialog
        maxWidth="sm"
        fullWidth
        open={addNotesDialogOpen}
        onClose={handleAddNotesDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiPaper-root": {
            padding: "20px",
            borderRadius: "28px",
            backgroundColor: "#f3e9ff",
          },
        }}
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  flexWrap: "wrap", // Makes the content wrap for smaller screens
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    fontSize: {
                      xs: "1rem", // Mobile
                      sm: "1.2rem", // Tablet
                      md: "1.5rem", // Web
                    },
                  }}
                >
                  {"Notes"}
                </Typography>

                <Typography
                  sx={{
                    fontSize: {
                      xs: "0.8rem", // Smaller font on mobile
                      sm: "1rem", // Tablet
                    },
                    color: "#828282",
                  }}
                >
                  {currentDate}
                </Typography>

                <Chip
                  label={`Added by: ${username}`}
                  variant="outlined"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "8px",
                    fontWeight: "bold",
                    fontSize: {
                      xs: "0.7rem", // Mobile
                      sm: "0.85rem", // Tablet
                    },
                  }}
                />

                <StyledFlexRowView>
                  <VoicemailIcon />
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      color: "#828282",
                      fontSize: {
                        xs: "0.8rem", // Mobile
                        sm: "1rem", // Tablet
                      },
                    }}
                  >
                    {"Improve"}
                  </Typography>
                </StyledFlexRowView>
              </div>
            </Grid>

            {noteList?.map((note, index) => (
              <React.Fragment key={index}>
                {noteList?.length > 1 ? (
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ fontWeight: "bold" }}>{`Note ${
                        index + 1
                      }`}</Typography>
                      <IconButton>
                        <Delete
                          onClick={() => handleNoteDelete(index)}
                          sx={{ cursor: "pointer" }}
                        />
                      </IconButton>
                    </div>
                  </Grid>
                ) : null}
                <Grid item xs={12}>
                  <TextField
                    multiline
                    rows={8}
                    placeholder="Notes...."
                    value={note.note}
                    onChange={(e) => handleNoteTextChange(index, e)}
                    fullWidth
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "8px",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "white",
                        },
                        "&:hover fieldset": {
                          borderColor: "white",
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      marginBottom: "10px",
                    }}
                  >
                    {"Interaction Type"}
                  </Typography>
                  <Autocomplete
                    disablePortal
                    options={typeList}
                    getOptionLabel={(option) => option.label}
                    value={
                      typeList.find(
                        (option) => option.label === note.interaction
                      ) || null
                    } // Set the current value
                    onChange={(event, value) => {
                      handlePhoneEmailTextChange(index, value); // Pass the entire value object
                    }} // Handle change
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        placeholder="Phone/Email/Text"
                        fullWidth
                        sx={{
                          backgroundColor: "white",
                          borderRadius: "8px",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "white",
                            },
                            "&:hover fieldset": {
                              borderColor: "white",
                            },
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
              </React.Fragment>
            ))}

            <Grid item xs={12}>
              <Button
                variant="text"
                sx={{
                  color: "#828282",
                  textTransform: "none",
                }}
                startIcon={<AddCircleOutlineIcon />}
                onClick={handleAddAnotherNote}
              >
                {"Add another"}
              </Button>
            </Grid>

            <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
              <StyledFlexRowView>
                <StyledButton
                  variant="contained"
                  onClick={handleSaveAddNotes}
                  startIcon={
                    addNoteLoading ? (
                      <CircularProgress color="inherit" size={15} />
                    ) : null
                  }
                  disabled={addNoteLoading}
                >
                  Save
                </StyledButton>
                <StyledButton
                  variant="contained"
                  onClick={handleAddNotesDialogClose}
                >
                  Cancel
                </StyledButton>
              </StyledFlexRowView>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default JobHistory;