import { Typography } from "@mui/material";
import React from "react";
import {
  ResponsiveContainer,
  Cell,
  PieChart,
  Pie,
  Tooltip,
  Label,
} from "recharts";
import { StyledButton } from "../../CandidateHub/Components/StyledComponents";

const GaugeChart = ({ formPrepopValue }) => {
  const chartValue = formPrepopValue?.score ? formPrepopValue?.score : 0; // Set this to 93
  const totalValue = 100; // The total value for the gauge

  const slices = [
    {
      value: chartValue,
      color: "url(#gradient)", // Reference to the gradient
    },
    {
      value: totalValue - chartValue,
      color: "#e0e0e0", // Color for the unfilled portion
    },
  ];

  const pieProps = {
    startAngle: 180,
    endAngle: 0,
    cx: "50%",
    cy: "50%",
    isAnimationActive: false,
  };

  const getLabelColor = (value) => {
    if (value <= 25) return "#FF0000"; // Red for Low
    if (value <= 50) return "#FFA500"; // Orange for Moderate
    if (value <= 75) return "#008000"; // Yellow for Good
    return "#008000"; // Green for Excellent
  };

  // Dynamically determine gradient stops
  const gradientStops = [
    { offset: "0%", color: "#FF0000" }, // Red at the start
    { offset: "25%", color: "#ffb833" }, // Orange up to 25%
    { offset: "50%", color: "#ffa500" }, // Yellow up to 50%
    { offset: "75%", color: "#99cc00" }, // Yellow-Green up to 75%
    { offset: "100%", color: "#008000" }, // Green at the end
  ];

  // Filter gradient stops based on chartValue
  const filteredStops = gradientStops.filter((stop) => {
    const percentageValue = parseInt(stop.offset, 10);
    return percentageValue <= (chartValue / 100) * 100;
  });

  return (
    <div style={{ width: "100%", height: "300px", maxWidth: "400px" }}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <defs>
            <linearGradient id="gradient" x1="10%" y1="20%" x2="100%" y2="10%">
              {filteredStops.map((stop, index) => (
                <stop key={index} offset={stop.offset} stopColor={stop.color} />
              ))}
              {/* Add an extra stop for the filled area */}
              <stop
                offset={`${(chartValue / 100) * 100}%`}
                stopColor={filteredStops[filteredStops.length - 1].color}
              />
              {/* Remaining part should be gray */}
              <stop offset="100%" stopColor="#e0e0e0" />
            </linearGradient>
          </defs>
          <Pie
            stroke="none"
            data={slices}
            innerRadius="50%"
            outerRadius="80%"
            {...pieProps}
          >
            {slices.map((each, i) => (
              <Cell key={`cell-${i}`} fill={slices[i].color} stroke="none" />
            ))}
          </Pie>
          <Tooltip />
          <Pie
            stroke="none"
            fill="none"
            activeIndex={1}
            data={[{ value: chartValue }, { value: totalValue - chartValue }]}
            innerRadius="50%"
            outerRadius="60%"
            {...pieProps}
          >
            <Label
              value={chartValue}
              position="centerBottom"
              offset={-20}
              className="gauge-label"
              fontSize="50px"
              fontWeight="bold"
            />
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <div style={{ textAlign: "center", marginTop: "-130px" }}>
        {formPrepopValue?.scoreLabel ? (
          <div
            style={{
              display: "inline-block",
              padding: "5px 10px",
              backgroundColor: getLabelColor(chartValue),
              color: "white",
              borderRadius: "5px",
              fontSize: "16px",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            {formPrepopValue?.scoreLabel}
          </div>
        ) : null}
        <div style={{ marginTop: "10px" }}>
          <Typography fontWeight={"bold"}>
            {formPrepopValue?.inferredTitle}
          </Typography>
        </div>
        <div style={{ marginTop: "10px" }}>
          <StyledButton variant="contained">{"Re-run TruReq"}</StyledButton>
        </div>
      </div>
    </div>
  );
};

export default GaugeChart;
