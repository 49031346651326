import { QUICK_MATCH } from "../actionTypes";

export const setQuickMatchState = (values) => ({
  type: QUICK_MATCH.setState,
  payload: values,
});

export const clearQuickMatchState = () => ({
  type: QUICK_MATCH.clearState,
});
