import { CANDIDATE_HUB } from "../actionTypes";

const initialState = {
  //   pagination: {
  //     activePage: 1,
  //     items: 10,
  //   },
  //   jobDescriptionFile: null,
  candidateHubResumeFile: null,
};

const candidateHub = (state = initialState, { type, payload }) => {
  switch (type) {
    case CANDIDATE_HUB.setState: {
      return { ...state, ...payload };
    }
    case CANDIDATE_HUB.clearState: {
      return initialState;
    }
    default:
      return state;
  }
};

export default candidateHub;
