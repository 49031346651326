import { combineReducers } from "redux";

import loginStatus from "./loginStatus";
import feedbackDashboard from "./feedbackDashboard";
import funnel from "./funnel";
import qualify from "./qualify";
import test from "./test";
import recommend from "./recommend";
import clientInterview from "./clientInterview";
import intakeForm from "./intakeForm";
import quickMatch from "./quickMatch";
import candidateHub from "./candidateHub";

export default combineReducers({
  loginStatus,
  feedbackDashboard,
  funnel,
  qualify,
  test,
  recommend,
  clientInterview,
  intakeForm,
  quickMatch,
  candidateHub,
});
