import {
  Box,
  Grid,
  Typography,
  Slider,
  Skeleton,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  RangeContainer,
  StyledSpaceBetween,
} from "../../PostJob/Components/StyledComponents";
import { styled } from "@mui/material/styles";
import { StyledButton } from "../../CandidateHub/Components/StyledComponents";
import SnackModal from "../../../commonComponents/Modal/SnackModal";
import { editWeightageList, getWeightageList } from "../../../api/api";
import axios from "axios";

const StyledSlider = styled(({ ...props }) => <Slider {...props} />)(
  ({ theme }) => ({
    color: "black",
    height: 4,
    position: "relative",
    "& .MuiSlider-thumb": {
      height: 25,
      width: 2,
      backgroundColor: "black",
      borderRadius: 0,
      "&:hover, &.Mui-focusVisible": {
        boxShadow: "0px 0px 0px 8px rgba(0, 0, 0, 0.16)",
      },
    },
    "& .MuiSlider-rail": {
      color: "black",
    },
    "& .MuiSlider-track": {
      color: "black",
    },
    "& .MuiSlider-mark": {
      backgroundColor: "transparent",
    },
    "&::before, &::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      width: "17px",
      height: "17px",
      backgroundColor: "black",
      borderRadius: "50%",
      transform: "translateY(-50%)",
    },
    "&::before": {
      left: "0",
    },
    "&::after": {
      right: "0",
    },
  })
);

function AttributeRange() {
  const [attributes, setAttributes] = useState([]);
  const [pageLoader, setPageLoader] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [snack, setSnack] = useState({
    open: false,
    color: "",
    message: "",
  });

  // Function to handle slider changes
  const handleSliderChange = (id, newValue) => {
    setAttributes((prevAttributes) =>
      prevAttributes?.map((skill) =>
        skill?.id === id ? { ...skill, weightage: newValue } : skill
      )
    );
  };

  // Calculate total criticality scores for Primary and Secondary attributes
  const totalPrimaryScore = attributes
    ?.filter((skill) => skill?.attribute === "primary")
    ?.reduce((total, skill) => total + skill?.weightage, 0);

  const totalSecondaryScore = attributes
    ?.filter((skill) => skill?.attribute === "secondary")
    ?.reduce((total, skill) => total + skill?.weightage, 0);

  const grandTotalScore = totalPrimaryScore + totalSecondaryScore;

  // Function to handle save action
  const handleSave = () => {
    if (grandTotalScore > 100) {
      setSnack({
        open: true,
        color: "error",
        message: "Total criticality score should not exceed 100.",
      });
    } else if (grandTotalScore === 100) {
      setEditLoading(true);
      axios
        .put(editWeightageList(), attributes)
        .then((response) => {
          if (response) {
            setSnack({
              open: true,
              color: "success",
              message: "Scores saved successfully!",
            });
            setEditLoading(false);
          }
          // if (response?.data?.success === true) {
          //   setSnack({
          //     open: true,
          //     color: "success",
          //     message: response?.data?.message,
          //   });
          //   // setEditLoading(false);
          // } else {
          //   setSnack({
          //     open: true,
          //     color: "error",
          //     message: response?.data?.message,
          //   });
          //   // setEditLoading(false);
          // }
        })
        .catch((err) => {
          setSnack({
            open: true,
            color: "error",
            message: err?.response?.data?.message,
          });
          setEditLoading(false);
        });
    } else {
      setSnack({
        open: true,
        color: "error",
        message: "Total criticality score should not be less than 100.",
      });
    }
  };

  const handleSnackClose = () => {
    setSnack({ open: false, color: "", message: "" });
  };

  const fetchWeightage = () => {
    setPageLoader(true);
    axios
      .get(getWeightageList())
      .then((res) => {
        setAttributes(res?.data);
        setPageLoader(false);
      })
      .catch((err) => {
        setSnack({
          open: true,
          color: "error",
          message: err?.response?.data?.message,
        });
        setPageLoader(false);
      });
  };

  useEffect(() => {
    fetchWeightage();
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        border: "1px solid black",
        padding: "16px",
        marginTop: "30px",
      }}
    >
      <Grid container columnSpacing={{ xs: 1, sm: 2, md: 4 }} pl={2}>
        {pageLoader ? (
          Array.from(new Array(12)).map((el, ind) => (
            <Grid item xs={12} sm={6} key={`key-${ind}`}>
              <Skeleton
                key={`key-${ind.toString()}`}
                width={"100%"}
                height={100}
              />
            </Grid>
          ))
        ) : (
          <>
            <Grid item xs={6}>
              <Box sx={{ width: "80%" }}>
                <Typography
                  sx={{
                    fontSizeAdjust: "16px",
                    fontWeight: "bold",
                    textAlign: "center",
                    marginBottom: "20px",
                    marginTop: "10px",
                  }}
                >
                  {"Primary Attributes"}
                </Typography>
              </Box>
              {attributes
                ?.filter((skill) => skill.attribute === "primary")
                ?.map((skill) => (
                  <RangeContainer key={skill.id} sx={{ marginBottom: "8px" }}>
                    <Box sx={{ width: "80%" }}>
                      <StyledSpaceBetween>
                        <Typography>{`${skill.name} - ${skill.category}`}</Typography>
                        <Typography>{"Critical"}</Typography>
                      </StyledSpaceBetween>
                      <StyledSlider
                        value={skill.weightage}
                        aria-label="Default"
                        valueLabelDisplay="auto"
                        min={0} // Minimum value set to 1
                        max={25} // Maximum value set to 25
                        onChange={(event, newValue) =>
                          handleSliderChange(skill.id, newValue)
                        }
                      />
                    </Box>
                  </RangeContainer>
                ))}
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ width: "80%" }}>
                <Typography
                  sx={{
                    fontSizeAdjust: "16px",
                    fontWeight: "bold",
                    textAlign: "center",
                    marginBottom: "20px",
                    marginTop: "10px",
                  }}
                >
                  {"Secondary Attributes"}
                </Typography>
              </Box>
              {attributes
                ?.filter((skill) => skill.attribute === "secondary")
                ?.map((skill) => (
                  <RangeContainer key={skill.id} sx={{ marginBottom: "8px" }}>
                    <Box sx={{ width: "80%" }}>
                      <StyledSpaceBetween>
                        <Typography>{`${skill.name} - ${skill.category}`}</Typography>
                        <Typography>{"Critical"}</Typography>
                      </StyledSpaceBetween>
                      <StyledSlider
                        value={skill.weightage}
                        aria-label="Default"
                        valueLabelDisplay="auto"
                        min={0} // Minimum value set to 1
                        max={25} // Maximum value set to 25
                        onChange={(event, newValue) =>
                          handleSliderChange(skill.id, newValue)
                        }
                      />
                    </Box>
                  </RangeContainer>
                ))}
            </Grid>

            <Grid
              container
              item
              xs={12}
              display={"flex"}
              justifyContent={"flex-end"}
            >
              <Typography
                variant="h5"
                sx={{ marginTop: "16px", fontWeight: "bold" }}
              >
                Total Criticality Score: {grandTotalScore ? grandTotalScore : 0}
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              display={"flex"}
              justifyContent={"flex-end"}
            >
              <StyledButton
                variant="contained"
                color="primary"
                onClick={handleSave}
                sx={{ marginTop: "16px" }}
                startIcon={
                  editLoading ? (
                    <CircularProgress color="inherit" size={15} />
                  ) : null
                }
                disabled={editLoading}
              >
                Save
              </StyledButton>
            </Grid>
          </>
        )}
      </Grid>
      <SnackModal
        open={snack.open}
        color={snack.color}
        message={snack.message}
        onClose={handleSnackClose}
      />
    </Box>
  );
}

export default AttributeRange;
