import { Grid, Skeleton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ActiveJobTableList from "./components/ActiveJobTableList";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { getActiveJobList } from "../../api/api";
import CustomSnack from "../../commonComponents/Snack/CustomSnack";

function ActiveJob() {
  const history = useHistory();
  const [activeJobTableList, setActiveJobTableList] = useState();
  const [pageLoader, setPageLoader] = useState(false);
  const [snack, setSnack] = useState({
    open: false,
    color: "",
    message: "",
  });

  useEffect(() => {
    setPageLoader(true);
    const fetchActiveJobList = async () => {
      try {
        const res = await axios.get(getActiveJobList());
        setActiveJobTableList(res?.data?.data);
        setPageLoader(false);
      } catch (error) {
        setSnack({
          open: true,
          color: "error",
          message: error?.res?.data?.message,
        });
        setPageLoader(false);
      }
    };
    fetchActiveJobList();
  }, []);

  const handleJobTitleClick = (row) => {
    history.push(`/home/active-jobs/${row.id}/details`);
  };

  const handleSnackClose = () => {
    setSnack({
      open: false,
      color: "",
      message: "",
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4" sx={{ fontWeight: 700 }}>
          {"Active Jobs"}
        </Typography>
      </Grid>

      {pageLoader ? (
        Array.from(new Array(12)).map((el, ind) => (
          <Grid item xs={12} sm={4} key={`key-${ind}`}>
            <Skeleton
              key={`key-${ind.toString()}`}
              width={"100%"}
              height={100}
            />
          </Grid>
        ))
      ) : (
        <Grid item xs={12}>
          <ActiveJobTableList
            activeJobTableList={activeJobTableList}
            handleJobTitleClick={handleJobTitleClick}
          />
        </Grid>
      )}

      <CustomSnack
        open={snack.open}
        color={snack.color}
        message={snack.message}
        onClose={handleSnackClose}
      />
    </Grid>
  );
}

export default ActiveJob;
