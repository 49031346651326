import { CANDIDATE_HUB } from "../actionTypes";

export const setCandidateHubState = (values) => ({
  type: CANDIDATE_HUB.setState,
  payload: values,
});

export const clearCandidateHubState = () => ({
  type: CANDIDATE_HUB.clearState,
});
