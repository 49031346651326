import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

const SkillCardItem = ({
  skills,
  title,
  description,
  showAll,
  onToggle,
  skillCategories,
  categoryKey, // New prop to receive the category key
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  // Function to handle dialog open
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  // Function to handle dialog close
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  // Determine which skills to show
  const displayedSkills = showAll ? skills : skills.slice(0, 3);

  return (
    <Grid item xs={12} md={4} mt={3}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#f3e9ff",
          borderRadius: "20px",
          padding: "20px",
          gap: "10px",
          height: "100%",
        }}
      >
        <Typography sx={{ fontSize: "25px" }}>{title}</Typography>
        <Typography sx={{ marginBottom: "6px" }}>{description}</Typography>

        {skills?.length === 0 ? (
          <Typography
            sx={{ fontSize: "18px", color: "#666", textAlign: "center" }}
          >
            No data found
          </Typography>
        ) : (
          displayedSkills?.map((skill, index) => (
            <React.Fragment key={index}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#d8baff",
                    color: "#21005D",
                    fontSize: "18px",
                    fontWeight: "bold",
                    width: "35px",
                    height: "35px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                    marginRight: "10px",
                    minWidth: "35px", // Ensures minimum size is maintained
                    minHeight: "35px", // Ensures minimum size is maintained
                  }}
                >
                  {skill.icon}
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: "1",
                    justifyContent: "space-between",
                    alignItems: "center",
                    overflow: "hidden", // Prevents overflow
                    textOverflow: "ellipsis", // Shows ellipsis for overflow text
                    whiteSpace: "nowrap", // Keeps text on one line
                  }}
                >
                  <Tooltip title={skill.name} placement="top-start">
                    <span
                      className="skill-name"
                      style={{
                        color: "#1D1B20",
                        flex: "1",
                        marginRight: "10px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                    >
                      {skill.name}
                    </span>
                  </Tooltip>
                  {categoryKey !== "soft_skills" ? (
                    <>
                      <span
                        className="skill-year"
                        style={{
                          flexShrink: 0,
                          marginRight: "10px",
                          whiteSpace: "nowrap", // Keeps years on one line
                        }}
                      >
                        {skill.years} yrs
                      </span>
                      <span
                        className="skill-year"
                        style={{ flexShrink: 0, whiteSpace: "nowrap" }}
                      >
                        {skill.year}
                      </span>
                    </>
                  ) : null}
                </div>
              </div>
              {index < displayedSkills.length - 1 && (
                <Divider sx={{ marginBottom: "3px" }} />
              )}
            </React.Fragment>
          ))
        )}

        {skills?.length === 0 ? null : (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
              gap: "30px",
            }}
          >
            <a
              href="#"
              style={{
                fontSize: "14px",
                textDecoration: "none",
                color: "#65558F",
                fontWeight: "bold",
              }}
              onClick={handleDialogOpen}
            >
              {"Explain"}
            </a>
            <a
              href="#"
              style={{
                fontSize: "14px",
                textDecoration: "none",
                color: "#65558F",
                fontWeight: "bold",
              }}
              onClick={onToggle}
            >
              {showAll ? "Show Less" : "Show More"}
            </a>
          </div>
        )}
      </div>

      {/* Dialog to show content when 'Explain' is clicked */}
      <Dialog
        maxWidth="lg" // Set the max width of the dialog
        fullWidth
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`${title} Explanation`}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            {/* Customize the explanation content here */}
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      {"Skills"}
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      {"Explanation"}
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      {"Expertise Level"}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {skillCategories
                    ?.filter((elt) => elt.key === categoryKey) // Filter based on the current category
                    .flatMap((elt) => elt.skills) // Flatten skills
                    .map((skill, index) => (
                      <TableRow key={skill.name}>
                        <TableCell align="center">{skill.name}</TableCell>
                        <TableCell align="left">{skill?.explanation}</TableCell>
                        <TableCell align="center">{skill?.label}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

function SkillCard({ resumeValue }) {
  const [expandedCard, setExpandedCard] = useState(null);

  const getSkillCategory = (category) => {
    switch (category) {
      case "hard_skills":
        return {
          title: "Hard Skills",
          description:
            "Here are the key hard skills this candidate has along with recency and years. Expert, Capable, Novice",
        };
      case "soft_skills":
        return {
          title: "Soft Skills",
          description:
            "Here are the key soft skills this candidate has along with recency and years. High, Medium, Low",
        };
      case "tools":
        return {
          title: "Tools",
          description:
            "Here are the key tools this candidate has experience in along with recency and years. Expert, Capable, Novice",
        };
      default:
        return {};
    }
  };

  // Default empty object to avoid errors
  const allSkills = resumeValue?.all_skills || {};

  const skillCategories = ["hard_skills", "soft_skills", "tools"]?.map(
    (key) => {
      const { title, description } = getSkillCategory(key);
      return {
        title,
        description,
        skills:
          allSkills[key]?.map((skill) => ({
            icon: skill?.label?.charAt(0),
            name: skill?.skill_name,
            years: skill?.years_of_exp,
            year: skill?.last_used_year,
            explanation: skill?.explanation,
            label: skill?.label,
          })) || [],
        key, // Add key to differentiate between skill types
      };
    }
  );

  return (
    <Grid
      container
      spacing={3}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        px: {
          xs: "16px",
          sm: "20px",
          md: "50px",
        },
      }}
    >
      {skillCategories.map((category, index) => (
        <SkillCardItem
          skillCategories={skillCategories}
          key={index}
          title={category.title}
          description={category.description}
          skills={category.skills}
          showAll={expandedCard === category.key}
          onToggle={() =>
            setExpandedCard(expandedCard === category.key ? null : category.key)
          }
          categoryKey={category.key} // Pass category key to SkillCardItem
        />
      ))}
    </Grid>
  );
}

export default SkillCard;
