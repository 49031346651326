import { Box, Tab, Tabs } from "@mui/material";
import React from "react";

function JobResumeTab({
  tabValue,
  handleTabChange,
  resumeTab,
  formPrepopValue,
}) {
  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: "background.paper",
        borderBottom: 1,
        borderColor: "divider",
      }}
    >
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
        allowScrollButtonsMobile
        sx={{
          "& .MuiTabs-indicator": {
            backgroundColor: "black",
          },
          // backgroundColor: "#f3e9ff",
        }}
      >
        <Tab
          label={formPrepopValue?.title}
          sx={{
            textTransform: "none",
            "&.Mui-selected": {
              color: "black",
              fontWeight: "bold",
            },
          }}
        />
        {resumeTab?.map((elt, index) => (
          <Tab
            label={`${elt?.name ? elt?.name : elt?.file_name} - ${elt?.score}`}
            key={index}
            sx={{
              textTransform: "none",
              "&.Mui-selected": {
                color: "black",
                fontWeight: "bold",
              },
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
}

export default JobResumeTab;
