import { Grid } from "@material-ui/core";
import {
  Box,
  Chip,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

function Row({ notesTableList }) {
  const [openRows, setOpenRows] = useState({});

  const handleRowClick = (index) => {
    setOpenRows((prev) => ({
      ...prev,
      [index]: !prev[index], // Toggle the specific row's open state
    }));
  };

  return (
    <React.Fragment>
      {notesTableList?.map((elt, index) => (
        <React.Fragment key={index}>
          <TableRow
            sx={{
              "& > *": { borderBottom: "unset" },
              borderBottom: "1px solid #E0E0E0",
            }}
            key={index}
          >
            <TableCell
              sx={{
                width: "10px",
              }}
            >
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => handleRowClick(index)}
              >
                {openRows[index] ? <RemoveIcon /> : <AddIcon />}
              </IconButton>
            </TableCell>
            <TableCell component="th" scope="row">
              {elt.title ? elt.title : "Note"}
            </TableCell>
            <TableCell align="left">
              {elt.interaction ? (
                <Chip label={elt.interaction} variant="outlined" />
              ) : (
                "-"
              )}
            </TableCell>
            <TableCell align="left">
              {elt.added_by ? (
                <Chip label={elt.added_by} variant="outlined" />
              ) : (
                "-"
              )}
            </TableCell>
            <TableCell align="left">{elt.created_at}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={openRows[index]} timeout="auto" unmountOnExit>
                <Box
                  sx={{
                    margin: 1,
                  }}
                >
                  <Typography
                    gutterBottom
                    component="div"
                    sx={{ color: "#828282", fontSize: "14px" }}
                  >
                    {"Notes Summary"}
                  </Typography>

                  <div>
                    <ul style={{ paddingLeft: "20px", marginTop: "10px" }}>
                      <li
                        key={index}
                        style={{
                          marginBottom: "10px",
                          lineHeight: "1.5",
                          fontSize: "14px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "10px",
                            alignItems: "baseline",
                          }}
                        >
                          <FiberManualRecordIcon
                            style={{ fontSize: "8px", color: "#828282" }}
                          />
                          <div style={{ color: "#828282" }}>{elt?.note}</div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}

function Notes({ notesTableList, isTrue }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          sx={{
            fontSize: isTrue
              ? undefined // No fontSize applied if isTrue is true
              : {
                  xs: "20px", // Mobile
                  sm: "24px", // Tablet
                  md: "28px", // Small Laptop
                  lg: "32px", // Large Laptop/Desktop
                },
          }}
          fontWeight={"bold"}
        >
          {"Notes"}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell align="left" sx={{ fontWeight: "800" }}>
                    {"Notes Title"}
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: "800" }}>
                    {"Type"}
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: "800" }}>
                    {"Entered By"}
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: "800" }}>
                    {"Date"}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <Row notesTableList={notesTableList} />
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Notes;
