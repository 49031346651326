import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { RatingFlexRowView, StyledRating } from "./StyledComponents";
import RemoveIcon from "@mui/icons-material/Remove";

export default function ResumeRating({ resumeValue }) {
  const [ratingValues, setRatingValues] = React.useState({});

  // Map the resume_score array to create a display name
  const displayNameMapping = {
    gaps: "Gap/Jumps",
    error_check: "Writing Style",
    progression: "Progression",
    // overall_score: "Resume Quality",
    professionalism: "Completeness",
    alignment_to_title: "Aligned to Title",
    continuous_learning: "Continuous Learning",
    education: "Education",
  };

  const handleRatingChange = (name, newValue) => {
    setRatingValues((prevValues) => ({
      ...prevValues,
      [name]: newValue,
    }));
  };

  const displayOrder = [
    // "overall_score",
    "professionalism",
    "error_check",
    "gaps",
    "progression",
    "alignment_to_title",
    "continuous_learning",
    "education",
  ];

  // const sortedResumeScore = [...(resumeValue?.resume_score || [])].sort(
  //   (a, b) => {
  //     return displayOrder.indexOf(a.name) - displayOrder.indexOf(b.name);
  //   }
  // );

  const sortedResumeScore = [...(resumeValue?.resume_score || [])]
    .filter((score) => score.name !== "overall_score") // Exclude overall_score
    .sort((a, b) => {
      return displayOrder.indexOf(a.name) - displayOrder.indexOf(b.name);
    });

  return (
    <Box>
      {sortedResumeScore?.map((item) => (
        <RatingFlexRowView key={item.name}>
          <Typography
            component="legend"
            sx={{
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            {displayNameMapping[item?.name] || item?.name}
          </Typography>

          <StyledRating
            name={item.name}
            value={ratingValues[item?.name] || item?.score}
            // onChange={(event, newValue) =>
            //   handleRatingChange(item?.name, newValue)
            // }
            readOnly
          />
        </RatingFlexRowView>
      ))}
    </Box>
  );
}