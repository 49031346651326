// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React from "react";
import dayjs from "dayjs";
// import { CalendarIcon } from "@mui/x-date-pickers";
import { IconButton } from "@material-ui/core";
import { TextField } from "@mui/material";

function CustomDatePickers({
  id,
  name,
  label,
  error,
  value,
  helperText,
  placeholder,
  onChange = () => {},
  format,
  minDate,
  maxDate,
  disableFuture,
  disablePast,
  variant,
  views,
  toolbarHidden = true,
  openTo,
  size = "small",
}) {
  return (
    <>
      <TextField />
      {/* <LocalizationProvider dateAdapter={AdapterDayjs}>

      <MobileDatePicker
        label={label}
        value={value ? dayjs(value) : null}
        format={format}
        minDate={minDate}
        maxDate={maxDate}
        disableFuture={disableFuture}
        disablePast={disablePast}
        views={views} // 'day' | 'month' | 'year'
        slotProps={{
          textField: {
            id: id,
            name: name,
            fullWidth: true,
            size: size,
            error: error,
            helperText: helperText,
            placeholder: placeholder,
            variant: variant,
            color: "primary",
            sx: {
              ".MuiFormLabel-root.Mui-error": {
                color: "#f44336",
              },
              ".MuiFormHelperText-root.Mui-error": {
                color: "#f44336",
              },
              ".MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#f44336",
                },
              ".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#25a9da",
                },
              ".MuiOutlinedInput-root.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#f44336",
                },
              ".MuiFormLabel-root.Mui-focused": {
                color: "#25a9da",
              },
            },
            InputProps: {
              endAdornment: (
                <IconButton style={{ padding: 0 }}>
                  <CalendarIcon />
                </IconButton>
              ),
            },
          },
          toolbar: {
            hidden: toolbarHidden,
          },
        }}
        openTo={openTo}
        onChange={onChange}
      />
    </LocalizationProvider> */}
    </>
  );
}

export default CustomDatePickers;
