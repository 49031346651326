import {
  Box,
  Button,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

export const QuickMatchContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  paddingLeft: "10px", // Mobile
  paddingRight: "10px",
  [theme.breakpoints.up("sm")]: {
    paddingLeft: "20px", // Tablet
    paddingRight: "20px",
  },
  [theme.breakpoints.up("md")]: {
    paddingLeft: "70px", // Web
    paddingRight: "70px",
  },
}));

export const MatchTestTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  [theme.breakpoints.down("sm")]: {
    fontSize: "20px", // Mobile
    marginTop: "25px",
    marginBottom: "10px",
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: "24px", // Tablet
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "28px", // Small Laptop
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "32px", // Large Laptop/Desktop
    marginTop: "30px",
    marginBottom: "30px",
  },
}));

export const StyledTextField = styled(TextField)(() => ({
  "& .MuiInputBase-root": {
    borderRadius: "8px",
  },
  ".MuiInputBase-input": {
    padding: "14px", // Add padding to the input
  },
}));

export const StyledUploadEnrichButton = styled(Button)(() => ({
  color: "white",
  backgroundColor: "Black",
  "&:hover": {
    backgroundColor: "Black",
  },
  // height: "35px",
  width: "100%",
  borderRadius: "8px",
  textTransform: "none",
}));

export const OptionInfoContainer = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  padding: "16px 0",
}));

export const QucikMatchCheckBoxContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "20px",

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: "10px",
    alignItems: "flex-start",
  },
}));

export const GenerateScoreButton = styled(Button)(() => ({
  width: "350px",
  borderRadius: "8px",
  marginTop: "10px",
  color: "white",
  backgroundColor: "Black",
  "&:hover": {
    backgroundColor: "Black",
  },
  textTransform: "none",
}));

export const BoldFormControlLabel = styled(FormControlLabel)(() => ({
  ".MuiFormControlLabel-label": {
    fontWeight: 700, // Set font weight for the label
  },
  gap: "10px",
}));
