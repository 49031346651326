import { Avatar, Chip, Grid, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React from "react";
import owner from "../../../images/logos/profile.png";
import {
  StyledButton,
  StyledFlexRowView,
} from "../../CandidateHub/Components/StyledComponents";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CategoryRating from "./CategoryRating";
import RatingChart from "./RatingChart";
import StrengthsAndWeaknesses from "./StrengthWeakeness";
import { GreyBoldTextTypo, ResponsiveTableTitle } from "./StyledComponents";
import WeightageScore from "./WeightageScore";
import SkillScoreList from "./SkillScoreList";
import { Add } from "@mui/icons-material";
import AddNoteDialog from "../../../commonComponents/AddNoteDialog/AddNoteDialog";
import Notes from "../../CandidateHub/Components/Notes";

function createData(id, type, action, status, date) {
  return { id, type, action, status, date };
}

const rows = [
  createData("FIG-123", "Resume Submitted", "View Resume", "Complete", "Dec 5"),
  createData(
    "FIG-122",
    "Chatbot interaction",
    "Review Response",
    "Complete",
    "Dec 5"
  ),
  createData(
    "FIG-121",
    "Hiring Mgr Interview",
    "Review Notes",
    "Pass",
    "Dec 7"
  ),
];

function InteractionHistory({
  matchedData,
  handleBackQuickMatch,
  isBack,
  handleCandidateAddNotes,
  handleCandidateAddNotesDialogClose,
  handleSaveCandidateAddNotes,
  candidateAddNotesDialogOpen,
  candidateNoteList,
  handleCandidateAddAnotherNote,
  handleNoteTextChange,
  handleCandidatePhoneEmailTextChange,
  handleCandidateNoteDelete,
  username,
  candidateNotesTableList,
  candidateAddNoteLoading,
  currentDate,
  typeList,
}) {
  console.log(candidateNotesTableList, "candidateNotesTableList");
  return (
    <Grid container spacing={3} px={{ md: "50px" }} py={{ xs: "10px" }}>
      {/* Title */}
      <Grid item xs={12}>
        <ResponsiveTableTitle>{"Interaction History"}</ResponsiveTableTitle>
      </Grid>

      {/* Table Section */}
      <Grid item xs={12}>
        <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{"ID"}</TableCell>
                <TableCell
                  align="left"
                  sx={{ fontWeight: "800", fontSize: "16px" }}
                >
                  {"Type"}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontWeight: "800", fontSize: "16px" }}
                >
                  {"Action"}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontWeight: "800", fontSize: "16px" }}
                >
                  {"Result"}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontWeight: "800", fontSize: "16px" }}
                >
                  {"Date"}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontWeight: "800", fontSize: "16px" }}
                >
                  {"Owner"}
                </TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    align="left"
                    sx={{ width: { xs: "30%", sm: "15%" }, fontSize: "16px" }}
                  >
                    {row.id}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ width: { xs: "50%", sm: "25%" }, fontSize: "16px" }}
                  >
                    {row.type}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: "16px" }}>
                    <Chip label={row.action} variant="outlined" />
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: "16px" }}>
                    {row.status}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: "16px" }}>
                    {row.date}
                  </TableCell>
                  <TableCell align="left">
                    <Avatar alt="Owner" sx={{ bgcolor: "red" }} src={owner} />
                  </TableCell>
                  <TableCell align="center">
                    <MoreHorizIcon />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      {/* CategoryRating and RatingChart */}
      <Grid item xs={12}>
        <Grid
          container
          spacing={3}
          sx={{ display: "flex", alignItems: "flex-start" }}
        >
          <Grid item xs={12} md={4}>
            <CategoryRating />
          </Grid>
          <Grid item xs={12} md={8} display={"flex"} justifyContent={"center"}>
            <RatingChart matchedData={matchedData} />
          </Grid>
        </Grid>
      </Grid>

      {/* Strengths and Weaknesses */}
      <Grid item xs={12}>
        <StrengthsAndWeaknesses matchedData={matchedData} />
      </Grid>

      {/* Recommendations */}
      {matchedData?.insights?.feedback?.analysis ? (
        <Grid item xs={12} mt={2}>
          <GreyBoldTextTypo>Analysis:</GreyBoldTextTypo>
          <Typography sx={{ color: "#828282", fontSize: "20px" }}>
            {matchedData?.insights?.feedback?.analysis}
          </Typography>
        </Grid>
      ) : null}

      {matchedData?.insights?.feedback?.red_flags?.length ? (
        <Grid item xs={12} mt={2}>
          <GreyBoldTextTypo>Red Flags:</GreyBoldTextTypo>
          {matchedData?.insights?.feedback?.red_flags?.map((item, index) => (
            <Typography sx={{ color: "#828282", fontSize: "20px" }}>
              {`${index + 1}. ${item}`}
            </Typography>
          ))}
        </Grid>
      ) : null}

      {matchedData?.insights?.feedback?.recommendations?.length ? (
        <Grid item xs={12} mt={2}>
          <GreyBoldTextTypo>Recommendation:</GreyBoldTextTypo>
          {matchedData?.insights?.feedback?.recommendations?.map(
            (item, index) => (
              <Typography sx={{ color: "#828282", fontSize: "20px" }}>
                {`${index + 1}. ${item}`}
              </Typography>
            )
          )}
        </Grid>
      ) : null}
      {matchedData?.results?.length ? (
        <Grid item xs={12}>
          <WeightageScore matchedData={matchedData} />
        </Grid>
      ) : null}

      <Grid item xs={12}>
        <SkillScoreList matchedData={matchedData} />
      </Grid>

      {candidateNotesTableList?.length ? (
        <Grid item xs={12}>
          <Notes notesTableList={candidateNotesTableList} />
        </Grid>
      ) : null}

      {/* Action Buttons */}
      <Grid item xs={12} mt={3}>
        <StyledFlexRowView
          style={{
            gap: "15px",
            flexWrap: "wrap",
            justifyContent: { xs: "center", sm: "flex-start" },
          }}
        >
          <StyledButton
            size="large"
            variant="contained"
            sx={{
              width: {
                xs: "100%", // mobile
                sm: "250px",
                md: "306px", // web
              },
            }}
          >
            {"Move to Offer"}
          </StyledButton>
          <StyledButton size="large" variant="contained">
            {"Cancel"}
          </StyledButton>
          <StyledButton size="large" variant="contained">
            {"Reject/Hold"}
          </StyledButton>
          <StyledButton
            size="large"
            variant="contained"
            endIcon={<Add />}
            onClick={handleCandidateAddNotes}
          >
            {"Add Notes"}
          </StyledButton>
        </StyledFlexRowView>
      </Grid>

      {isBack ? (
        <Grid item xs={12}>
          <StyledButton
            size="large"
            variant="contained"
            onClick={handleBackQuickMatch}
          >
            {"Back"}
          </StyledButton>
        </Grid>
      ) : null}

      {/* Notes Dialog */}
      {/* Notes Dialog */}
      <AddNoteDialog
        handleAddNotesDialogClose={handleCandidateAddNotesDialogClose}
        handleSaveAddNotes={handleSaveCandidateAddNotes}
        addNotesDialogOpen={candidateAddNotesDialogOpen}
        noteList={candidateNoteList}
        handleAddAnotherNote={handleCandidateAddAnotherNote}
        handleNoteTextChange={handleNoteTextChange}
        handlePhoneEmailTextChange={handleCandidatePhoneEmailTextChange}
        handleNoteDelete={handleCandidateNoteDelete}
        username={username}
        notesTableList={candidateNotesTableList}
        addNoteLoading={candidateAddNoteLoading}
        currentDate={currentDate}
        typeList={typeList}
      />
    </Grid>
  );
}

export default InteractionHistory;
