import { Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import StepOneMatchTest from "./Components/StepOneMatchTest";
import axios from "axios";
import { uploadJdResume } from "../../api/api";
import CustomSnack from "../../commonComponents/Snack/CustomSnack";
import { useDispatch, useSelector } from "react-redux";
import {
  clearQuickMatchState,
  setQuickMatchState,
} from "../../redux/actions/quickMatch";
import SnackModal from "../../commonComponents/Modal/SnackModal";

function QuickMatch() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const isNewQucikMatch = location?.state?.isNewQucikMatch;
  const [jobDescriptionFileName, setJobDescriptionFileName] = useState("");
  const [resumeFileName, setResumeFileName] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([
    { fileName: "", file: null },
  ]);
  const [loading, setLoading] = useState(false);
  const [snack, setSnack] = useState({
    open: false,
    color: "",
    message: "",
  });
  const [progressPercent, setProgressPercent] = useState([]);
  const [successItem, setSuccessItem] = useState(null);
  const [shouldNavigate, setShouldNavigate] = useState(false);

  const jobDescriptionInputRef = useRef(null);
  const resumeInputRef = useRef(null);
  const fileInputRefs = useRef([]);

  const { jobDescriptionFile, resumeFile } = useSelector(
    (state) => state.quickMatch
  );

  useEffect(() => {
    if (isNewQucikMatch) {
      setResumeFileName("");
      setJobDescriptionFileName("");
      if (jobDescriptionInputRef.current) {
        jobDescriptionInputRef.current.value = ""; // Clear the file input
      }
      if (resumeInputRef.current) {
        resumeInputRef.current.value = ""; // Clear the file input
      }
      dispatch(clearQuickMatchState());
    } else {
      setResumeFileName(resumeFile?.name);
      setJobDescriptionFileName(jobDescriptionFile?.name);
    }
  }, [isNewQucikMatch]);
  console.log(isNewQucikMatch, "isNewQucikMatch");

  const handleUploadJobDescription = () => {
    if (jobDescriptionInputRef.current) {
      jobDescriptionInputRef.current.click();
    }
  };

  const handleJobDescriptionFileChange = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      setJobDescriptionFileName(file.name); // Update file name in text field
      dispatch(
        setQuickMatchState({
          jobDescriptionFile: file,
        })
      );
    }
  };

  // const handleGenerateScore = () => {
  //   setLoading(true);

  //   const formData = new FormData();
  //   if (jobDescriptionFile) {
  //     formData.append("jd_file", jobDescriptionFile);
  //   }

  //   uploadedFiles.forEach((resume, index) => {
  //     if (resume.file) {
  //       formData.append(`resume_file`, resume.file);
  //     }
  //   });
  //   formData.append("domain", "");
  //   formData.append("country", "");
  //   formData.append("id", "");

  //   axios
  //     .post(uploadJdResume(), formData, {
  //       headers: { "Content-Type": "multipart/form-data" },
  //     })

  //     .then((response) => {
  //       if (response?.data?.success === true) {
  //         const allSuccess = response?.data?.data?.every(
  //           (item) => item?.success === true
  //         );
  //         const successfulItem = response?.data?.data?.find(
  //           (item) => item?.success === true
  //         );
  //         const failedItems = response?.data?.data?.filter(
  //           (item) => item?.success === false
  //         );

  //         if (failedItems.length > 0) {
  //           setSuccessItem(successfulItem);
  //           if (successfulItem) {
  //             setShouldNavigate(true);
  //           }
  //           setLoading(false);
  //           const errorMessages = failedItems.map((item, index) => (
  //             <div key={index}>
  //               {index + 1}. {item.message}
  //             </div>
  //           ));
  //           setSnack({
  //             open: true,
  //             color: "error",
  //             message: (
  //               <div>
  //                 {errorMessages.length > 0
  //                   ? errorMessages
  //                   : "There were errors processing some files."}
  //               </div>
  //             ),
  //           });
  //           return;
  //         }

  //         if (allSuccess) {
  //           history.push(
  //             `/home/active-jobs/${successfulItem?.data?.jd_info}/details`
  //           );
  //         }
  //       }
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       console.log(err, "err");
  //       setSnack({
  //         open: true,
  //         color: "error",
  //         message: err?.response?.data?.message,
  //       });
  //     });
  // };

  // useEffect(() => {
  //   if (!snack.open && shouldNavigate && successItem) {
  //     history.push(`/home/active-jobs/${successItem?.data?.jd_info}/details`);
  //     setShouldNavigate(false); // Reset after navigation
  //   }
  // }, [snack.open, shouldNavigate, successItem]);

  const handleGenerateScore = async () => {
    setLoading(true);
    // setProgressPercent(uploadedFiles.map(() => 0));

    const formData = new FormData();
    if (jobDescriptionFile) {
      formData.append("jd_file", jobDescriptionFile);
    }

    uploadedFiles.forEach((resume) => {
      if (resume.file) {
        formData.append("resume_file", resume.file);
      }
    });

    const url = uploadJdResume(); // Prepare the URL for the request

    try {
      console.log("Sending request...");

      // Use fetch to send the request
      const response = await fetch(url, {
        method: "POST", // Specify the POST method
        body: formData, // Use FormData as the body
      });

      // Check if the response is okay
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body.getReader(); // Get a reader from the response stream
      const decoder = new TextDecoder("utf-8"); // To decode the stream

      const processStream = async () => {
        let done = false;
        let buffer = ""; // Buffer to accumulate the chunks

        while (!done) {
          const { value, done: isDone } = await reader.read();
          done = isDone;

          if (value) {
            const chunk = decoder.decode(value, { stream: true });
            buffer += chunk; // Append the chunk to the buffer

            // Attempt to process the accumulated buffer
            let boundaryIndex;
            try {
              // Split by newlines or other delimiters (if any), adjust if needed
              while ((boundaryIndex = buffer.indexOf("}\n{")) !== -1) {
                const jsonString = buffer.slice(0, boundaryIndex + 1); // Extract the first JSON object
                buffer = buffer.slice(boundaryIndex + 1); // Remove the processed part from the buffer
                const jsonResponse = JSON.parse(jsonString); // Parse the JSON

                // Process the parsed JSON
                processJsonResponse(jsonResponse);
              }

              // Try to parse the last remaining part if it's a valid JSON
              const jsonResponse = JSON.parse(buffer);
              processJsonResponse(jsonResponse);
              buffer = ""; // Clear the buffer after successful parsing
            } catch (e) {
              console.error("Error parsing JSON:", e, "Received chunk:", chunk);
              // Continue accumulating if the buffer is incomplete
            }
          }
        }
        console.log("Stream completed.");
      };

      // Separate function to process the parsed JSON response
      // const processJsonResponse = (jsonResponse) => {
      //   if (jsonResponse.success) {
      //     if (
      //       jsonResponse?.data?.percent !== undefined &&
      //       jsonResponse?.data?.current_resume &&
      //       !jsonResponse?.data?.response
      //     ) {
      //       console.log("Updating progress to:", jsonResponse.data.percent);
      //       setProgressPercent((prev) => {
      //         let arr = [...prev];
      //         let index = arr.findIndex(
      //           (elt) => elt.fileName === jsonResponse?.data?.current_resume
      //         );
      //         if (index >= 0) {
      //           arr[index] = {
      //             ...arr[index],
      //             percent: jsonResponse?.data?.percent,
      //           };
      //         }
      //         return arr;
      //       });
      //     }

      //     if (
      //       jsonResponse?.data?.length > 0 &&
      //       jsonResponse?.data[0]?.jd_info
      //     ) {
      //       const jdInfo = jsonResponse.data[0].jd_info;
      //       console.log(jdInfo);
      //       history.push(`/home/active-jobs/${jdInfo}/details`);
      //       setLoading(false);
      //     }
      //   } else {
      //     setSnack({
      //       open: true,
      //       color: "error",
      //       message: jsonResponse?.message,
      //     });
      //     setLoading(false);
      //   }
      // };
      const processJsonResponse = (jsonResponse) => {
        const { success, data, message } = jsonResponse;

        // Handle progress updates
        if (success && data?.percent !== undefined && data?.current_resume) {
          console.log("Updating progress to:", data.percent);

          setProgressPercent((prev) => {
            let arr = [...prev];
            let index = arr.findIndex(
              (elt) => elt.fileName === data.current_resume
            );
            if (index >= 0) {
              arr[index] = {
                ...arr[index],
                percent: data.percent,
              };
            }
            return arr;
          });
        }

        // Handle errors
        if (!success && data.current_resume) {
          console.log(
            "Error for resume:",
            data.current_resume,
            "Message:",
            message
          );

          setProgressPercent((prev) => {
            let arr = [...prev];
            let index = arr.findIndex(
              (elt) => elt.fileName === data.current_resume
            );
            if (index >= 0) {
              arr[index] = {
                ...arr[index],
                error: message,
              };
            }
            return arr;
          });
        } else if (!success && !data.current_resume) {
          setSnack({
            open: true,
            color: "error",
            message: message,
          });
          setLoading(false);
        }

        // Handle completion and redirect
        if (Array.isArray(data) && data.length > 0 && data[0]?.jd_info) {
          const jdInfo = data[0].jd_info;
          const isParsed = data.some((item) => item.is_parsed === false);
          if (isParsed) {
            // Extract error messages from the data
            const errorMessages = data
              .filter((item) => item.is_parsed === false)
              .map((item) => item.error_message)
              .join("\n");

            console.error("Errors in parsing resumes:", errorMessages);
            setSuccessItem(jdInfo);
            if (jdInfo) {
              setShouldNavigate(true);
            }
            // Show error messages in snackbar
            setSnack({
              open: true,
              color: "error",
              message: errorMessages,
            });
          } else {
            console.log("Redirecting to job details for JD:", jdInfo);
            history.push(`/home/active-jobs/${jdInfo}/details`);
            setLoading(false);
          }
        }
      };

      processStream(); // Start processing the stream
    } catch (error) {
      setLoading(false);
      console.error("Error during the request:", error);
    }
    // finally {
    //   setLoading(false);
    // }
  };

  const [fileProgress, setFileProgress] = useState(uploadedFiles.map(() => 0));
  console.log(progressPercent, "percent");

  const handleSnackClose = () => {
    if (shouldNavigate && successItem) {
      history.push(`/home/active-jobs/${successItem}/details`);
      // setShouldNavigate(false); // Reset after navigation
    } else {
      setSnack({ open: false, color: "", message: "" });
    }
  };

  const handleClearJobDescriptionFileName = () => {
    setJobDescriptionFileName(""); // Clear the file name
    if (jobDescriptionInputRef.current) {
      jobDescriptionInputRef.current.value = ""; // Clear the file input
    }
    dispatch(
      setQuickMatchState({
        jobDescriptionFile: null,
      })
    );
  };

  const handleClearResumeFileName = (index) => {
    let arr = [...progressPercent];
    arr.splice(index, 1);
    setProgressPercent(arr);
    setUploadedFiles((prev) => {
      const updatedFiles = prev.filter((_, idx) => idx !== index);
      if (fileInputRefs.current) {
        fileInputRefs.current.value = "";
      }
      dispatch(
        setQuickMatchState({
          resumeFile: updatedFiles.map((resume) => resume.file),
        })
      );

      return updatedFiles;
    });
  };

  const handleUploadFiles = (event) => {
    const files = Array.from(event.target.files);

    setUploadedFiles((prev) => {
      const updatedFiles = [...prev];
      const arr = [...progressPercent];
      files.forEach((file) => {
        if (updatedFiles.filter((uploaded) => uploaded.file).length < 10) {
          const emptyIndex = updatedFiles.findIndex(
            (uploaded) => uploaded.fileName === ""
          );
          if (emptyIndex !== -1) {
            updatedFiles[emptyIndex] = { fileName: file.name, file }; // Update with new file
            arr[emptyIndex] = { fileName: file.name };
          } else if (updatedFiles.length < 10) {
            updatedFiles.push({ fileName: file.name, file }); // Add to the end if under the limit
            arr.push({ fileName: file.name });
          }
        }
      });
      setProgressPercent(arr);
      dispatch(
        setQuickMatchState({
          resumeFile: updatedFiles.map((resume) => resume.file),
        })
      );

      return updatedFiles;
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <StepOneMatchTest
          jobDescriptionFileName={jobDescriptionFileName}
          resumeFileName={resumeFileName}
          handleUploadJobDescription={handleUploadJobDescription}
          handleJobDescriptionFileChange={handleJobDescriptionFileChange}
          jobDescriptionInputRef={jobDescriptionInputRef}
          resumeInputRef={resumeInputRef}
          handleGenerateScore={handleGenerateScore}
          loading={loading}
          handleClearJobDescriptionFileName={handleClearJobDescriptionFileName}
          handleClearResumeFileName={handleClearResumeFileName}
          fileInputRefs={fileInputRefs}
          handleUploadFiles={handleUploadFiles}
          uploadedFiles={uploadedFiles}
          progressPercent={progressPercent}
          fileProgress={fileProgress}
        />
      </Grid>
      <SnackModal
        open={snack.open}
        color={snack.color}
        message={snack.message}
        onClose={handleSnackClose}
      />
    </Grid>
  );
}

export default QuickMatch;
