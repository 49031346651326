import { Grid, TextField } from "@mui/material";
import React from "react";
import { StyledBoldTypo } from "./StyledComponents";

function FormPrePop({ formPrepopValue }) {
  return (
    <>
      <Grid container p={3} spacing={2} sx={{ border: "1px solid black" }}>
        <Grid item xs={12} sm={6} md={4}>
          <div>
            <StyledBoldTypo>{"Title"}</StyledBoldTypo>
            <TextField
              id="title"
              label=""
              placeholder="Security Compliance Analyst - 30725"
              size="small"
              fullWidth
              name="title"
              value={formPrepopValue?.title || ""}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <div>
            <StyledBoldTypo>{"Company"}</StyledBoldTypo>
            <TextField
              id="title"
              label=""
              placeholder="Company Name"
              size="small"
              fullWidth
              name="title"
              value={formPrepopValue?.company_name || ""}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <div>
            <StyledBoldTypo>{"Industry"}</StyledBoldTypo>
            <TextField
              id="outlined-controlled"
              label=""
              placeholder="Industry"
              size="small"
              fullWidth
              value={formPrepopValue?.industry || ""}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <div>
            <StyledBoldTypo>{"Hourly Target Rate"}</StyledBoldTypo>
            <TextField
              id="outlined-controlled"
              label=""
              placeholder="Pay Rate"
              size="small"
              fullWidth
              value={formPrepopValue?.payRate || ""}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <div>
            <StyledBoldTypo>{"Seniority Level"}</StyledBoldTypo>
            <TextField
              id="outlined-controlled"
              label=""
              placeholder="Management Level"
              size="small"
              fullWidth
              value={formPrepopValue?.managementLevel || ""}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <div>
            <StyledBoldTypo>{"Employment Type"}</StyledBoldTypo>
            <TextField
              id="outlined-controlled"
              label=""
              placeholder="Employment Type"
              size="small"
              fullWidth
              value={formPrepopValue?.employmentType || ""}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <div>
            <StyledBoldTypo>{"Location"}</StyledBoldTypo>
            <TextField
              id="outlined-controlled"
              label=""
              placeholder="Location"
              size="small"
              fullWidth
              value={formPrepopValue?.location || ""}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <div>
            <StyledBoldTypo>{"Start Date"}</StyledBoldTypo>
            <TextField
              id="outlined-controlled"
              label=""
              placeholder="Start Date"
              size="small"
              fullWidth
              value={formPrepopValue?.startDate || ""}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <div>
            <StyledBoldTypo>{"End Date"}</StyledBoldTypo>
            <TextField
              id="outlined-controlled"
              label=""
              placeholder="End Date"
              size="small"
              fullWidth
              value={formPrepopValue?.endDate || ""}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <div>
            <StyledBoldTypo>{"Occupational Classification"}</StyledBoldTypo>
            <TextField
              id="outlined-controlled"
              label=""
              placeholder="Occupational Classification"
              size="small"
              fullWidth
              value={formPrepopValue?.occupationalClassification || ""}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <div>
            <StyledBoldTypo>{"Req Id (If applicable)"}</StyledBoldTypo>
            <TextField
              id="outlined-controlled"
              label=""
              placeholder="Req Id (If applicable)"
              size="small"
              fullWidth
              value={formPrepopValue?.requestId || ""}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <div>
            <StyledBoldTypo>{"Shift"}</StyledBoldTypo>
            <TextField
              id="outlined-controlled"
              label=""
              placeholder="Shift"
              size="small"
              fullWidth
              value={formPrepopValue?.shift || ""}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <div>
            <StyledBoldTypo>{"Reports to"}</StyledBoldTypo>
            <TextField
              id="outlined-controlled"
              label=""
              placeholder="Reports to"
              size="small"
              fullWidth
              value={formPrepopValue?.reportsTo || ""}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <div>
            <StyledBoldTypo>{"BU/Dept/Team"}</StyledBoldTypo>
            <TextField
              id="outlined-controlled"
              label=""
              placeholder="BU/Dept/Team"
              size="small"
              fullWidth
              value={formPrepopValue?.buDepartmentTeam || ""}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div>
            <StyledBoldTypo>{"Company Profile"}</StyledBoldTypo>
            <TextField
              id="title"
              label=""
              placeholder="Security Compliance Analyst - 30725"
              size="small"
              fullWidth
              name="title"
              value={formPrepopValue?.companyProfile || ""}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default FormPrePop;
