import { MoreHoriz } from "@mui/icons-material";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

function ActiveJobTableList({ activeJobTableList, handleJobTitleClick }) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{"ID"}</TableCell>
                <TableCell>{"Req ID"}</TableCell>
                <TableCell
                  align="left"
                  sx={{ fontWeight: "800", fontSize: "16px" }}
                >
                  {"Job Title"}
                </TableCell>
                {/* <TableCell
                  align="left"
                  sx={{ fontWeight: "800", fontSize: "16px" }}
                >
                  {"Action"}
                </TableCell> */}
                {/* <TableCell
                  align="left"
                  sx={{ fontWeight: "800", fontSize: "16px" }}
                >
                  {"Result"}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontWeight: "800", fontSize: "16px" }}
                >
                  {"Date"}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontWeight: "800", fontSize: "16px" }}
                >
                  {"Owner"}
                </TableCell>*/}
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activeJobTableList?.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    align="left"
                    sx={{ width: { xs: "30%", sm: "15%" }, fontSize: "16px" }}
                  >
                    {row.unique_id}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ width: { xs: "30%", sm: "15%" }, fontSize: "16px" }}
                  >
                    {row.req_id}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      width: { xs: "50%", sm: "25%" },
                      fontSize: "16px",
                      cursor: "pointer",
                      color: "#292985",
                      textDecoration: "underline",
                    }}
                    onClick={() => handleJobTitleClick(row)}
                  >
                    {row.job_title}
                  </TableCell>

                  <TableCell align="center">
                    <MoreHoriz />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default ActiveJobTableList;
