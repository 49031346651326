import { Box, Popper, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { IconButton } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

// Popper used for avoid scrolling container
const CustomSnack = ({ open, color, message, onClose }) => {
  return (
    <Popper
      open={open}
      anchorEl={document.getElementById("root")}
      className={"snackbar_wrapper"}
    >
      <Snackbar
        open={open}
        // autoHideDuration={40000}
        onClose={onClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        {open ? (
          <Alert
            severity={color || "success"}
            variant={"filled"}
            style={{ color: "white" }}
            action={
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={onClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          >
            {message}
          </Alert>
        ) : null}
      </Snackbar>
    </Popper>
  );
};

export default CustomSnack;
