import { Grid, Skeleton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CandidateSearch from "../CandidateHub/Components/CandidateSearch";
import Profile from "./Components/Profile";
import InteractionHistory from "./Components/InteractionHistory";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { getMatchedJdResumeData } from "../../api/api";
import CustomSnack from "../../commonComponents/Snack/CustomSnack";
import { useHistory } from "react-router-dom";

function CandidateScoringSummary() {
  const location = useLocation();
  const history = useHistory();

  const [matchedData, setMatchedData] = useState("");
  const [snack, setSnack] = useState({
    open: false,
    color: "",
    message: "",
  });
  const [pageLoader, setPageLoader] = useState(false);

  // Parse the query string
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id"); // Retrieves the value of 'id'
  console.log(id, "ids");

  useEffect(() => {
    const fetchData = async () => {
      setPageLoader(true);
      try {
        const {
          data: { data },
        } = await axios.get(getMatchedJdResumeData(id));
        console.log(data, "matchdata");
        setMatchedData({
          candidate_name: data?.resume_info?.candidate_name,
          pay_rate: data?.resume_info?.pay_rate,
          location: data?.resume_info?.location,
          inferred_title: data?.resume_info?.inferred_title,
          visa_info: data?.resume_info?.visa_info,
          overall_score: data?.quick_match_score?.overall_score,
          results: data?.results || [],
          skill_calculation: data?.skill_calculation,
        });
        setPageLoader(false);
      } catch (error) {
        setSnack({
          open: true,
          color: "error",
          message: error?.response?.data?.message,
        });
        setPageLoader(false);
        console.log(error, "err");
      }
    };

    fetchData();
  }, [id]);

  const handleSnackClose = () => {
    setSnack({
      open: false,
      color: "",
      message: "",
    });
  };

  const handleBackQuickMatch = () => {
    history.push(`/home/quick-match`);
  };

  const handleViewResume = () => {};
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant={"h5"} fontWeight={"bold"}>
          {"Candidate Search"}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <CandidateSearch />
      </Grid>
      {pageLoader ? (
        Array.from(new Array(12)).map((el, ind) => (
          <Grid item xs={12} sm={4} key={`key-${ind}`}>
            <Skeleton
              key={`key-${ind.toString()}`}
              width={"100%"}
              height={100}
            />
          </Grid>
        ))
      ) : (
        <>
          <Grid item xs={12}>
            <Profile
              matchedData={matchedData}
              handleViewResume={handleViewResume}
            />
          </Grid>
          <Grid item xs={12}>
            <InteractionHistory
              matchedData={matchedData}
              handleBackQuickMatch={handleBackQuickMatch}
              isBack={true}
            />
          </Grid>
        </>
      )}
      <CustomSnack
        open={snack.open}
        color={snack.color}
        message={snack.message}
        onClose={handleSnackClose}
      />
    </Grid>
  );
}

export default CandidateScoringSummary;
