import React, { useState } from "react";
import {
  Badge,
  Box,
  Collapse,
  Divider,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import deleteIco from "../../../images/svgs/delete.svg";
import "../Style.css";
import AddIcon from "@mui/icons-material/Add";
import CustomSwitch from "../../../commonComponents/Switch/CustomSwitch";
import {
  StyledCheckBox,
  StyledSlider,
} from "../../../commonComponents/StyledComponents";
import {
  RangeContainer,
  RecommendContainer,
  SkillTitle,
  StyledBoldTypo,
  StyledSpaceBetween,
} from "./StyledComponents";
import {
  StyledButton,
  StyledFlexRowView,
} from "../../CandidateHub/Components/StyledComponents";
import { Add, Remove } from "@mui/icons-material";
import { CategoryRatingContainer } from "../../InteractionHub/Components/StyledComponents";

function HardSoftSkill({
  formPrepopValue,
  handleAddHardSkill,
  handleAddSoftSkill,
  handleRemoveSkill,
  handleSliderChange,
  isDelete,
}) {
  const [openItems, setOpenItems] = useState({});

  const handleToggle = (key) => {
    setOpenItems((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const groupedSkills =
    formPrepopValue?.hardSkills?.reduce((acc, skill) => {
      const { classification } = skill;
      if (!acc[classification]) {
        acc[classification] = [];
      }
      acc[classification].push(skill);
      return acc;
    }, {}) || {};
  return (
    <Grid container spacing={3}>
      {/* Hard Skill Section */}
      <Grid item xs={12} md={6}>
        <SkillTitle>
          <StyledBoldTypo isCenter>{"Hard Skill"}</StyledBoldTypo>
          <CustomSwitch sx={{ m: 1 }} checked={true} />
        </SkillTitle>

        {Object.entries(groupedSkills).length > 0 &&
          Object.entries(groupedSkills).map(([classification, skills]) => (
            <>
              <CategoryRatingContainer key={classification}>
                <StyledFlexRowView>
                  <IconButton onClick={() => handleToggle(classification)}>
                    {openItems[classification] ? <Remove /> : <Add />}
                  </IconButton>
                  <Tooltip title={classification} placement="top-start">
                    <Badge badgeContent={skills.length} color="success">
                      <StyledBoldTypo
                        sx={{
                          cursor: "pointer",
                          marginRight: "16px !important",
                        }}
                      >
                        {classification}
                      </StyledBoldTypo>
                    </Badge>
                  </Tooltip>
                </StyledFlexRowView>
              </CategoryRatingContainer>

              <Collapse
                in={openItems[classification]}
                timeout="auto"
                unmountOnExit
              >
                {skills?.map((skill, subIndex) => (
                  <RangeContainer key={subIndex} style={{ marginLeft: "40px" }}>
                    <Box sx={{ width: "100%" }}>
                      <StyledSpaceBetween>
                        <Typography>{skill.skill}</Typography>
                        <Typography>{"Critical"}</Typography>
                      </StyledSpaceBetween>
                      <StyledSlider
                        defaultValue={skill.criticality_score}
                        value={skill.criticality_score}
                        aria-label="Default"
                        valueLabelDisplay="auto"
                        min={0}
                        max={10}
                      />
                    </Box>
                    <div>
                      {subIndex === 0 && <Typography>{"Years"}</Typography>}
                      <TextField
                        id={`outlined-controlled-hard-${skill.skill}`}
                        label=""
                        size="small"
                        sx={{ width: "50px" }}
                        value={skill.experience_in_yrs}
                        InputProps={{ readOnly: true }}
                      />
                    </div>
                  </RangeContainer>
                ))}
              </Collapse>
            </>
          ))}

        {/* {formPrepopValue?.hardSkills?.map((skill, index) => (
          <RangeContainer key={index}>
            <Box sx={{ width: "100%" }}>
              <StyledSpaceBetween>
                <Typography>{skill?.skill}</Typography>
                <Typography>{"Critical"}</Typography>
              </StyledSpaceBetween>
              <StyledSlider
                defaultValue={skill?.criticality_score}
                aria-label="Default"
                valueLabelDisplay="auto"
                onChange={(event, newValue) =>
                  handleSliderChange(index, newValue, "hard")
                }
                min={0}
                max={10}
                disabled={true}
              />
            </Box>
            <div>
              {index === 0 ? <Typography>{"Years"}</Typography> : null}
              <TextField
                id={`outlined-controlled-hard-${skill.id}`}
                label=""
                placeholder=""
                size="small"
                sx={{ width: "50px" }}
                value={skill.experience_in_yrs}
              />
            </div>
            {isDelete ? (
              <img
                className="deleteImg"
                src={deleteIco}
                alt="delete"
                onClick={() => handleRemoveSkill(index)}
                style={{ cursor: "pointer" }}
              />
            ) : null}
          </RangeContainer>
        ))} */}

        {formPrepopValue?.recommendHardSkills?.length ? (
          <RecommendContainer>
            <Divider style={{ display: "flex", flexGrow: 1 }}>
              {"Recommended Skills"}
            </Divider>
            <StyledCheckBox checked={true} />
          </RecommendContainer>
        ) : null}

        {formPrepopValue?.recommendHardSkills?.map((skill, index) => (
          <RangeContainer key={skill.id}>
            <Box sx={{ width: "100%" }}>
              <StyledSpaceBetween>
                <Typography>{skill.skill}</Typography>
                <Typography>{"Critical"}</Typography>
              </StyledSpaceBetween>
              <StyledSlider
                defaultValue={skill.criticality_score}
                value={skill.criticality_score}
                aria-label="Default"
                valueLabelDisplay="auto"
                // onChange={(event, newValue) =>
                //   handleSliderChange(index, newValue, "recommendHard")
                // }
                min={0}
                max={10}
                // disabled={true}
              />
            </Box>
            <div>
              {index === 0 ? <Typography>{"Years"}</Typography> : null}
              <TextField
                id={`outlined-controlled-hard-${skill.id}`}
                label=""
                placeholder=""
                size="small"
                sx={{ width: "50px" }}
                value={skill.experience_in_yrs}
              />
            </div>
            {isDelete ? (
              <img
                className="deleteImg"
                src={deleteIco}
                alt="delete"
                // onClick={() => handleRemoveSkill(skill.id, "hard")}
                style={{ cursor: "pointer" }}
              />
            ) : null}
          </RangeContainer>
        ))}

        {isDelete ? (
          <div>
            <StyledButton
              onClick={handleAddHardSkill}
              variant="contained"
              endIcon={<AddIcon />}
            >
              {"Add"}
            </StyledButton>
          </div>
        ) : null}
      </Grid>

      {/* Soft Skill Section */}
      <Grid item xs={12} md={6}>
        <SkillTitle>
          <StyledBoldTypo isCenter>{"Soft Skill"}</StyledBoldTypo>
          <CustomSwitch sx={{ m: 1 }} checked={true} />
        </SkillTitle>

        {formPrepopValue?.softSkills?.map((skill, index) => (
          <RangeContainer key={skill.id}>
            <Box sx={{ width: "100%" }}>
              <StyledSpaceBetween>
                <Typography>{skill.skill}</Typography>
                <Typography>{"Critical"}</Typography>
              </StyledSpaceBetween>
              <StyledSlider
                defaultValue={skill.criticality_score}
                value={skill.criticality_score}
                aria-label="Default"
                valueLabelDisplay="auto"
                // onChange={(event, newValue) =>
                //   handleSliderChange(index, newValue, "soft")
                // }
                min={0}
                max={10}
                // disabled={true}
              />
            </Box>
            {isDelete ? (
              <img
                className="deleteImg"
                src={deleteIco}
                alt="delete"
                // onClick={() => handleRemoveSkill(index)}
                style={{ cursor: "pointer" }}
              />
            ) : null}
          </RangeContainer>
        ))}

        {formPrepopValue?.recommendSoftSkills?.length ? (
          <RecommendContainer>
            <Divider style={{ display: "flex", flexGrow: 1 }}>
              {"Recommended Skills"}
            </Divider>
            <StyledCheckBox checked={true} />
          </RecommendContainer>
        ) : null}

        {formPrepopValue?.recommendSoftSkills?.map((skill, index) => (
          <RangeContainer key={skill.id}>
            <Box sx={{ width: "100%" }}>
              <StyledSpaceBetween>
                <Typography>{skill.skill}</Typography>
                <Typography>{"Critical"}</Typography>
              </StyledSpaceBetween>
              <StyledSlider
                defaultValue={skill.criticality_score}
                value={skill.criticality_score}
                aria-label="Default"
                valueLabelDisplay="auto"
                // onChange={(event, newValue) =>
                //   handleSliderChange(index, newValue, "recommendSoft")
                // }
                min={0}
                max={10}
                // disabled={true}
              />
            </Box>
            {isDelete ? (
              <img
                className="deleteImg"
                src={deleteIco}
                alt="delete"
                // onClick={() => handleRemoveSkill(index)}
                style={{ cursor: "pointer" }}
              />
            ) : null}
          </RangeContainer>
        ))}
        {isDelete ? (
          <div>
            <StyledButton
              onClick={handleAddSoftSkill}
              variant="contained"
              endIcon={<AddIcon />}
            >
              {"Add"}
            </StyledButton>
          </div>
        ) : null}
      </Grid>
    </Grid>
  );
}

export default HardSoftSkill;
