import { Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import deleteIco from "../../../images/svgs/delete.svg";
import CircularProgress from "@mui/material/CircularProgress";
import { StyledButton } from "../../CandidateHub/Components/StyledComponents";
import { useTheme } from "@mui/material/styles";
import {
  ImportJobPostContainer,
  JDTextField,
  StyledFlexRowCenterView,
} from "./StyledComponents";

function ImportCreateJob({
  fileName,
  handleStep,
  handleRemoveFile,
  handleFileChange,
  handleUploadClick,
  fileInputRef,
  file,
  handleFirstNextClick,
  loading,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <Grid container p={4} gap={3}>
        <Grid item xs={12}>
          <Typography variant="h5" textAlign={"center"}>
            {"Enrich a Job Post"}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <ImportJobPostContainer>
            <JDTextField
              id="outlined-basic"
              label="Type the JD"
              variant="outlined"
              size="small"
              multiline
              rows={isMobile ? 10 : 15}
            />
            <StyledButton
              variant="contained"
              sx={{
                width: isMobile ? "100%" : "auto",
              }}
            >
              {"Import Job Post"}
            </StyledButton>
          </ImportJobPostContainer>
        </Grid>

        <Grid item xs={12}>
          {fileName && ( // Conditionally render the file name
            <StyledFlexRowCenterView>
              <Typography
                variant="body2"
                sx={{ marginLeft: "10px", textAlign: "center" }}
              >
                {fileName}
              </Typography>
              <img
                className="deleteImg"
                src={deleteIco}
                alt="delete"
                onClick={handleRemoveFile}
                style={{ cursor: "pointer" }}
              />
            </StyledFlexRowCenterView>
          )}
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <StyledButton
            variant="contained"
            component="span"
            onClick={handleUploadClick}
          >
            {"Upload File"}
            <input
              ref={fileInputRef} // Attac
              type="file"
              //   accept=".svg, .jpg, .png,"
              hidden
              onChange={handleFileChange}
              // value={file}
              name={"file"}
              id={"file"}
              //   onChange={handleMiniFileInputChange}
              //   onBlur={handleBlur}
            />
          </StyledButton>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: isMobile ? "center" : "flex-end",
          }}
        >
          <StyledButton
            onClick={handleFirstNextClick}
            variant="contained"
            startIcon={
              loading ? <CircularProgress color="inherit" size={15} /> : null
            }
            disabled={loading}
          >
            {"Next"}
          </StyledButton>
        </Grid>
      </Grid>
    </>
  );
}

export default ImportCreateJob;
