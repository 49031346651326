import { Box, Checkbox, Grid, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledStepperContainer = styled(Box)(() => ({
  width: "100%",
  "& .MuiStepIcon-root.Mui-active": {
    color: "#000000", // Your custom color
  },
  "& .MuiStepIcon-text": {
    fill: "#000000", // Your custom color
  },

  "& .MuiStepIcon-root.Mui-active .MuiStepIcon-text": {
    fill: "#ffffff", // Text color for active step icon
  },
}));

export const ImportJobPostContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column", // Mobile
  gap: "10px",
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row", // Tablet and above
    gap: "20px",
  },
}));

export const JDTextField = styled(TextField)(({ theme }) => ({
  minWidth: "100%", // Default for mobile
  [theme.breakpoints.up("sm")]: {
    minWidth: "400px", // For tablets
  },
  [theme.breakpoints.up("md")]: {
    minWidth: "620px", // For web
  },
}));

export const StyledFlexRowCenterView = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "10px",
  flexDirection: "row",
}));

export const LocationContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  justifyContent: "space-between", // Default for larger screens
  flexDirection: "row", // Default to row
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column", // Switch to column for mobile (small screens)
    justifyContent: "flex-start", // Align items to the start of the container
    alignItems: "flex-start",
  },
}));

export const ResponsiveFlexRowColumnContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  flexDirection: "row", // Default to row
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column", // Switch to column for mobile (small screens)
    justifyContent: "flex-start", // Align items to the start of the container
    alignItems: "flex-start",
  },
}));

//PrePop Job

export const HeaderEndViewGrid = styled(Grid)(() => ({
  dispaly: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "20px",
  justifyContent: "flex-end",
}));

export const SkillTitle = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "30px",
}));

export const RangeContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: "20px",
  alignItems: "center",
  marginBottom: "10px",
  [theme.breakpoints.up("md")]: {
    marginRight: "30px",
  },
}));

export const StyledSpaceBetween = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
}));

export const RecommendContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "10px",
  marginBottom: "10px",
  [theme.breakpoints.up("md")]: {
    marginRight: "30px",
  },
}));

export const StyledBoldTypo = styled(Typography)(({ isCenter }) => ({
  fontWeight: "800 !important", // Use theme's bold weight
  textAlign: isCenter ? "center" : "left",
  fontSize: "16px",
}));

export const TitleSwitchContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}));

export const ConfrimSettingsContainer = styled(Grid)(() => ({
  dispaly: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "20px",
  justifyContent: "flex-start",
}));

export const ConfirmSettingsGreyContainer = styled("div")(() => ({
  backgroundColor: "#d5d6d769",
  padding: "20px",
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  borderRadius: "8px",
  minHeight: "100%",
}));

export const TierContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  gap: "35px",
  alignItems: "center",
}));

export const StyledCheckBoxAll = styled(Checkbox)(() => ({
  color: "black",
  "&.Mui-checked": {
    color: "black",
  },
  "&.MuiButtonBase-root": {
    paddingRight: "8px !important", // Add padding right to the Checkbox
    padding: "0px",
  },
}));

export const InterviewSettingsContainerGrid = styled(Grid)(() => ({
  border: "1px solid black",
  paddingTop: "30px !important",
  paddingBottom: "30px !important",
  paddingLeft: "10px",
  paddingRight: "10px",
}));

export const InterviewTouchContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
}));

export const ConfirmSettingButtonGrid = styled(Grid)(() => ({
  dispaly: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "20px",
  justifyContent: "center",
  marginTop: "30px !important",
}));
