import {
  Grid,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  StyledButton,
  StyledFlexColumnView,
} from "../../CandidateHub/Components/StyledComponents";
import { useHistory, useParams } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { compareResume } from "../../../api/api";
import axios from "axios";
import CustomSnack from "../../../commonComponents/Snack/CustomSnack";

function CompareResumeTableList() {
  const history = useHistory();
  const param = useParams();
  const firstResumeId = param.firstResumeId;
  const secondResumeId = param.secondResumeId;
  const [tableList, setTableList] = useState([]);
  const [maxItemsCount, setMaxItemsCount] = useState(null);
  const [pageLoader, setPageLoader] = useState(false);
  const [snack, setSnack] = useState({
    open: false,
    color: "",
    message: "",
  });

  useEffect(() => {
    setPageLoader(true);

    const fetchActiveJobList = async () => {
      try {
        const res = await axios.get(
          compareResume(firstResumeId, secondResumeId)
        );

        setTableList(res?.data?.data);

        // Corrected the parentheses for Math.max
        setMaxItemsCount(
          Math.max(
            ...res?.data?.data?.candidates?.map((person) => person.items.length)
          )
        );

        setPageLoader(false);
      } catch (error) {
        setSnack({
          open: true,
          color: "error",
          message: error?.response?.data?.message || "An error occurred",
        });
        setPageLoader(false);
      }
    };

    fetchActiveJobList();
  }, [firstResumeId, secondResumeId]);

  console.log(tableList, "tableList");

  //   const maxItemsCount = Math.max(
  //     ...tableList?.candidates?.map((person) => person.items.length)
  //   );

  const handleBack = () => {
    history.goBack();
  };

  const handleSnackClose = () => {
    setSnack({
      open: false,
      color: "",
      message: "",
    });
  };

  return (
    <>
      <StyledFlexColumnView>
        <StyledButton
          onClick={handleBack}
          startIcon={<KeyboardBackspaceIcon />}
        >
          {"Back"}
        </StyledButton>

        {pageLoader ? (
          <Grid container spacing={3}>
            {Array.from(new Array(12)).map((el, ind) => (
              <Grid item xs={12} sm={4} key={`key-${ind}`}>
                <Skeleton
                  key={`key-${ind.toString()}`}
                  width={"100%"}
                  height={100}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <TableContainer
            component={Paper}
            sx={{
              flexGrow: 1,
              overflowY: "auto",
              maxHeight: "calc(100vh - 177px)",
            }}
          >
            <Table
              sx={{
                "& .MuiTableCell-root": {
                  border: "1px solid black",
                },
              }}
              aria-label="spanning table"
            >
              <TableHead
                sx={{
                  position: "sticky",
                  top: 0, // Adjust if needed
                  zIndex: 1000, // Ensure it stays on top of other content when scrolling
                  backgroundColor: "white", // Optional: Keep it visible with a background
                  boxShadow: "0 0.2px 0.2px black",
                  border: "1px solid black",
                  "& .MuiTableCell-root": {
                    border: "1px solid black",
                    backgroundColor: "white",
                  },
                }}
              >
                <TableRow
                  sx={{
                    border: "1px solid black",
                    boxShadow: "0 1px 1px black",
                    backgroundColor: "white",
                  }}
                >
                  <TableCell
                    colSpan={2}
                    sx={{
                      "&:first-child": {
                        // Correctly targeting the first-child pseudo-class
                        position: "unset !important",
                        left: "unset !important",
                        zIndex: "unset !important",
                        backgroundColor: "unset !important",
                      },
                    }}
                  >
                    <Typography
                      variant="h6"
                      textAlign={"center"}
                      fontWeight={"bold"}
                    >
                      Compare Resume
                    </Typography>
                  </TableCell>
                  {tableList?.candidates?.map((person, index) => (
                    <TableCell align="left" colSpan={4} key={index}>
                      <Typography sx={{ fontSize: "16px" }}>
                        <b>Name:</b> {person.name}
                      </Typography>
                      <Typography sx={{ fontSize: "16px" }}>
                        <b>Score:</b> {person.score}
                      </Typography>
                      <Typography sx={{ fontSize: "16px" }}>
                        <b>Rate:</b> {person.rate}
                      </Typography>
                      <Typography sx={{ fontSize: "16px" }}>
                        <b>Location:</b> {person.location}
                      </Typography>
                      <Typography sx={{ fontSize: "16px" }}>
                        <b>Title:</b> {person.title}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell
                    align="left"
                    sx={{
                      fontWeight: "800",
                      fontSize: "16px",
                      "&:first-child": {
                        // Correctly targeting the first-child pseudo-class
                        position: "unset !important",
                        left: "unset !important",
                        zIndex: "unset !important",
                        backgroundColor: "unset !important",
                      },
                    }}
                  >
                    Category
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ fontWeight: "800", fontSize: "16px" }}
                  >
                    Name
                  </TableCell>
                  {tableList?.candidates?.map((_, index) => (
                    <React.Fragment key={index}>
                      <TableCell
                        align="left"
                        sx={{ fontWeight: "800", fontSize: "16px" }}
                      >
                        Score
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ fontWeight: "800", fontSize: "16px" }}
                      >
                        Weightage
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ fontWeight: "800", fontSize: "16px" }}
                      >
                        Weightage Score
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ fontWeight: "800", fontSize: "16px" }}
                      >
                        Justification
                      </TableCell>
                    </React.Fragment>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.from({ length: maxItemsCount }).map((_, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {/* First cell is the description */}
                    <TableCell
                      sx={{
                        "&:first-child": {
                          // Correctly targeting the first-child pseudo-class
                          position: "unset !important",
                          left: "unset !important",
                          zIndex: "unset !important",
                          backgroundColor: "unset !important",
                        },
                        fontSize: "16px",
                      }}
                    >
                      {tableList?.candidates[0]?.items[rowIndex]?.category ||
                        ""}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        "@media (max-width: 1300px)": {
                          whiteSpace: "normal",
                          wordBreak: "break-word",
                        },
                      }}
                    >
                      {tableList?.candidates[0]?.items[rowIndex]?.name || ""}
                    </TableCell>

                    {tableList?.candidates?.map((person, personIndex) => (
                      <React.Fragment key={personIndex}>
                        <TableCell align="left" sx={{ fontSize: "16px" }}>
                          {person.items[rowIndex]?.score !== undefined
                            ? person.items[rowIndex].score.toFixed(2)
                            : "0.00"}
                        </TableCell>
                        <TableCell align="left" sx={{ fontSize: "16px" }}>
                          {person.items[rowIndex]?.weightage !== undefined
                            ? person.items[rowIndex].weightage.toFixed(2)
                            : "0.00"}
                        </TableCell>
                        <TableCell align="left" sx={{ fontSize: "16px" }}>
                          {person.items[rowIndex]?.weightage_score !== undefined
                            ? person.items[rowIndex].weightage_score.toFixed(2)
                            : "0.00"}
                        </TableCell>
                        <TableCell align="left" sx={{ fontSize: "16px" }}>
                          {person.items[rowIndex]?.justification || "-"}
                        </TableCell>
                      </React.Fragment>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </StyledFlexColumnView>

      <CustomSnack
        open={snack.open}
        color={snack.color}
        message={snack.message}
        onClose={handleSnackClose}
      />
    </>
  );
}

export default CompareResumeTableList;
