import {
  Autocomplete,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import {
  StyledButton,
  StyledFlexRowView,
} from "../../components/CandidateHub/Components/StyledComponents";
import VoicemailIcon from "@mui/icons-material/Voicemail";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Delete } from "@material-ui/icons";

function AddNoteDialog({
  handleAddNotesDialogClose,
  handleSaveAddNotes,
  addNotesDialogOpen,
  noteList,
  handleAddAnotherNote,
  handleNoteTextChange,
  handlePhoneEmailTextChange,
  handleNoteDelete,
  username,
  addNoteLoading,
  currentDate,
  typeList,
}) {
  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={addNotesDialogOpen}
      onClose={handleAddNotesDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiPaper-root": {
          padding: "20px",
          borderRadius: "28px",
          backgroundColor: "#f3e9ff",
        },
      }}
    >
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "space-between",
                flexWrap: "wrap", // Makes the content wrap for smaller screens
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  fontSize: {
                    xs: "1rem", // Mobile
                    sm: "1.2rem", // Tablet
                    md: "1.5rem", // Web
                  },
                }}
              >
                {"Notes"}
              </Typography>

              <Typography
                sx={{
                  fontSize: {
                    xs: "0.8rem", // Smaller font on mobile
                    sm: "1rem", // Tablet
                  },
                  color: "#828282",
                }}
              >
                {currentDate}
              </Typography>

              <Chip
                label={`Added by: ${username}`}
                variant="outlined"
                sx={{
                  backgroundColor: "white",
                  borderRadius: "8px",
                  fontWeight: "bold",
                  fontSize: {
                    xs: "0.7rem", // Mobile
                    sm: "0.85rem", // Tablet
                  },
                }}
              />

              <StyledFlexRowView>
                <VoicemailIcon />
                <Typography
                  sx={{
                    fontWeight: "bold",
                    color: "#828282",
                    fontSize: {
                      xs: "0.8rem", // Mobile
                      sm: "1rem", // Tablet
                    },
                  }}
                >
                  {"Improve"}
                </Typography>
              </StyledFlexRowView>
            </div>
          </Grid>

          {noteList?.map((note, index) => (
            <React.Fragment key={index}>
              {noteList?.length > 1 ? (
                <Grid item xs={12}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>{`Note ${
                      index + 1
                    }`}</Typography>
                    <IconButton>
                      <Delete
                        onClick={() => handleNoteDelete(index)}
                        sx={{ cursor: "pointer" }}
                      />
                    </IconButton>
                  </div>
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <TextField
                  multiline
                  rows={8}
                  placeholder="Notes...."
                  value={note.note}
                  onChange={(e) => handleNoteTextChange(index, e)}
                  fullWidth
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "8px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "white",
                      },
                      "&:hover fieldset": {
                        borderColor: "white",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    marginBottom: "10px",
                  }}
                >
                  {"Interaction Type"}
                </Typography>
                <Autocomplete
                  disablePortal
                  options={typeList}
                  getOptionLabel={(option) => option.label}
                  value={
                    typeList.find(
                      (option) => option.label === note.interaction
                    ) || null
                  } // Set the current value
                  onChange={(event, value) => {
                    handlePhoneEmailTextChange(index, value); // Pass the entire value object
                  }} // Handle change
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      placeholder="Phone/Email/Text"
                      fullWidth
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "8px",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "white",
                          },
                          "&:hover fieldset": {
                            borderColor: "white",
                          },
                        },
                      }}
                    />
                  )}
                />
              </Grid>
            </React.Fragment>
          ))}

          <Grid item xs={12}>
            <Button
              variant="text"
              sx={{
                color: "#828282",
                textTransform: "none",
              }}
              startIcon={<AddCircleOutlineIcon />}
              onClick={handleAddAnotherNote}
            >
              {"Add another"}
            </Button>
          </Grid>

          <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
            <StyledFlexRowView>
              <StyledButton
                variant="contained"
                onClick={handleSaveAddNotes}
                startIcon={
                  addNoteLoading ? (
                    <CircularProgress color="inherit" size={15} />
                  ) : null
                }
                disabled={addNoteLoading}
              >
                Save
              </StyledButton>
              <StyledButton
                variant="contained"
                onClick={handleAddNotesDialogClose}
              >
                Cancel
              </StyledButton>
            </StyledFlexRowView>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default AddNoteDialog;
