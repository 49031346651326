import React from "react";
import FormPrePop from "./FormPrePop";
import { Grid, Skeleton } from "@mui/material";
import HardSoftSkill from "./HardSoftSkill";
import { Typography } from "@material-ui/core";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import Education from "./Education";
import OverallChart from "./OverallChart";
import { StyledButton } from "../../CandidateHub/Components/StyledComponents";
import { HeaderEndViewGrid } from "./StyledComponents";

function PrePopJob({
  handleStep,
  formPrepopValue,
  handleAddHardSkill,
  handleAddSoftSkill,
  handleRemoveSkill,
  handleSliderChange,
  handleMultiSelectChange,
  pageLoader,
}) {
  const handleNext = () => {
    handleStep(2);
  };
  const handleBackClick = () => {
    handleStep(0);
  };
  return (
    <Grid container spacing={3}>
      <HeaderEndViewGrid container item xs={12}>
        <Typography>
          {"Chat for Support"} <ChatBubbleOutlineIcon />
        </Typography>
      </HeaderEndViewGrid>

      <HeaderEndViewGrid container item xs={12}>
        <StyledButton variant="outlined" onClick={handleBackClick}>
          {"Start Over"}
        </StyledButton>
        <StyledButton onClick={handleNext} variant="contained">
          {"Save Draft"}
        </StyledButton>
      </HeaderEndViewGrid>

      {pageLoader ? (
        Array.from(new Array(12)).map((el, ind) => (
          <Grid item xs={12} sm={4} key={`key-${ind}`}>
            <Skeleton
              key={`key-${ind.toString()}`}
              width={"100%"}
              height={100}
            />
          </Grid>
        ))
      ) : (
        <>
          <Grid item xs={12}>
            <FormPrePop formPrepopValue={formPrepopValue} />
          </Grid>

          <Grid item xs={12}>
            <HardSoftSkill
              formPrepopValue={formPrepopValue}
              handleAddHardSkill={handleAddHardSkill}
              handleAddSoftSkill={handleAddSoftSkill}
              handleRemoveSkill={handleRemoveSkill}
              handleSliderChange={handleSliderChange}
              isDelete={false}
            />
          </Grid>

          <Grid item xs={12} mt={3}>
            <Education
              formPrepopValue={formPrepopValue}
              handleMultiSelectChange={handleMultiSelectChange}
            />
          </Grid>

          <Grid item xs={12} mt={3}>
            <OverallChart formPrepopValue={formPrepopValue} />
          </Grid>

          <Grid
            container
            alignItems={"center"}
            justifyContent={"space-between"}
            item
            xs={12}
          >
            <StyledButton variant="outlined" onClick={handleBackClick}>
              {"Back"}
            </StyledButton>
            <StyledButton onClick={handleNext} variant="contained">
              {"Next"}
            </StyledButton>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default PrePopJob;
