import { Grid, TextField, Chip } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import React from "react";
import CustomSwitch from "../../../commonComponents/Switch/CustomSwitch";
import {
  LocationContainer,
  ResponsiveFlexRowColumnContainer,
  StyledBoldTypo,
  TitleSwitchContainer,
} from "./StyledComponents";

const filter = createFilterOptions();
function Education({ formPrepopValue, handleMultiSelectChange }) {
  return (
    <Grid container p={3} spacing={2} sx={{ border: "1px solid black" }}>
      <Grid container item xs={12} md={6} spacing={2}>
        <Grid item xs={12}>
          <TitleSwitchContainer>
            <StyledBoldTypo>{"Highest level of Education"}</StyledBoldTypo>
            <CustomSwitch sx={{ m: 1 }} checked={true} />
          </TitleSwitchContainer>

          <TextField
            id={`outline`}
            label={""}
            placeholder={""}
            fullWidth
            multiline
            value={formPrepopValue?.education}
          />
        </Grid>

        <Grid item xs={12}>
          <TitleSwitchContainer>
            <StyledBoldTypo>{"Certificates"}</StyledBoldTypo>
            <CustomSwitch sx={{ m: 1 }} checked={true} />
          </TitleSwitchContainer>

          <Autocomplete
            multiple
            fullWidth
            value={formPrepopValue?.certificates || []}
            id="tags-filled"
            options={[]}
            freeSolo
            onChange={(e, newValue) =>
              handleMultiSelectChange("certificates", newValue)
            }
            readOnly
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              // Suggest the creation of a new value
              if (params.inputValue !== "") {
                filtered.push({
                  inputValue: params.inputValue,
                  value: `Add "${params.inputValue}"`,
                });
              }

              return filtered;
            }}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === "string") {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return option?.value;
            }}
            // renderOption={(option) => option}
            renderOption={(props, option) => {
              const { key, ...optionProps } = props;
              return (
                <li key={key} {...optionProps}>
                  {option?.value}
                </li>
              );
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            // getOptionSelected={(option, value) => option === value}
            filterSelectedOptions
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  style={{
                    color: option.name == "inferred" ? "red" : "",
                  }}
                  variant="outlined"
                  label={option.inputValue ? option.inputValue : option?.value}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                multiline
                {...params}
                variant="outlined"
                placeholder=""
                label=""
                inputProps={{
                  ...params.inputProps,
                  onKeyDown: (e) => {
                    if (e.key === "Enter") {
                      e.stopPropagation();
                    }
                  },
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <TitleSwitchContainer>
            <StyledBoldTypo>{"Spoken Languages"}</StyledBoldTypo>
            <CustomSwitch sx={{ m: 1 }} checked={true} />
          </TitleSwitchContainer>

          <Autocomplete
            readOnly
            multiple
            fullWidth
            value={formPrepopValue?.languages || []}
            id="tags-filled"
            options={[]}
            freeSolo
            onChange={(e, newValue) =>
              handleMultiSelectChange("languages", newValue)
            }
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              // Suggest the creation of a new value
              if (params.inputValue !== "") {
                filtered.push({
                  inputValue: params.inputValue,
                  value: `Add "${params.inputValue}"`,
                });
              }

              return filtered;
            }}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === "string") {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return option?.value;
            }}
            // renderOption={(option) => option}
            renderOption={(props, option) => {
              const { key, ...optionProps } = props;
              return (
                <li key={key} {...optionProps}>
                  {option?.value}
                </li>
              );
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            // getOptionSelected={(option, value) => option === value}
            filterSelectedOptions
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  style={{
                    color: option.name == "inferred" ? "red" : "",
                  }}
                  label={option.inputValue ? option.inputValue : option?.value}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                multiline
                {...params}
                variant="outlined"
                label="Languages"
                inputProps={{
                  ...params.inputProps,
                  onKeyDown: (e) => {
                    if (e.key === "Enter") {
                      e.stopPropagation();
                    }
                  },
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <TitleSwitchContainer>
            <StyledBoldTypo>{"Visa Status"}</StyledBoldTypo>
            <CustomSwitch sx={{ m: 1 }} checked={formPrepopValue?.visaStatus} />
          </TitleSwitchContainer>

          <TextField
            id="outlined-multiline-flexible"
            label=""
            placeholder="Needs authorization to work. No sponsorship"
            fullWidth
            value={formPrepopValue?.visaContent || ""}
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} md={6} sx={{ height: "100%" }} spacing={2}>
        <Grid item xs={12}>
          <LocationContainer>
            <TitleSwitchContainer>
              <StyledBoldTypo>{"Location Targets"}</StyledBoldTypo>
              <CustomSwitch sx={{ m: 1 }} checked={true} />
            </TitleSwitchContainer>
            <TitleSwitchContainer>
              <StyledBoldTypo>{"Relocation allowed"}</StyledBoldTypo>
              <CustomSwitch
                sx={{ m: 1 }}
                checked={formPrepopValue?.relocation_required}
                name={"relocation_required"}
              />
            </TitleSwitchContainer>
          </LocationContainer>

          <Autocomplete
            readOnly
            multiple
            fullWidth
            value={formPrepopValue?.locationTargets || []}
            id="tags-filled"
            options={[]}
            freeSolo
            onChange={(e, newValue) =>
              handleMultiSelectChange("locationTargets", newValue)
            }
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              // Suggest the creation of a new value
              if (params.inputValue !== "") {
                filtered.push({
                  inputValue: params.inputValue,
                  value: `Add "${params.inputValue}"`,
                });
              }

              return filtered;
            }}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === "string") {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return option?.value;
            }}
            // renderOption={(option) => option}
            renderOption={(props, option) => {
              const { key, ...optionProps } = props;
              return (
                <li key={key} {...optionProps}>
                  {option?.value}
                </li>
              );
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            // getOptionSelected={(option, value) => option === value}
            filterSelectedOptions
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option.inputValue ? option.inputValue : option?.value}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                multiline
                {...params}
                variant="outlined"
                label=""
                placeholder=""
                inputProps={{
                  ...params.inputProps,
                  onKeyDown: (e) => {
                    if (e.key === "Enter") {
                      e.stopPropagation();
                    }
                  },
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <TitleSwitchContainer>
            <StyledBoldTypo>{"Employer Targets"}</StyledBoldTypo>
            <CustomSwitch sx={{ m: 1 }} checked={true} />
          </TitleSwitchContainer>

          <Autocomplete
            readOnly
            multiple
            fullWidth
            value={formPrepopValue?.employerTargets || []}
            id="tags-filled"
            options={[]}
            freeSolo
            onChange={(e, newValue) =>
              handleMultiSelectChange("employerTargets", newValue)
            }
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              // Suggest the creation of a new value
              if (params.inputValue !== "") {
                filtered.push({
                  inputValue: params.inputValue,
                  value: `Add "${params.inputValue}"`,
                });
              }

              return filtered;
            }}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === "string") {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return option?.value;
            }}
            // renderOption={(option) => option}
            renderOption={(props, option) => {
              const { key, ...optionProps } = props;
              return (
                <li key={key} {...optionProps}>
                  {option?.value}
                </li>
              );
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            // getOptionSelected={(option, value) => option === value}
            filterSelectedOptions
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  style={{
                    color: option.name == "inferred" ? "red" : "",
                  }}
                  variant="outlined"
                  label={option.inputValue ? option.inputValue : option?.value}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                multiline
                {...params}
                variant="outlined"
                placeholder=""
                label=""
                inputProps={{
                  ...params.inputProps,
                  onKeyDown: (e) => {
                    if (e.key === "Enter") {
                      e.stopPropagation();
                    }
                  },
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <ResponsiveFlexRowColumnContainer>
            <TitleSwitchContainer>
              <StyledBoldTypo>{"Govt/Security Cl."}</StyledBoldTypo>
              <CustomSwitch
                sx={{ m: 1 }}
                checked={formPrepopValue?.govtSecurityClearance}
                // onChange={handleCheckedChange}
                name={"govtSecurityClearance"}
              />
            </TitleSwitchContainer>
            <TitleSwitchContainer>
              <StyledBoldTypo>{"DEI"}</StyledBoldTypo>
              <CustomSwitch
                sx={{ m: 1 }}
                checked={formPrepopValue?.dei}
                // onChange={handleCheckedChange}
                name={"dei"}
              />
            </TitleSwitchContainer>
          </ResponsiveFlexRowColumnContainer>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Education;
