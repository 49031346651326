import {
  Autocomplete,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Modal,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import JobResumeTab from "../components/JobResumeTab";
import {
  StyledButton,
  StyledFlexRowView,
} from "../../CandidateHub/Components/StyledComponents";
import FormPrePop from "../../PostJob/Components/FormPrePop";
import HardSoftSkill from "../../PostJob/Components/HardSoftSkill";
import Education from "../../PostJob/Components/Education";
import OverallChart from "../../PostJob/Components/OverallChart";
import Profile from "../../InteractionHub/Components/Profile";
import InteractionHistory from "../../InteractionHub/Components/InteractionHistory";
import axios from "axios";
import {
  addNotes,
  downloadTalairoJd,
  downloadTalairoResume,
  getJobData,
  getMatchedJdResumeData,
  getNotes,
  JdAddNotes,
  JdGetNotes,
  qmResumeAddNotes,
  qmResumeGetNotes,
  uploadJdResume,
} from "../../../api/api";
import { useHistory, useParams, useLocation } from "react-router-dom";
import CustomSnack from "../../../commonComponents/Snack/CustomSnack";
import { StyledSpaceBetween } from "../../PostJob/Components/StyledComponents";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import UploadIcon from "@mui/icons-material/Upload";
import {
  StyledTextField,
  StyledUploadEnrichButton,
} from "../../QuickMatch/Components/StyledComponents";
import { Add, Clear, Download } from "@mui/icons-material";
import AddNoteDialog from "../../../commonComponents/AddNoteDialog/AddNoteDialog";
import Notes from "../../CandidateHub/Components/Notes";
import SnackModal from "../../../commonComponents/Modal/SnackModal";

function JobResumeDetail() {
  const location = useLocation();
  const params = useParams();
  const history = useHistory();
  const jobId = params.jobId;
  const [tabValue, setTabValue] = React.useState(0);
  const resumeTabIndex = tabValue - 1;
  const username = localStorage.getItem("username");
  const [formPrepopValue, setFormPrepopValue] = useState({
    title: "",
    industry: "",
  });
  const [resumeTab, setResumeTab] = useState([]);
  const matchedData = resumeTab[resumeTabIndex]?.details;
  //   const [matchedData, setMatchedData] = useState("");
  const [uploadResumeDialogOpen, setUploadResumeDialogOpen] = useState(false);
  const [resumeFileName, setResumeFileName] = useState("");
  const resumeInputRef = useRef(null);
  const [pageLoader, setPageLoader] = useState(false);
  const [resumeLoader, setResumeLoader] = useState(false);

  const [uploadResumeLoading, setUploadResumeLoading] = useState(false);
  const [progressPercent, setProgressPercent] = useState(0);
  const [snack, setSnack] = useState({
    open: false,
    color: "",
    message: "",
  });

  //Add Notes
  const [addNotesDialogOpen, setAddNotesDialogOpen] = useState(false);
  const [noteList, setNoteList] = useState([
    { note: "", interaction: "", added_by: username },
  ]);
  const [notesTableList, setNotesTableList] = useState([]);
  const [addNoteLoading, setAddNoteLoading] = useState(false);

  //Candidate Add Notes
  const [candidateAddNotesDialogOpen, setCandidateAddNotesDialogOpen] =
    useState(false);
  const [candidateNoteList, setCandidateNoteList] = useState([
    { note: "", interaction: "", added_by: username },
  ]);
  const [candidateNotesTableList, setCandidateNotesTableList] = useState([]);
  const [candidateAddNoteLoading, setCandidateAddNoteLoading] = useState(false);

  //Compare Resume
  const [compareModalOpen, setCompareModalOpen] = useState(false);
  const [candidate, setCandidate] = useState(null);
  const [resumeDownloadLoading, setResumeDownloadLoading] = useState(false);
  const [jdDownloadLoading, setJdDownloadLoading] = useState(false);

  const typeList = [{ label: "Phone" }, { label: "Email" }, { label: "Text" }];

  const currentDate = new Date().toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
  });

  const handleAddNotesDialogClose = () => {
    setAddNotesDialogOpen(false);
    setNoteList([{ note: "", interaction: "", added_by: username }]);
  };

  const getNotesDetatil = (id) => {
    axios
      .get(JdGetNotes(id))
      .then((res) => {
        setNotesTableList(res?.data?.data);
      })
      .catch((err) => {
        setSnack({
          open: true,
          color: "error",
          message: err?.response?.data?.message,
        });
      });
  };
  // Handle saving the notes
  const handleSaveAddNotes = () => {
    const hasEmptyFields = noteList.some(
      (noteItem) => !noteItem.note || !noteItem.interaction
    );

    if (hasEmptyFields) {
      setSnack({
        open: true,
        color: "error",
        message: "Note and Interaction Type fields are required",
      });
      return; // Stop execution if validation fails
    }

    if (jobId) {
      setAddNoteLoading(true);
      axios
        .post(JdAddNotes(jobId), noteList)
        .then((response) => {
          if (response?.data?.success === true) {
            setSnack({
              open: true,
              color: "success",
              message: response?.data?.message,
            });
            setAddNoteLoading(false);
          } else {
            setSnack({
              open: true,
              color: "error",
              message: response?.data?.message,
            });
            setAddNoteLoading(false);
          }
          getNotesDetatil(jobId);
          setAddNotesDialogOpen(false);
          setNoteList([{ note: "", interaction: "", added_by: username }]);
        })
        .catch((err) => {
          setSnack({
            open: true,
            color: "error",
            message: err?.response?.data?.message,
          });
          setAddNoteLoading(false);
        });
    }
  };

  // Handle adding another note and interaction type
  const handleAddAnotherNote = () => {
    setNoteList([
      ...noteList,
      { note: "", interaction: "", added_by: username },
    ]); // Add new empty note object
  };

  // Handle note text change for a specific index
  const handleNoteTextChange = (index, e) => {
    const newNotes = [...noteList];
    newNotes[index].note = e.target.value; // Update note for the corresponding index
    setNoteList(newNotes);
  };

  // Handle interaction type change for a specific index
  const handlePhoneEmailTextChange = (index, value) => {
    const newNotes = [...noteList];
    newNotes[index].interaction = value?.label || ""; // Ensure you set the correct field
    setNoteList(newNotes);
  };

  const handleAddNotes = () => {
    setAddNotesDialogOpen(true);
  };

  const handleNoteDelete = (indexToDelete) => {
    let arr = [...noteList];
    arr.splice(indexToDelete, 1);
    setNoteList(arr);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    const index = newValue - 1;
    const isMatchedData = resumeTab[newValue - 1]?.details;
    const resumeId = resumeTab[newValue - 1]?.id;
    if (!isMatchedData && newValue) {
      fetchResumeData(resumeId, index);
      // getCandidateNotesDetatil(resumeId);
    }
  };
  const resumeId = resumeTab[tabValue - 1]?.id;
  useEffect(() => {
    if (resumeId) {
      getCandidateNotesDetatil(resumeId);
    }
  }, [resumeId]);

  const fetchResumeData = async (resumeId, index, resumeObj) => {
    setResumeLoader(true);
    try {
      const {
        data: { data },
      } = await axios.get(getMatchedJdResumeData(resumeId));

      let arr = [...resumeTab];
      let detailObj = {
        candidate_name: data?.resume_info?.candidate_name,
        pay_rate: data?.resume_info?.pay_rate,
        location: data?.resume_info?.location,
        inferred_title: data?.resume_info?.inferred_title,
        visa_info: data?.resume_info?.visa_info,
        overall_score: data?.quick_match_score?.overall_score,
        results: data?.results || [],
        skill_calculation: data?.skill_calculation,
        error_message: data?.error_message,
        resume_id: data?.resume_id,
        // file_name: data?.resume_file_name,
        spider_graph_results: data?.spider_graph_results,
        file_name: data?.resume_file_name,
        insights: data?.insights,
        feedback: data?.feedback,
      };
      if (arr[index]) {
        arr[index] = {
          ...arr[index],
          details: detailObj,
        };
      } else if (resumeObj) {
        arr.push({
          ...resumeObj,
          details: detailObj,
        });
      }
      setResumeTab(arr);
      console.log(data, "matchdata");
      // setMatchedData({
      //   candidate_name: data?.resume_info?.candidate_name,
      //   pay_rate: data?.resume_info?.pay_rate,
      //   location: data?.resume_info?.location,
      //   inferred_title: data?.resume_info?.inferred_title,
      //   visa_info: data?.resume_info?.visa_info,
      //   overall_score: data?.quick_match_score?.overall_score,
      //   results: data?.results || [],
      // });
      setResumeLoader(false);
    } catch (error) {
      setSnack({
        open: true,
        color: "error",
        message: error?.response?.data?.message,
      });
      setResumeLoader(false);
    }
  };

  const fetchJobData = async () => {
    setPageLoader(true);
    try {
      const {
        data: { data },
      } = await axios.get(getJobData(jobId));

      const certificates = data?.job_details?.certificates?.flatMap((item) =>
        item.content
          .filter((value) => value.length > 0) // Filter out empty strings
          .map((value) => ({ name: item.name, value }))
      );

      const languages = data?.job_details?.languages?.flatMap((item) =>
        item.content
          .filter((value) => value.length > 0) // Filter out empty strings
          .map((value) => ({ name: item.name, value }))
      );
      const employer = data?.job_details?.employer_targets?.flatMap((item) =>
        item.content
          .filter((value) => value.length > 0) // Filter out empty strings
          .map((value) => ({ name: item.name, value }))
      );
      setFormPrepopValue({
        title: data?.job_details?.job_title,
        industry: data?.job_details?.industry,
        payRate:
          typeof data?.job_details?.pay_rate === "string"
            ? data.job_details.pay_rate
            : null,
        managementLevel: data?.job_details?.management_level,
        employmentType:
          typeof data?.job_details?.employment === "string"
            ? data.job_details.employment
            : null,
        location: data?.job_details?.location,
        startDate: data?.job_details?.start_date,
        endDate: data?.job_details?.end_date,
        occupationalClassification:
          data?.job_details?.occupational_classification,
        requestId: data?.job_details?.req_id,
        shift: data?.job_details?.shift,
        reportsTo: data?.job_details?.reports_to,
        buDepartmentTeam: data?.job_details["bu/department/team"],
        hardSkills: data?.job_details?.extracted_hard_skills,
        softSkills: data?.job_details?.extracted_soft_skills,
        recommendHardSkills: data?.job_details?.relevant_hard_skills,
        recommendSoftSkills: data?.job_details?.relevant_soft_skills,

        locationTargets: data?.job_details?.location_targets?.map((elt) => ({
          value: elt,
        })),
        employerTargets: employer,
        certificates: certificates,
        education:
          typeof data?.job_details?.education === "string"
            ? data?.job_details?.education
            : null,
        languages: languages,
        visaStatus: data?.job_details["visa/employment_needs"]?.visa_status,
        visaContent: data?.job_details["visa/employment_needs"]?.content,
        govtSecurityClearance: data?.job_details?.govt_security_clearance,
        dei: data?.job_details?.dei,
        relocation_required: data?.job_details?.relocation_required,
        score: data?.job_details?.score,
        insights: data?.job_details?.insights,
        inferredTitle: data?.job_details?.inferred_title,
        companyProfile: data?.job_details?.company_profile,
        company_name: data?.job_details?.company_name,
        scoreLabel: data?.job_details?.score_label,
        interviewType: data?.job_details?.interview_type || [],
      });
      console.log(data?.job_details?.resumes, "data?.job_details?.resumes");
      setResumeTab(data?.resumes || []);
      setPageLoader(false);
    } catch (error) {
      setSnack({
        open: true,
        color: "error",
        message: error?.response?.data?.message,
      });
      setPageLoader(false);
    }
  };

  useEffect(() => {
    fetchJobData();
    getNotesDetatil(jobId);

    //resume
  }, [jobId]);

  const handleAddHardSkill = () => {
    const newSkill = {
      skill: "",
      experience_in_yrs: 0,
      criticality_score: 0,
    };
    //   setHardSkills([...hardSkills, newSkill]);
    let arr = [...formPrepopValue?.hardSkills, newSkill];
    setFormPrepopValue({
      ...formPrepopValue,
      hardSkills: arr,
    });
  };

  const handleAddSoftSkill = () => {
    const newSkill = {
      skill: "",
      criticality_score: 0,
    };
    let arr = [...formPrepopValue?.softSkills, newSkill];
    setFormPrepopValue({
      ...formPrepopValue,
      softSkills: arr,
    });
    //   setSoftSkills([...softSkills, newSkill]);
  };

  const handleRemoveSkill = (index) => {
    let arr = [...formPrepopValue?.hardSkills];
    arr.splice(index, 1);
    setFormPrepopValue({
      ...formPrepopValue,
      hardSkills: arr,
    });
  };

  const handleSliderChange = (index, newValue, type) => {
    if (type === "hard") {
      let arr = [...formPrepopValue?.hardSkills];
      arr[index].criticality_score = newValue;
      setFormPrepopValue({
        ...formPrepopValue,
        hardSkills: arr,
      });
    } else if (type === "soft") {
      let arr = [...formPrepopValue?.softSkills];
      arr[index].criticality_score = newValue;
      setFormPrepopValue({
        ...formPrepopValue,
        softSkills: arr,
      });
    } else if (type === "recommendHard") {
      let arr = [...formPrepopValue?.recommendHardSkills];
      arr[index].criticality_score = newValue;
      setFormPrepopValue({
        ...formPrepopValue,
        recommendHardSkills: arr,
      });
    } else {
      let arr = [...formPrepopValue?.recommendSoftSkills];
      arr[index].criticality_score = newValue;
      setFormPrepopValue({
        ...formPrepopValue,
        recommendSoftSkills: arr,
      });
    }
  };

  const handleMultiSelectChange = (name, val) => {
    if (typeof val === "string") {
      setFormPrepopValue({
        ...formPrepopValue,
        [name]: val,
      });
    } else if (val && val.inputValue) {
      // Create a new value from the user input
      setFormPrepopValue({
        ...formPrepopValue,
        [name]: val.inputValue,
      });
    } else {
      // setFieldValue("preferred", newValue);
      setFormPrepopValue({
        ...formPrepopValue,
        [name]: val,
      });
    }
  };

  const handleUploadResume = () => {
    setUploadResumeDialogOpen(true);
  };

  // const handleUploadResumeFile = () => {
  //   setUploadResumeLoading(true);
  //   const formData = new FormData();
  //   if (resumeInputRef.current.files.length > 0) {
  //     formData.append("resume_file", resumeInputRef.current.files[0]);
  //   }
  //   formData.append("domain", "");
  //   formData.append("country", "");
  //   formData.append("id", jobId);
  //   axios
  //     .post(uploadJdResume(), formData, {
  //       headers: { "Content-Type": "multipart/form-data" },
  //     })
  //     .then((response) => {
  //       if (response?.data?.success === true) {
  //         //   setSnack({
  //         //     open: true,
  //         //     color: "success",
  //         //     message: response?.data?.message,
  //         //   });
  //         let responseId = response?.data?.data?.id;
  //         let arr = [...resumeTab];

  //         let resumeObj = {
  //           id: responseId,
  //           name: response?.data?.data?.resume_info?.candidate_name,
  //           score: response?.data?.data?.quick_match_score?.overall_score,
  //           file_name: response?.data?.data?.resume_file_name,
  //         };
  //         arr.push(resumeObj);
  //         let currentResumeIndex = arr.length;
  //         setResumeTab(arr);
  //         fetchResumeData(responseId, currentResumeIndex, resumeObj);
  //         setTabValue(currentResumeIndex);
  //         setUploadResumeLoading(false);
  //         setUploadResumeDialogOpen(false);
  //         setResumeFileName(""); // Clear the file name
  //         if (resumeInputRef.current) {
  //           resumeInputRef.current.value = ""; // Clear the file input
  //         }
  //       }
  //     })
  //     .catch((err) => {
  //       // let arr = [...resumeTab];
  //       // let currentResumeIndex = arr.length + 1;
  //       // fetchJobData();
  //       // setTabValue(currentResumeIndex);
  //       setSnack({
  //         open: true,
  //         color: "error",
  //         message: err?.response?.data?.message,
  //       });
  //       let responseId = err?.response?.data?.data?.id;
  //       let arr = [...resumeTab];

  //       let resumeObj = {
  //         id: responseId,
  //         name: err?.response?.data?.data?.resume_info?.candidate_name
  //           ? err?.response?.data?.data?.resume_info?.candidate_name
  //           : "",
  //         score: err?.response?.data?.data?.score,
  //         file_name: err?.response?.data?.data?.resume_file_name,
  //       };
  //       arr.push(resumeObj);
  //       let currentResumeIndex = arr.length;
  //       setResumeTab(arr);
  //       fetchResumeData(responseId, currentResumeIndex, resumeObj);
  //       setTabValue(currentResumeIndex);
  //       setUploadResumeLoading(false);
  //       setUploadResumeDialogOpen(false);
  //       setResumeFileName(""); // Clear the file name
  //       if (resumeInputRef.current) {
  //         resumeInputRef.current.value = ""; // Clear the file input
  //       }
  //     });
  // };

  const handleUploadResumeFile = async () => {
    if (!resumeInputRef.current.files.length > 0) {
      setSnack({
        open: true,
        color: "error",
        message: "Please upload a resume",
      });
    } else {
      setUploadResumeLoading(true);
      const formData = new FormData();
      if (resumeInputRef.current.files.length > 0) {
        formData.append("resume_file", resumeInputRef.current.files[0]);
      }
      formData.append("domain", "");
      formData.append("country", "");
      formData.append("id", jobId);

      const url = uploadJdResume(); // Prepare the URL for the request

      try {
        console.log("Sending request...");

        // Use fetch to send the request
        const response = await fetch(url, {
          method: "POST", // Specify the POST method
          body: formData, // Use FormData as the body
        });

        // Check if the response is okay
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const reader = response.body.getReader(); // Get a reader from the response stream
        const decoder = new TextDecoder("utf-8"); // To decode the stream

        const processStream = async () => {
          let done = false;
          let buffer = ""; // Buffer to accumulate the chunks

          while (!done) {
            const { value, done: isDone } = await reader.read();
            done = isDone;

            if (value) {
              const chunk = decoder.decode(value, { stream: true });
              buffer += chunk; // Append the chunk to the buffer

              // Attempt to process the accumulated buffer
              let boundaryIndex;
              try {
                // Split by newlines or other delimiters (if any), adjust if needed
                while ((boundaryIndex = buffer.indexOf("}\n{")) !== -1) {
                  const jsonString = buffer.slice(0, boundaryIndex + 1); // Extract the first JSON object
                  buffer = buffer.slice(boundaryIndex + 1); // Remove the processed part from the buffer
                  const jsonResponse = JSON.parse(jsonString); // Parse the JSON

                  // Process the parsed JSON
                  processJsonResponse(jsonResponse);
                }

                // Try to parse the last remaining part if it's a valid JSON
                const jsonResponse = JSON.parse(buffer);
                processJsonResponse(jsonResponse);
                buffer = ""; // Clear the buffer after successful parsing
              } catch (e) {
                // setSnack({
                //   open: true,
                //   color: "error",
                //   message: chunk?.response?.message,
                // });
                setUploadResumeLoading(false);
                console.error(
                  "Error parsing JSON:",
                  e,
                  "Received chunk:",
                  chunk
                );
                // Continue accumulating if the buffer is incomplete
              }
            }
          }
          console.log("Stream completed.");
        };

        // Separate function to process the parsed JSON response
        const processJsonResponse = (jsonResponse) => {
          // if (jsonResponse.success) {
          if (
            jsonResponse?.data?.percent !== undefined &&
            jsonResponse?.data?.current_resume &&
            !jsonResponse?.data?.response
          ) {
            console.log("Updating progress to:", jsonResponse.data.percent);
            setProgressPercent(jsonResponse?.data?.percent);
          }

          // Handle 100% progress and candidate name
          if (jsonResponse?.data?.jd_info) {
            let responseId = jsonResponse?.data?.id;
            let arr = [...resumeTab];

            let resumeObj = {
              id: responseId,
              name: jsonResponse?.data?.resume_info?.candidate_name
                ? jsonResponse?.data?.resume_info?.candidate_name
                : "",
              score: jsonResponse?.data?.quick_match_score?.overall_score
                ? jsonResponse?.data?.quick_match_score?.overall_score
                : jsonResponse?.data?.score,
              file_name: jsonResponse?.data?.resume_file_name,
            };
            arr.push(resumeObj);
            let currentResumeIndex = arr.length;
            setResumeTab(arr);
            fetchResumeData(responseId, currentResumeIndex, resumeObj);
            setTabValue(currentResumeIndex);
            setUploadResumeLoading(false);
            setUploadResumeDialogOpen(false);
            setResumeFileName(""); // Clear the file name
            if (resumeInputRef.current) {
              resumeInputRef.current.value = ""; // Clear the file input
            }
            setProgressPercent(0);
          }
          // }
          // else {
          //   // let arr = [...resumeTab];
          //   // let currentResumeIndex = arr.length + 1;
          //   // fetchJobData();
          //   // setTabValue(currentResumeIndex);
          //   // setSnack({
          //   //   open: true,
          //   //   color: "error",
          //   //   message: err?.response?.data?.message,
          //   // });
          //   let responseId = jsonResponse?.data?.id;
          //   let arr = [...resumeTab];
          //   let resumeObj = {
          //     id: responseId,
          //     name: jsonResponse?.data?.resume_info?.candidate_name
          //       ? jsonResponse?.data?.resume_info?.candidate_name
          //       : "",
          //     score: jsonResponse?.data?.score,
          //     file_name: jsonResponse?.data?.resume_file_name,
          //   };
          //   arr.push(resumeObj);
          //   let currentResumeIndex = arr.length;
          //   setResumeTab(arr);
          //   fetchResumeData(responseId, currentResumeIndex, resumeObj);
          //   setTabValue(currentResumeIndex);
          //   setUploadResumeLoading(false);
          //   setUploadResumeDialogOpen(false);
          //   setResumeFileName(""); // Clear the file name
          //   if (resumeInputRef.current) {
          //     resumeInputRef.current.value = ""; // Clear the file input
          //   }

          //   // setSnack({
          //   //   open: true,
          //   //   color: "error",
          //   //   message: jsonResponse?.message,
          //   // });
          //   // setUploadResumeLoading(false);
          // }
        };

        processStream(); // Start processing the stream
      } catch (error) {
        setUploadResumeLoading(false);
        console.error("Error during the request:", error);
      }
    }
  };

  const handleSnackClose = () => {
    setSnack({
      open: false,
      color: "",
      message: "",
    });
  };

  const handleUploadResumeDialogClose = () => {
    setUploadResumeDialogOpen(false);
    setResumeFileName(""); // Clear the file name
    if (resumeInputRef.current) {
      resumeInputRef.current.value = ""; // Clear the file input
    }
    setProgressPercent(0);
    setUploadResumeLoading(false);
  };

  const handleResumeFileChange = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      setResumeFileName(file.name); // Update file name in text field
    }
  };

  const handleClearResumeFileName = () => {
    setResumeFileName(""); // Clear the file name
    if (resumeInputRef.current) {
      resumeInputRef.current.value = ""; // Clear the file input
    }
  };

  const handleUploadResumeDialog = () => {
    if (resumeInputRef.current) {
      resumeInputRef.current.click();
    }
  };

  const handleBack = () => {
    history.push(`/home/active-jobs`);
  };
  console.log(resumeTab, "resumeTab");
  const handleViewResume = () => {
    const isMatchedData = resumeTab[tabValue - 1]?.details;
    const resumeId = resumeTab[tabValue - 1]?.id;
    if (matchedData?.resume_id) {
      history.push(
        `/home/candidate-hub/get-detail?id=${matchedData?.resume_id}`
      );
    } else {
      setSnack({
        open: true,
        color: "error",
        message: "Resume not parsed successfully.",
      });
    }
  };

  const handleCompareResume = () => setCompareModalOpen(true);
  const handleCompareResumeClose = () => {
    setCompareModalOpen(false);
    setCandidate(null);
  };
  const handleCompareResumeSubmit = () => {
    const resumeId = resumeTab[tabValue - 1]?.id;
    console.log(candidate?.id, "candidate");
    console.log(resumeId, "rid");
    history.push(
      `/home/active-jobs/${resumeId}/${candidate?.id}/compare-resume-details`
    );
  };

  const handleCandidateChange = (e, newVal) => {
    setCandidate(newVal);
  };

  const selectedId = resumeTab[tabValue - 1]?.id;

  const filteredResumeTab = resumeTab.filter((item) => item.id !== selectedId);

  function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress variant="determinate" {...props} color="inherit" />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  // const handleCandidateAddNotes = () => {};

  const handleCandidateAddNotesDialogClose = () => {
    setCandidateAddNotesDialogOpen(false);
    setCandidateNoteList([{ note: "", interaction: "", added_by: username }]);
  };

  // const getCandidateNotesDetatil = (resumeId, index) => {
  //   axios
  //     .get(qmResumeGetNotes(resumeId))
  //     .then((res) => {
  //       let updatedNotes = [...candidateNotesTableList];
  //       updatedNotes[index] = res?.data?.data; // Store notes by tab index if needed
  //       setCandidateNotesTableList(updatedNotes || []);
  //     })
  //     .catch((err) => {
  //       console.error("Error fetching candidate notes:", err);
  //       setSnack({
  //         open: true,
  //         color: "error",
  //         message: err?.response?.data?.message || "Error fetching notes",
  //       });
  //     });
  // };

  const getCandidateNotesDetatil = (id) => {
    axios
      .get(qmResumeGetNotes(id))
      .then((res) => {
        setCandidateNotesTableList(res?.data?.data);
      })
      .catch((err) => {
        setSnack({
          open: true,
          color: "error",
          message: err?.response?.data?.message,
        });
      });
  };

  // Handle saving the notes
  const handleSaveCandidateAddNotes = () => {
    const hasEmptyFields = candidateNoteList.some(
      (noteItem) => !noteItem.note || !noteItem.interaction
    );

    if (hasEmptyFields) {
      setSnack({
        open: true,
        color: "error",
        message: "Note and Interaction Type fields are required",
      });
      return; // Stop execution if validation fails
    }
    const resumeId = resumeTab[tabValue - 1]?.id;

    if (resumeId) {
      setCandidateAddNoteLoading(true);
      axios
        .post(qmResumeAddNotes(resumeId), candidateNoteList)
        .then((response) => {
          if (response?.data?.success === true) {
            setSnack({
              open: true,
              color: "success",
              message: response?.data?.message,
            });
            setCandidateAddNoteLoading(false);
          } else {
            setSnack({
              open: true,
              color: "error",
              message: response?.data?.message,
            });
            setCandidateAddNoteLoading(false);
          }
          getCandidateNotesDetatil(resumeId);
          setCandidateAddNotesDialogOpen(false);
          setCandidateNoteList([
            { note: "", interaction: "", added_by: username },
          ]);
        })
        .catch((err) => {
          setSnack({
            open: true,
            color: "error",
            message: err?.response?.data?.message,
          });
          setCandidateAddNoteLoading(false);
        });
    }
  };

  // Handle adding another note and interaction type
  const handleCandidateAddAnotherNote = () => {
    setCandidateNoteList([
      ...candidateNoteList,
      { note: "", interaction: "", added_by: username },
    ]); // Add new empty note object
  };

  // Handle note text change for a specific index
  const handleCandidateNoteTextChange = (index, e) => {
    const newNotes = [...candidateNoteList];
    newNotes[index].note = e.target.value; // Update note for the corresponding index
    setCandidateNoteList(newNotes);
  };

  // Handle interaction type change for a specific index
  const handleCandidatePhoneEmailTextChange = (index, value) => {
    const newNotes = [...candidateNoteList];
    newNotes[index].interaction = value?.label || ""; // Ensure you set the correct field
    setCandidateNoteList(newNotes);
  };

  const handleCandidateAddNotes = () => {
    setCandidateAddNotesDialogOpen(true);
  };

  const handleCandidateNoteDelete = (indexToDelete) => {
    let arr = [...candidateNoteList];
    arr.splice(indexToDelete, 1);
    setCandidateNoteList(arr);
  };

  const handleDownloadResume = () => {
    setResumeDownloadLoading(true);
    if (resumeId) {
      axios
        .get(downloadTalairoResume(resumeId), {
          responseType: "blob",
        })
        .then((response) => {
          // Infer the file extension from the response's content type or URL
          const contentType = response.headers["content-type"];
          const extension =
            contentType === "application/pdf"
              ? "pdf"
              : contentType ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              ? "docx"
              : "doc"; // Fallback for older doc format or other types

          // Set a dynamic filename based on the response type
          const fileName = `${matchedData?.file_name}`;

          // Create a download link for the file
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: contentType })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
          setResumeDownloadLoading(false);
        })
        .catch((error) => {
          if (error.response && error.response.data instanceof Blob) {
            // Parse the Blob to extract the error message
            const reader = new FileReader();
            reader.onload = () => {
              const responseText = reader.result;
              try {
                // Parse JSON to extract the error message
                const json = JSON.parse(responseText);
                const errorMessage =
                  json.error || "An unexpected error occurred.";
                setSnack({
                  open: true,
                  color: "error",
                  message: errorMessage,
                });
                setResumeDownloadLoading(false);
              } catch (e) {
                // Fallback in case parsing fails
                setSnack({
                  open: true,
                  color: "error",
                  message:
                    "An unexpected error occurred while parsing the error message.",
                });
              }
              setResumeDownloadLoading(false);
            };
            reader.readAsText(error.response.data);
          } else {
            // Fallback for other types of errors
            setSnack({
              open: true,
              color: "error",
              message: "An unexpected error occurred.",
            });
            setResumeDownloadLoading(false);
          }
        });
    } else {
      setSnack({
        open: true,
        color: "error",
        message: "Resume was not parsed successfully. Unable to download.",
      });
      setResumeDownloadLoading(false);
    }
  };

  const handleDownloadTalairoJd = () => {
    setJdDownloadLoading(true);
    if (jobId && formPrepopValue) {
      axios
        .get(downloadTalairoJd(jobId), {
          responseType: "blob",
        })
        .then((response) => {
          // Infer the file extension from the response's content type or URL
          const contentType = response.headers["content-type"];
          const extension =
            contentType === "application/pdf"
              ? "pdf"
              : contentType ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              ? "docx"
              : "doc"; // Fallback for older doc format or other types

          // Set a dynamic filename based on the response type
          // const fileName = `${matchedData?.file_name}`;
          const fileName = `${formPrepopValue?.title}.${extension}`;

          // Create a download link for the file
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: contentType })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
          setJdDownloadLoading(false);
        })
        .catch((error) => {
          if (error.response && error.response.data instanceof Blob) {
            // Parse the Blob to extract the error message
            const reader = new FileReader();
            reader.onload = () => {
              const responseText = reader.result;
              try {
                // Parse JSON to extract the error message
                const json = JSON.parse(responseText);
                const errorMessage =
                  json.error || "An unexpected error occurred.";
                setSnack({
                  open: true,
                  color: "error",
                  message: errorMessage,
                });
                setJdDownloadLoading(false);
              } catch (e) {
                // Fallback in case parsing fails
                setSnack({
                  open: true,
                  color: "error",
                  message:
                    "An unexpected error occurred while parsing the error message.",
                });
                setJdDownloadLoading(false);
              }
            };
            reader.readAsText(error.response.data);
          } else {
            // Fallback for other types of errors
            setSnack({
              open: true,
              color: "error",
              message: "An unexpected error occurred.",
            });
            setJdDownloadLoading(false);
          }
        });
    } else {
      setSnack({
        open: true,
        color: "error",
        message: "JD was not parsed successfully. Unable to download.",
      });
      setJdDownloadLoading(false);
    }
  };
  return (
    <>
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          sx={{
            position: "sticky",
            top: 0, // Adjust if needed
            zIndex: 1000, // Ensure it stays on top of other content when scrolling
            backgroundColor: "white", // Optional: Keep it visible with a background
          }}
        >
          <StyledSpaceBetween>
            <StyledButton
              onClick={handleBack}
              startIcon={<KeyboardBackspaceIcon />}
            >
              {"Back"}
            </StyledButton>
            <StyledButton
              onClick={handleUploadResume}
              startIcon={<UploadIcon />}
            >
              {"Upload Resume"}
            </StyledButton>
          </StyledSpaceBetween>
        </Grid>

        {pageLoader ? (
          Array.from(new Array(12)).map((el, ind) => (
            <Grid item xs={12} sm={4} key={`key-${ind}`}>
              <Skeleton
                key={`key-${ind.toString()}`}
                width={"100%"}
                height={100}
              />
            </Grid>
          ))
        ) : (
          <>
            <Grid
              item
              xs={12}
              sx={{
                position: "sticky",
                top: "50px", // Below the button section, adjust as needed
                zIndex: 1000,
                backgroundColor: "white",
              }}
            >
              <Box
                sx={{
                  width: "100%", // Full width for the box
                  overflowX: "auto", // Ensure the Tabs can scroll horizontally without affecting layout
                  display: "block", // Block display to avoid flex issues
                  minHeight: "60px", // Optional: Ensures minimum space for the Tabs
                }}
              >
                <JobResumeTab
                  tabValue={tabValue}
                  handleTabChange={handleTabChange}
                  resumeTab={resumeTab}
                  formPrepopValue={formPrepopValue}
                />
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                flexGrow: 1,
                overflowY: "auto",
                maxHeight: "calc(100vh - 260px)",
              }}
            >
              {tabValue === 0 ? (
                <>
                  <Grid container spacing={3}>
                    {/* <Grid item xs={12}>
                      <Typography>{"Job Details"}</Typography>
                    </Grid> */}

                    <Grid
                      item
                      xs={12}
                      display={"flex"}
                      justifyContent={"flex-end"}
                    >
                      <StyledButton
                        variant="contained"
                        onClick={handleDownloadTalairoJd}
                        startIcon={
                          jdDownloadLoading ? (
                            <CircularProgress color="inherit" size={15} />
                          ) : (
                            <Download />
                          )
                        }
                        disabled={jdDownloadLoading}
                      >
                        {"Download"}
                      </StyledButton>
                    </Grid>

                    <Grid item xs={12}>
                      <FormPrePop formPrepopValue={formPrepopValue} />
                    </Grid>

                    <Grid item xs={12}>
                      <HardSoftSkill
                        formPrepopValue={formPrepopValue}
                        handleAddHardSkill={handleAddHardSkill}
                        handleAddSoftSkill={handleAddSoftSkill}
                        handleRemoveSkill={handleRemoveSkill}
                        handleSliderChange={handleSliderChange}
                        isDelete={false}
                      />
                    </Grid>

                    <Grid item xs={12} mt={3}>
                      <Education
                        formPrepopValue={formPrepopValue}
                        handleMultiSelectChange={handleMultiSelectChange}
                      />
                    </Grid>

                    <Grid item xs={12} mt={3}>
                      <OverallChart formPrepopValue={formPrepopValue} />
                    </Grid>

                    {notesTableList?.length ? (
                      <Grid item xs={12}>
                        <Notes notesTableList={notesTableList} />
                      </Grid>
                    ) : null}

                    <Grid item xs={12}>
                      <StyledButton
                        variant="contained"
                        endIcon={<Add />}
                        onClick={handleAddNotes}
                      >
                        {"Add Notes"}
                      </StyledButton>
                    </Grid>

                    {/* Notes Dialog */}
                    <AddNoteDialog
                      handleAddNotesDialogClose={handleAddNotesDialogClose}
                      handleSaveAddNotes={handleSaveAddNotes}
                      addNotesDialogOpen={addNotesDialogOpen}
                      noteList={noteList}
                      handleAddAnotherNote={handleAddAnotherNote}
                      handleNoteTextChange={handleNoteTextChange}
                      handlePhoneEmailTextChange={handlePhoneEmailTextChange}
                      handleNoteDelete={handleNoteDelete}
                      username={username}
                      notesTableList={notesTableList}
                      addNoteLoading={addNoteLoading}
                      currentDate={currentDate}
                      typeList={typeList}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid container spacing={3}>
                    {resumeLoader ? (
                      Array.from(new Array(12)).map((el, ind) => (
                        <Grid item xs={12} sm={4} key={`key-${ind}`}>
                          <Skeleton
                            key={`key-${ind.toString()}`}
                            width={"100%"}
                            height={100}
                          />
                        </Grid>
                      ))
                    ) : (
                      <>
                        {/* <Grid item xs={12}>
                          <Typography>{"Resume Details"}</Typography>
                        </Grid> */}
                        <Grid item xs={12}>
                          <Profile
                            matchedData={matchedData}
                            handleViewResume={handleViewResume}
                            handleCompareResume={handleCompareResume}
                            handleDownloadResume={handleDownloadResume}
                            resumeDownloadLoading={resumeDownloadLoading}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <InteractionHistory
                            matchedData={matchedData}
                            isBack={false}
                            handleCandidateAddNotes={handleCandidateAddNotes}
                            handleCandidateAddNotesDialogClose={
                              handleCandidateAddNotesDialogClose
                            }
                            handleSaveCandidateAddNotes={
                              handleSaveCandidateAddNotes
                            }
                            candidateAddNotesDialogOpen={
                              candidateAddNotesDialogOpen
                            }
                            candidateNoteList={candidateNoteList}
                            handleCandidateAddAnotherNote={
                              handleCandidateAddAnotherNote
                            }
                            handleNoteTextChange={handleCandidateNoteTextChange}
                            handleCandidatePhoneEmailTextChange={
                              handleCandidatePhoneEmailTextChange
                            }
                            handleCandidateNoteDelete={
                              handleCandidateNoteDelete
                            }
                            username={username}
                            candidateNotesTableList={candidateNotesTableList}
                            candidateAddNoteLoading={candidateAddNoteLoading}
                            currentDate={currentDate}
                            typeList={typeList}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </>
              )}
            </Grid>
          </>
        )}

        <SnackModal
          open={snack.open}
          color={snack.color}
          message={snack.message}
          onClose={handleSnackClose}
        />
      </Grid>

      {/* upload resume dailog */}
      <Dialog
        maxWidth="md"
        fullWidth
        open={uploadResumeDialogOpen}
        onClose={handleUploadResumeDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiPaper-root": {
            padding: "20px",
            borderRadius: "28px",
            backgroundColor: "#f3e9ff",
          },
        }}
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5" sx={{ fontWeight: 700 }}>
                {"Upload Resume"}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Grid
                container
                spacing={4}
                justifyContent="start"
                alignItems="center"
                sx={{ mb: 3 }}
              >
                <Grid item xs={12} md={7}>
                  <StyledTextField
                    variant="outlined"
                    fullWidth
                    size={"large"}
                    label="Upload a resume "
                    placeholder="Upload a resume"
                    value={resumeFileName}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {resumeFileName && (
                            <IconButton onClick={handleClearResumeFileName}>
                              <Clear />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <StyledUploadEnrichButton
                    variant="contained"
                    component="span"
                    onClick={handleUploadResumeDialog}
                    size={"large"}
                  >
                    {"Upload Candidate Resume"}
                    <input
                      ref={resumeInputRef}
                      type="file"
                      hidden
                      onChange={handleResumeFileChange}
                      name="resumeFile"
                      id="resumeFile"
                    />
                  </StyledUploadEnrichButton>
                </Grid>
              </Grid>
            </Grid>
            {uploadResumeLoading ? (
              <Grid item xs={12} md={7}>
                <LinearProgressWithLabel
                  variant="buffer"
                  value={progressPercent || 0}
                />
              </Grid>
            ) : null}
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
              <StyledFlexRowView>
                <StyledButton
                  variant="contained"
                  onClick={handleUploadResumeDialogClose}
                >
                  Cancel
                </StyledButton>
                <StyledButton
                  variant="contained"
                  onClick={handleUploadResumeFile}
                  startIcon={
                    uploadResumeLoading ? (
                      <CircularProgress color="inherit" size={15} />
                    ) : null
                  }
                  disabled={uploadResumeLoading}
                >
                  Submit
                </StyledButton>
              </StyledFlexRowView>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      {/* compare resume modal */}
      <Modal
        keepMounted
        open={compareModalOpen}
        // onClose={handleCompareResumeClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 450,
            bgcolor: "background.paper",
            border: "1px solid #000",
            boxShadow: 24,
            p: 4,
            borderRadius: "16px",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", gap: "16px" }}
          >
            <Typography
              id="keep-mounted-modal-title"
              variant="h5"
              component="h2"
              fontWeight={"bold"}
            >
              Compare Resume
            </Typography>

            <Typography>
              Select a candidate to compare their resume with{" "}
              <b>{resumeTab[tabValue - 1]?.name}</b>'s.
            </Typography>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={filteredResumeTab || []}
              // getOptionLabel={(option) => option.name}
              getOptionLabel={(option) => {
                if (typeof option === "object" && option !== null) {
                  return option.name;
                }
                return option;
              }}
              value={candidate}
              onChange={handleCandidateChange}
              renderInput={(params) => (
                <TextField {...params} label="Select a Candidate Name" />
              )}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "12px",
              }}
            >
              <StyledButton onClick={handleCompareResumeClose}>
                Cancel
              </StyledButton>
              <StyledButton onClick={handleCompareResumeSubmit} autoFocus>
                Compare
              </StyledButton>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default JobResumeDetail;
