import { Collapse, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  StyledFlexRowView,
  StyledRating,
} from "../../CandidateHub/Components/StyledComponents";
import { StyledBoldTypo } from "../../PostJob/Components/StyledComponents";
import { Add, Remove } from "@mui/icons-material";
import { CategoryRatingContainer, HideIconButton } from "./StyledComponents";

const ratings = [
  { label: "Work Experience", score: 4.5 },
  { label: "Recency", score: 5 },
  { label: "Accomplishments", score: 4 },
  {
    label: "Hard Skills",
    score: 4,
    submenu: [{ label: "Coding", score: 3.5 }],
  },
  {
    label: "Soft Skills",
    score: 4.5,
    submenu: [{ label: "Communication", score: 4.5 }],
  },
  { label: "Education", score: 4 },
  { label: "Location", score: 3.5 },
  { label: "DEI", score: 3 },
  {
    label: "Resume Quality",
    score: 4,
    submenu: [
      { label: "Professionalism", score: 3 },
      { label: "Spelling/Punc.", score: 4 },
      { label: "Gap", score: 2.5 },
      { label: "Progression", score: 3 },
    ],
  },
];
const CategoryRating = () => {
  const [openItems, setOpenItems] = useState({});

  const handleToggle = (key) => {
    setOpenItems((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  return (
    <Grid container rowSpacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6" align="center" fontWeight={"bold"}>
          Category Rating
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {ratings?.map((item) => (
          <>
            <CategoryRatingContainer>
              <StyledFlexRowView>
                {item.submenu ? (
                  <IconButton onClick={() => handleToggle(item.label)}>
                    {openItems[item.label] ? <Remove /> : <Add />}
                  </IconButton>
                ) : (
                  <HideIconButton>
                    <Add
                      sx={{
                        color: "white",
                      }}
                    />
                  </HideIconButton>
                )}
                <Tooltip title={item.label} placement="top-start">
                  <StyledBoldTypo
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    {item.label}
                  </StyledBoldTypo>
                </Tooltip>
              </StyledFlexRowView>
              <StyledRating
                name={item.name}
                value={item?.score}
                readOnly
                precision={0.5}
              />
            </CategoryRatingContainer>

            {item.submenu && (
              <Collapse in={openItems[item.label]} timeout="auto" unmountOnExit>
                {item.submenu.map((subItem, subIndex) => (
                  <CategoryRatingContainer>
                    <StyledFlexRowView>
                      <HideIconButton>
                        <Add sx={{ color: "white" }} />
                      </HideIconButton>
                      <StyledBoldTypo>{subItem.label}</StyledBoldTypo>
                    </StyledFlexRowView>
                    <StyledRating
                      name={subItem.name}
                      value={subItem?.score}
                      readOnly
                      precision={0.5}
                    />
                  </CategoryRatingContainer>
                ))}
              </Collapse>
            )}
          </>
        ))}
      </Grid>
    </Grid>
  );
};

export default CategoryRating;
