import {
  Button,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  ToggleButton,
} from "@mui/material";
import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { StyledToggleButtonGroup } from "./StyledComponents";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import GridViewIcon from "@mui/icons-material/GridView";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

function CandidateSearch({ resumeValue }) {
  const [alignment, setAlignment] = React.useState("left");
  const [formats, setFormats] = React.useState(() => ["italic"]);

  const handleFormat = (event, newFormats) => {
    setFormats(newFormats);
  };

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={5}>
        <TextField
          label=""
          id="outlined-start-adornment"
          sx={{ maxWidth: "60ch", width: "100%" }}
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          placeholder={"Candidate Name/Job Code/Team/Owner"}
        />
      </Grid>

      <Grid item xs={6} sm={6} md={3}>
        <TextField
          label=""
          id="outlined-start-adornment"
          sx={{ maxWidth: "13ch", width: "100%" }}
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FilterAltIcon />
              </InputAdornment>
            ),
          }}
          placeholder={"Filter"}
        />
      </Grid>

      <Grid
        item
        xs={6}
        sm={6}
        md={3}
        sx={{ display: "flex", justifyContent: "end" }}
      >
        <div>
          <Paper
            elevation={0}
            sx={{
              display: "flex",
              // border: (theme) => `1px solid ${theme.palette.divider}`,
              flexWrap: "wrap",
              backgroundColor: "#F7F7F7",
              maxWidth: "130px",
            }}
          >
            <StyledToggleButtonGroup
              size="small"
              value={alignment}
              exclusive
              onChange={handleAlignment}
              aria-label="text alignment"
            >
              <ToggleButton value="left" aria-label="left aligned">
                <FormatListBulletedIcon />
              </ToggleButton>
              <ToggleButton value="center" aria-label="centered">
                <GridViewIcon />
              </ToggleButton>
              <ToggleButton value="right" aria-label="right aligned">
                <CalendarTodayIcon />
              </ToggleButton>
            </StyledToggleButtonGroup>
          </Paper>
        </div>
      </Grid>
    </Grid>
  );
}

export default CandidateSearch;
